/**
 * @name VerificationService
 * @class VerificationService
 * @memberOf app_services
 * @description
 *  **Description**<br>
 *    The __VerificationService__ contains logic to prepare data for Verification page.<br><br>
 *  **List of methods**
 *  * [getBankWorkingTime()]{@link app_services.VerificationService.getBankWorkingTime}
 *  * [resolveVerificationType()]{@link app_services.VerificationService.resolveVerificationType}
 */
class VerificationService {

    /**
     * @constructs
     * @memberOf app_services.VerificationService
     * @description
     *  **Description**<br>
     *    This method contains all initializations for that class
     */
    constructor(
        verificationType
    ) {

        // Extra dependencies
        this.verificationType = verificationType;
    }

    /**
     * @name getBankWorkingTime
     * @function getBankWorkingTime
     * @memberOf app_services.VerificationService
     * @description
     *  **Description**<br>
     *    The __getBankWorkingTime__ method returns prepared Bank working time data for Conference Call verification.
     * @returns {Object} Bank working time
     * @todo optimize this method and write comments.
     */
    getBankWorkingTime(data) {
        let date = moment().format("YYYY-MM-DD");

        // Default bank working time object
        let workingTime = {
            timeZone: Math.round(moment().tz("America/Los_Angeles")._offset/60),
            openTimeStamp: moment.tz(date+'T09:00:00', 'America/Los_Angeles').unix(),   // 9:00 AM LA/SFO
            closeTimeStamp: moment.tz(date+'T18:00:00', 'America/Los_Angeles').unix()   // 6:00 PM LA/SFO
        };

        let holydays = [];

        if (data && data.bank_holidays) {
            for (let i = 0; i<data.bank_holidays.length; i++) {
                holydays.push(data.bank_holidays[i].format);
            }
        }

        // get sunday-based day of week ( sunday == 0; saturday == 6)
        let currentDay = moment().isoWeekday() % 7;
        let currentDate = moment().format("YYYY-MM-DD");

        let isIteration = true;
        let iterationCount = 0;

        if (data && data.bank_working_time) {

            while (isIteration && iterationCount < 10) {
                iterationCount++;

                if (data.bank_working_time[currentDay]
                    && !data.bank_working_time[currentDay].is_closed
                    && holydays.indexOf(currentDate) === -1) {

                    let workingDay = data.bank_working_time[currentDay];
                    isIteration = false;

                    if (workingDay.open_time_stamp < workingDay.close_time_stamp) {
                        workingTime.openTimeStamp = workingDay.open_time_stamp;
                        workingTime.closeTimeStamp = workingDay.close_time_stamp;
                    }
                    else {
                        workingTime.closeTimeStamp = workingDay.open_time_stamp;
                        workingTime.openTimeStamp = workingDay.close_time_stamp;
                    }

                }
                else {
                    // set next day of week
                    currentDay = currentDay === 6 ? 0 : currentDay+1;
                    // increase date to +1 day
                    currentDate = moment().add(iterationCount, 'day').endOf('day').format("YYYY-MM-DD");
                }
            }
        }

        return workingTime;
    }

    /**
     * @name resolveVerificationType
     * @function resolveVerificationType
     * @memberOf app_services.VerificationService
     * @description
     *  **Description**<br>
     *    The __resolveVerificationType__ method returns resolved verification type.
     * @returns {String} verification type
     */
    resolveVerificationType(type) {
        let resolvedType;

        switch (type) {
            case this.verificationType.CONFERENCE_CALL:
                resolvedType = 'conference-call';
                break;

            case this.verificationType.TEST_CHARGE:
            case this.verificationType.TEST_CHARGE_CC_CHECK:
            case this.verificationType.TEST_CHARGE_CC:
                resolvedType = 'test-charge';
                break;

            case this.verificationType.CC_CHECK:
            case this.verificationType.UNDEFINED_TYPE:
            case this.verificationType.OTHER_TYPE:
            case this.verificationType.NO_VERIFICATION:
            case this.verificationType.CC_CHECK_PLUS:
            default:
                resolvedType = 'none';
                break;
        }

        return resolvedType;
    }
}

// Injections of necessary dependencies
VerificationService.$inject = [
    'app.verificationType'
];

// Default export
export default VerificationService;