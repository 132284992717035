/**
 * @name TotalController
 * @class TotalController
 * @memberOf app_controllers
 * @description
 *  **Description**<br>
 *    The __TotalController__ is controller which control the total block state.<br><br>
 *  **List of methods**
 *  * [$onInit()]{@link app_controllers.TotalController.$onInit}
 *  * [getChargeBreakdownTextKey()]{@link app_controllers.TotalController.getChargeBreakdownTextKey}
 *  * [isChargeBreakdownVisible()]{@link app_controllers.TotalController.isChargeBreakdownVisible}
 *  * [isSpecialConsolidator()]{@link app_controllers.TotalController.isSpecialConsolidator}
 *  * [updateTotalAmount()]{@link app_controllers.TotalController.updateTotalAmount}
 *  * [setPaymentForSend()]{@link app_controllers.TotalController.setPaymentForSend}
 */
class TotalController {

    /**
     * @constructs
     * @memberOf app_controllers.TotalController
     * @description
     *  **Description**<br>
     *    This method contains all initializations for that class
     * @property {Object} total - Contains the data about total
     * @dependency {service} $rootScope {@link https://docs.angularjs.org/api/ng/service/$rootScope|link}
     * @dependency {service} $filter {https://docs.angularjs.org/api/ng/service/$filter|link}
     * @dependency {library} $translate {@link https://github.com/angular-translate/angular-translate|link}
     * @dependency {service} $timeout {@link https://docs.angularjs.org/api/ng/service/$timeout|link}
     * @dependency {service} SharedDataService {@link app_services.SharedDataService|link}
     */
    constructor(
        $rootScope,
        $filter,
        $translate,
        $timeout,
        specialConsolidators,
        SharedDataService
    ) {

        // Extra dependencies
        this.$rootScope = $rootScope;
        this.$filter = $filter;
        this.$translate = $translate;
        this.$timeout = $timeout;
        this.specialConsolidators = specialConsolidators;
        this.SharedDataService = SharedDataService;
    }

    /**
     * @name $onInit
     * @function $onInit
     * @memberOf app_controllers.TotalController
     * @description
     *  **Description**<br>
     *    The __$onInit__ lifecycle hook is for all initialisation of code for a Controller.
     */
    $onInit() {
        if (this.appData.payment) {

            this.totalAmount = this.appData.payment.ticket_total.total_amount;
            this.currency = this.appData.currency;
            this.isBogSale = this.appData.cmsCompany === 'BOG';
            this.chargeBreakdownTextKey = this.getChargeBreakdownTextKey();
            this.airlineAmount = this.appData.payment.split_section.airline_amount;
            this.airlineTitle = this.appData.payment.split_section.airline_details.title;
            this.itnAmount = this.appData.payment.split_section.itn_amount;
            this.itnAmountBreackdownTextKey = this.airlineAmount ? 'nonRefundableCharge' : 'processedByITN';
            this.tpAmount = this.appData.payment.ticket_protection.tp_amount;
            this.totalTpAmount = this.appData.payment.ticket_total.total_tp_amount;
            this.specialDiscount = this.appData.payment.ticket_total.special_discount;
            this.totalAmountValue = '';
            this.totalAmountText = '';

            let isPaymentTypeWt = this.appData.paymentType === 'wt';
            let isCmsCompanyUk = this.appData.cmsCompany === 'UK';
            this.isCardDisclaimerUk = !isPaymentTypeWt && isCmsCompanyUk && this.currency.title === 'GBP';

            if (!this.appData.isHardcopy) {
                this.updateTotalAmount();
            }
            else {
                this.totalAmountValue = this.appData.payment.totalAmountValue;
                this.totalAmountText = this.appData.payment.totalAmountText;
                this.specialDiscount = this.appData.payment.specialDiscount;
            }

            let amountProcessedBy = this.isSpecialConsolidator() ? this.specialConsolidators.title[this.appData.consolidator] : this.airlineTitle;

            this.appData.total.airlineAmountText = `to be processed by ${amountProcessedBy}`;
            this.appData.total.itnAmountText = this.airlineAmount ? '(to be processed by ITN LLC)' : '(non-refundable charge by ITN LLC)';
        }

        this.$rootScope.$on('update-total-amount', () => {
            this.updateTotalAmount();
        });
    }


    /**
     * @name getChargeBreakdownTextKey
     * @function getChargeBreakdownTextKey
     * @memberOf app_controllers.TotalController
     * @description
     *  **Description**<br>
     *    The __getChargeBreakdownTextKey__ method defines key for translation for charge breakdown.
     * @returns {String} key for translation for charge breakdown
     */
    getChargeBreakdownTextKey() {
        let key;

        if (this.appData.paymentType === 'wt') {
            key = 'total.chargeBreakdown.wired';
        }
        else {
            key = 'total.chargeBreakdown.charged';
        }

        return key;
    }

    /**
     * @name isChargeBreakdownVisible
     * @function isChargeBreakdownVisible
     * @memberOf app_controllers.TotalController
     * @description
     *  **Description**<br>
     *    The __isChargeBreakdownVisible__ method defines is charge breakdown inscription is visible or not.
     * @returns {Boolean} return true if charge breakdown inscription is visible
     */
    isChargeBreakdownVisible() {
        let payment = this.appData.payment;
        let tpAmount = payment.ticket_protection.tp_amount;
        let isTpSelected = this.appData.protection.isSelected;
        let itnAmount = payment.split_section.itn_amount;
        let airlineAmount = payment.split_section.airline_amount;

        let isCompanyAmount = !!airlineAmount || !!itnAmount;
        let isTipsAmount = this.appData.isTips && this.appData.total.confirmedTips > 0;
        let isTicketProtection = !!tpAmount && isTpSelected === '1';

        return isCompanyAmount || isTipsAmount || isTicketProtection;
    }

    /**
     * @name isSpecialConsolidator
     * @function isSpecialConsolidator
     * @memberOf app_controllers.TotalController
     * @description
     *  **Description**<br>
     *    The __isSpecialConsolidator__ method defines is consolidator is in the list of specials consolidators or not.
     * @returns {Boolean} returns true if consolidator is in the list of specials consolidators
     */
    isSpecialConsolidator() {
        let isSpecialConsolidator = false;

        if (this.appData.consolidator && this.specialConsolidators.list) {
            angular.forEach(this.specialConsolidators.list, (consolidator, key) => {
                if (this.appData.consolidator === consolidator) {
                    isSpecialConsolidator = true;
                }
            });
        }

        return isSpecialConsolidator;
    }

    /**
     * @name updateTotalAmount
     * @function updateTotalAmount
     * @memberOf app_controllers.TotalController
     * @description
     *  **Description**<br>
     *    The __updateTotalAmount__ method updates ( value and tex ) of total amount.
     */
    updateTotalAmount() {
        let total = this.totalAmount;

        if (this.appData.isTips && this.appData.total.confirmedTips) {
            total += this.appData.total.confirmedTips;
        }

        if (this.appData.protection.isSelected === '1') {
            total += this.tpAmount;
        }

        this.totalAmountValue = total;
        this.totalAmountText = `(${this.$filter('priceToWords')(
            total,
            this.currency.title,
            this.$translate.use()
        )})`;

        this.SharedDataService.affirmTotal = total;

        this.$timeout(()=>{
            if (window.affirm && window.affirm.ui && typeof window.affirm.ui.refresh === 'function') {
                window.affirm.ui.refresh();
            }
        });

        this.setPaymentForSend();
    }

    /**
     * @name setPaymentForSend
     * @function setPaymentForSend
     * @memberOf app_controllers.TotalController
     * @description
     *  **Description**<br>
     *    The __setPaymentForSend__ method prepare prices and set it to payment variable to send on server.
     */
    setPaymentForSend() {
        let totalAmount;
        let totalAmountString;
        let totalTpAmount;
        let totalTpAmountString;
        let tip_amount = this.appData.total.confirmedTips || 0;

        totalAmount = this.totalAmount + tip_amount;
        totalAmountString = `(${this.$filter('priceToWords')(
            totalAmount,
            this.currency.title,
            this.$translate.use()
        )})`;

        totalTpAmount = this.totalTpAmount + tip_amount;
        totalTpAmountString = `(${this.$filter('priceToWords')(
            totalTpAmount,
            this.currency.title,
            this.$translate.use()
        )})`;

        tip_amount = tip_amount ? tip_amount : null;

        this.appData.payment.ticket_total.total_amount = totalAmount;
        this.appData.payment.ticket_total.total_amount_string = totalAmountString;
        this.appData.payment.ticket_total.total_tp_amount = totalTpAmount;
        this.appData.payment.ticket_total.total_tp_amount_string = totalTpAmountString;
        this.appData.payment.tip_amount = tip_amount;
    }

}

// Injections of necessary dependencies
TotalController.$inject = [
    '$rootScope',
    '$filter',
    '$translate',
    '$timeout',
    'app.specialConsolidators',
    'SharedDataService'
];

// Default export
export default TotalController;