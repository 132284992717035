// Import dependencies
import angular from 'angular';
import RulesComponent from './rules.component';
import RulesService from './rules.service';
import Baggage from './baggage/baggage.module';

/**
 * @name app.rules
 * @class RulesModule
 * @memberOf app_modules
 * @description
 *  **Description**<br>
 *    The __app.rules__ module is module that is responsible to make all preparation of data for rules block
 *    and display him in application. This module consist from 'RulesComponent' and 'RulesService'.
 *    What make each of them, see in relevant section.
 *    * {@link app_components.RulesComponent|RulesComponent}
 *    * {@link app_services.RulesService|RulesService}
 */
const RulesModule = angular.module('app.rules', [
    Baggage
])
    .component('rules', RulesComponent)
    .service('RulesService', RulesService)
    .name;

// Default export
export default RulesModule;