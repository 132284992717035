(function() {
    "use strict";

    angular.module('app.core')
        .config([
            '$locationProvider',
            '$httpProvider',
            '$translateProvider',
            '$compileProvider',
            '$logProvider',

            function(
                $locationProvider,
                $httpProvider,
                $translateProvider,
                $compileProvider,
                $logProvider
            ) {

                $locationProvider
                    .html5Mode(true)
                    .hashPrefix('');

                $httpProvider.interceptors.push('tokenService');

                $translateProvider
                    .useStaticFilesLoader({
                        files: [{
                            prefix: 'src/languages/',
                            suffix: '.json'
                        }]
                    })
                    .preferredLanguage('en')
                    .forceAsyncReload(true)
                    .useLoaderCache(true)
                    .useSanitizeValueStrategy(null);

                $compileProvider.debugInfoEnabled(false);
                $compileProvider.commentDirectivesEnabled(false);
                $compileProvider.cssClassDirectivesEnabled(false);

                $logProvider.debugEnabled(false);
            }
        ]);

})();