// Import dependencies
import template from './package-summary.template.html';

/**
 * @name PackageSummaryComponent
 * @class PackageSummaryComponent
 * @memberOf app_components
 * @description
 *  **Description**<br>
 *    The __package summary__ component contains all needed to display 'package summary' block. That include itself ( template ).
 *    For more details see:
 *    * {@link https://gitlab.dyninno.net/oaf/standalone/blob/master/web/public/src/components/package-summary/package-summary.template.html|'flight' template}
 */
const PackageSummaryComponent = {
    controller: function() {},
    controllerAs: 'PackageSummaryCtrl',
    template,
    bindings: {
        appData: '<'
    }
};

// Default export
export default PackageSummaryComponent;