(function() {
    'use strict';

    // angularjs implementation of intl-tel-input, source: https://github.com/jackocnr/intl-tel-input
    angular.module('app.directives').directive('phoneInputPayment', [

        '$timeout',

        function(
            $timeout
        ) {

            return {
                restrict: 'A',
                scope: 'true',
                require: 'ngModel',

                link: function(scope, element, attr, ctrl) {

                    let isServicePhone = attr.phoneInputPayment === 'service';
                    let autoComplete = angular.copy(element[0].autocomplete);

                    if (!scope.PaymentCtrl.isHardcopy) {

                        let defaultCountries = ['ar', 'ca', 'co', 'ec', 'pe', 'ph', 'us'];
                        let selectedCountry = 'us';

                        switch (scope.PaymentCtrl.appData.cmsCompany) {
                            case 'UK':
                                defaultCountries = ['ar', 'ca', 'co', 'ec', 'pe', 'ph', 'gb', 'us'];
                                selectedCountry = 'gb';
                                break;
                            case 'CA':
                                selectedCountry = 'ca';
                                break;
                        }

                        let options = {
                            utilsScript :require('intl-tel-input/build/js/utils.js'),
                            preferredCountries: [],
                            initialCountry: selectedCountry,
                            nationalMode: true,
                            autoPlaceholder: 'off'
                        };

                        if (isServicePhone) {
                            options = {
                                utilsScript :require('intl-tel-input/build/js/utils.js'),
                                initialCountry: selectedCountry,
                                preferredCountries: defaultCountries,
                                nationalMode: true,
                                autoPlaceholder: 'off'
                            };
                        }

                        let telElement = window.intltelinput(element[0], options);

                        $timeout(()=>{
                            element[0].autocomplete = autoComplete;
                        });

                        element.on('countrychange', function() {
                            if (ctrl.$viewValue) {
                                $timeout(function(){
                                    ctrl.$setViewValue(telElement.getNumber());
                                });
                            }
                        });

                        ctrl.$validators.intlTelValidity = function (value) {
                            // if phone number is deleted / empty do not run phone number validation
                            if (attr.required && (value || element[0].value.length > 0)) {
                                return telElement.isValidNumber();
                            } else {
                                ctrl.$setPristine();
                                return true;
                            }
                        };

                        ctrl.$parsers.push(function () {
                            let num = telElement.getNumber();
                            let country = telElement.getSelectedCountryData().iso2;

                            if (window.intlTelInputUtils) {
                                num = window.intlTelInputUtils.formatNumber(num, country, 1);
                                let forbiddenChars = new RegExp(/[^0-9\+\-\(\)\s]/);
                                num = num.replace(forbiddenChars, '');
                            }

                            ctrl.$setViewValue(num);
                            ctrl.$render();
                            return num;
                        });

                        // Set input value to model value and trigger evaluation.
                        ctrl.$formatters.push(function (value) {
                            if (value) {
                                if(value.charAt(0) !== '+') {
                                    value = '+' + value;
                                }
                                telElement.setNumber(value);
                            }

                            let num;
                            let country = telElement.getSelectedCountryData().iso2;

                            if (window.intlTelInputUtils) {
                                num = window.intlTelInputUtils.formatNumber(value, country, 1);
                            }

                            return num || value;
                        });

                        if (!isServicePhone) {
                            scope.$watch('PaymentCtrl.payment.country', function (newValue) {
                                if (newValue && newValue.code) {
                                    telElement.setCountry(newValue.code);
                                }
                            });
                        }

                        scope.$on('$destroy', function() {
                            telElement.destroy();
                        });
                    }
                }
            };
        }]);
})();
