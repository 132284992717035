// Import dependencies
import controller from './total.controller';
import template from './total.template.html';

/**
 * @name TotalComponent
 * @class TotalComponent
 * @memberOf app_components
 * @description
 *  **Description**<br>
 *    The __total__ component contains all needed to display 'total' block. That include itself ( controller and template ).
 *    For more details see:
 *    * {@link app_controllers.TotalController|TotalController}
 *    * {@link https://gitlab.dyninno.net/oaf/standalone/blob/master/web/public/src/components/total/total.template.html|'total' template}
 */
const TotalComponent = {
    controller,
    controllerAs: 'TotalCtrl',
    template,
    bindings: {
        appData: '<',
        itnForm: '<',
        saleFormModel: '='
    }
};

// Default export
export default TotalComponent;