// Import dependencies
import angular from 'angular';
import TestCharge from './test-charge/test-charge.module';
import ConferenceCall from './conference-call/conference-call.module';
import VerificationComponent from './verification.component';
import VerificationService from './verification.service';

/**
 * @name app.verification
 * @class VerificationModule
 * @memberOf app_modules
 * @description
 *  **Description**<br>
 *    The __app.verification__ module is responsible to make all preparation of data for verification page
 *    and display him in application. This module consist from 'VerificationComponent'.
 *    What it makes, see in relevant section.
 *    * {@link app_components.VerificationComponent|VerificationComponent}
 */

const VerificationModule = angular.module('app.verification', [
    TestCharge,
    ConferenceCall
])
    .component('verification', VerificationComponent)
    .service('VerificationService', VerificationService)
    .name;

// Default export
export default VerificationModule;