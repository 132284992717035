(function() {
    "use strict";

    angular.module('app.services')
        .service('dateService', [
            'app.dateSettings',

            function(
                dateSettings

            ) {

                // Returns array of integers from 1st arg up to 2nd. Ascending, if 'from' is less then 'upto' and descending otherwise
                this.createNumberList = function(from, upto){
                    var array = [];
                    var i = from;

                    if (from <= upto) {
                        for (i; i <= upto; i++) array.push(i);
                    }
                    else {
                        for (i; i >= upto; i--) array.push(i);
                    }

                    return array;
                };

                // Returns number of days in year and month specified.
                this.daysInMonth = function (month,year){
                    return new Date(year, month, 0).getDate();
                };

                this.settings = dateSettings;

            }
        ]);
})();