(function() {
    "use strict";

    angular.module('app.directives').directive('youtube', [
        '$window',
        'NormalizeDataService',

        function(
            $window,
            NormalizeDataService
        ) {

        return {
            restrict: "E",

            scope: {
                voucherPrice: '@',
                isPopup: '=',
                popupCloseLogic: '='
            },

            template: require('../templates/partials/youtube.html'),

            link : function(scope, element, attrs) {
                let tag, firstScriptTag, player;

                scope.youtube = {
                    isOpen: false,
                    currentTime: null,

                    openPopup: function() {

                        /*https://developers.google.com/youtube/iframe_api_reference#Getting_Started*/

                        tag = document.createElement('script');
                        tag.src = 'https://www.youtube.com/iframe_api';
                        tag.id = 'youtube-script';
                        firstScriptTag = document.getElementsByTagName('script')[0];
                        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

                        function setPlayerStatus(){
                            if (player && scope.youtube.currentTime) {
                                player.seekTo(scope.youtube.currentTime, true);
                            }
                            player.playVideo();
                        }

                        $window.onYouTubeIframeAPIReady = function() {
                            player = new YT.Player('oaf-yt-player', {
                                playerVars: {
                                    enablejsapi: 1,
                                    autoplay: 1,
                                    html5: 1
                                },
                                height: '405',
                                width: '720',
                                videoId: '__k2hTAcABk',
                                events: {
                                    'onReady': setPlayerStatus
                                }
                            });
                        };

                        this.isOpen = true;
                        scope.isPopup = true;
                        scope.popupCloseLogic = this.closePopup.bind(this);
                    },

                    closePopup: function() {
                        if (player) {
                            if (player.getCurrentTime) {
                                this.currentTime = player.getCurrentTime();
                            }

                            if (player.destroy) {
                                player.destroy();
                                player = null;
                            } else {
                                player = null;
                            }
                        }

                        let wScript = document.getElementById('www-widgetapi-script');

                        if (NormalizeDataService.typeOf(wScript) === 'htmlscriptelement') {
                            tag.parentNode.removeChild(wScript);
                        }

                        tag.parentNode.removeChild(tag);

                        // removing youtube-specific variables from global
                        window.YTConfig = null;
                        window.YT = null;
                        window.yt = null;
                        delete window.yt;
                        delete window.ytDomDomGetNextId;
                        delete window.ytEventsEventsListeners;
                        delete window.ytEventsEventsCounter;
                        delete window.onYouTubeIframeAPIReady;
                        delete window.onYTReady;

                        scope.isPopup = false;
                        scope.popupCloseLogic = null;
                        this.isOpen = false;
                    }
                };
            }
        };
    }]);
})();
