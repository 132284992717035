/**
 * @name ErrorPageController
 * @class ErrorPageController
 * @memberOf app_controllers
 * @description
 *  **Description**<br>
 *    The __ErrorPageController__ is controller which control the error page state.<br><br>
 *  **List of methods**
 *  * [$onInit()]{@link app_controllers.ErrorPageController.$onInit}
 */
class ErrorPageController {

    /**
     * @constructs
     * @memberOf app_controllers.ErrorPageController
     * @description
     *  **Description**<br>
     *    This method contains all initializations for that class
     */
    constructor(
        SharedDataService
    ) {
        this.SharedDataService = SharedDataService;
    }

    getErrorText(){
        return this.SharedDataService.errorMsgKey;
    }


}

// Injections of necessary dependencies
ErrorPageController.$inject = [
    'SharedDataService'
];

// Default export
export default ErrorPageController;