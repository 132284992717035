// Import dependencies
import controller from './verification.controller';
import template from './verification.template.html';

/**
 * @name VerificationComponent
 * @class VerificationComponent
 * @memberOf app_components
 * @description
 *  **Description**<br>
 *    The __Verification__ component contains logic for verification page. That include itself ( controller and template ).
 *    For more details see:
 *    * {@link app_controllers.VerificationController|VerificationController}
 *    * {@link https://gitlab.dyninno.net/oaf/standalone/blob/master/web/public/src/components/verification/verification.template.html|'verification' template}
 */
const VerificationComponent = {
    controller,
    controllerAs: 'VerificationCtrl',
    template,
    bindings: {
        appData: '<'
    },
    require: {
        app: '^app'
    }
};

// Default export
export default VerificationComponent;