// Import dependencies
import angular from 'angular';
import HotelComponent from './hotel.component';
import HotelService from './hotel.service';

/**
 * @name app.hotel
 * @class HotelModule
 * @memberOf app_modules
 * @description
 *  **Description**<br>
 *    The __app.hotel__ module is module that is responsible to make all preparation of data for hotel block
 *    and display him in application. This module consist from 'HotelComponent' and 'HotelService'.
 *    What make each of them, see in relevant section.
 *    * {@link app_components.HotelComponent|HotelComponent}
 *    * {@link app_services.HotelService|HotelService}
 */
const HotelModule = angular.module('app.hotel', [])
    .component('hotel', HotelComponent)
    .service('HotelService', HotelService)
    .name;

// Default export
export default HotelModule;