/**
 * @name ApiResponseHandlerService
 * @class ApiResponseHandlerService
 * @memberOf app_services
 * @description
 *  **Description**<br>
 *    The __ApiResponseHandlerService__ class does execute the verification of server response.
 *    All of this methods provide the opportunity to be sure that received response are correct
 *    and contains correct structure of data.
 *    That class not contains static methods and must be used as a dependency.
 *    If you need to use this service in application ( controller, service, factory, directive ) you can simply inject it.
 *    Use for this the $inject method that is provided from angular framework.<br><br>
 *  **List of methods**
 *  * [checkCommonParts()]{@link app_services.ApiResponseHandlerService.checkCommonParts}
 *  * [checkSaleData()]{@link app_services.ApiResponseHandlerService.checkSaleData}
 *  * [checkHardcopyData()]{@link app_services.ApiResponseHandlerService.checkHardcopyData}
 *  * [checkVerificationHardcopyData()]{@link app_services.ApiResponseHandlerService.checkVerificationHardcopyData}
 * @example
 * class SweetyGirlFactory {
 *
 *   constructor(
 *     ApiResponseHandlerService
 *   ) {
 *     this.ApiResponseHandlerService = ApiResponseHandlerService;
 *   }
 *
 * }
 *
 * SweetyGirlFactory.$inject = ['ApiResponseHandlerService'];
 */
class ApiResponseHandlerService {

    /**
     * @constructs
     * @memberOf app_services.ApiResponseHandlerService
     * @description
     *  **Description**<br>
     *    This method contains all initializations for that class
     * @property {Object} checkResult - Contains the result of check
     * @dependency {service} NormalizeDataService {@link app_services.NormalizeDataService|link}
     */
    constructor(
        NormalizeDataService
    ) {
        // Extra dependencies
        this.NormalizeDataService = NormalizeDataService;

        // Properties
        this.checkResult = {};
    }

    /**
     * @name checkCommonParts
     * @function checkCommonParts
     * @memberOf app_services.ApiResponseHandlerService
     * @description
     *  **Description**<br>
     *    The __checkCommonParts__ method execute the check of common constituents of response from server. Such as ( status, data ).
     *    If something is not valid, saves the error in 'checkResult' as ( key: message ).
     * @param {Object} response - the response object from server.
     * @returns {Boolean} Result of check
     */
    checkCommonParts(response) {
        let isValid = true;

        // Checks status. Status must be 'true'
        if (response.success !== true) {
            isValid = false;
            this.checkResult.status = `Response status is not 'true'. Status: '${response.status}'`;
        }

        // Checks data. Data must be an object
        let type = this.NormalizeDataService.typeOf(response.result);
        if (type !== 'object') {
            isValid = false;
            this.checkResult.data = `Response data is not an 'object'. Data type is: '${type}'`;
        }

        return isValid;
    }

    /**
     * @name checkSaleData
     * @function checkSaleData
     * @memberOf app_services.ApiResponseHandlerService
     * @description
     *  **Description**<br>
     *    The __checkSaleData__ execute check of sale data.
     *    If something is not valid, saves the error in 'checkResult' as ( key: message ).
     * @param {Object} response - the response object from server.
     * @returns {Boolean} Result of check
     */
    checkSaleData(response) {

        // Checks common parts
        let isValid = this.checkCommonParts(response);

        if (isValid) {

            // TODO verify data integrity for sale

        }

        return isValid;
    }

    /**
     * @name checkHardcopyData
     * @function checkHardcopyData
     * @memberOf app_services.ApiResponseHandlerService
     * @description
     *  **Description**<br>
     *    The __checkHardcopyData__ execute check of hardcopy data.
     *    If something is not valid, saves the error in 'checkResult' as ( key: message ).
     * @param {Object} response - the response object from server.
     * @returns {Boolean} Result of check
     */
    checkHardcopyData(response) {
        let type;

        // Checks common parts
        let isValid = this.checkCommonParts(response);

        if (isValid) {

            // Check submitted data in response. Submitted data must be an object
            type = this.NormalizeDataService.typeOf(response.result.submittedData);
            if (type !== 'object') {
                isValid = false;
                this.checkResult.submittedData = `Submitted data is not an object. Submitted data: ${type}`;
            }

        }

        return isValid;
    }

    /**
     * @name checkVerificationHardcopyData
     * @function checkVerificationHardcopyData
     * @memberOf app_services.ApiResponseHandlerService
     * @description
     *  **Description**<br>
     *    The __checkVerificationHardcopyData__ execute check of verification hardcopy data.
     *    If something is not valid, saves the error in 'checkResult' as ( key: message ).
     * @param {Object} response - the response object from server.
     * @returns {Boolean} Result of check
     */
    checkVerificationHardcopyData(response) {

        // Checks common parts
        let isValid = this.checkCommonParts(response);

        if (isValid) {

            // TODO verify data integrity for sale

        }

        return isValid;
    }
}

// Injections of necessary dependencies
ApiResponseHandlerService.$inject = [
    'NormalizeDataService'
];

// Default export
export default ApiResponseHandlerService;