// Import dependencies
import angular from 'angular';
import FormSubmitComponent from './form-submit.component';

/**
 * @name app.formSubmit
 * @class FormSubmitModule
 * @memberOf app_modules
 * @description
 *  **Description**<br>
 *    The __app.formSubmit__ module is module that is responsible to make all preparation of data for form submit block
 *    and display him in application. This module consist from 'FormSubmitComponent'.
 *    What it makes, see in relevant section.
 *    * {@link app_components.FormSubmitComponent|FormSubmitComponent}
 */
const FormSubmitModule = angular.module('app.formSubmit', [])
    .component('formSubmit', FormSubmitComponent)
    .name;

// Default export
export default FormSubmitModule;