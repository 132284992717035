// Import dependencies
import template from './hotel.template.html';

/**
 * @name HotelComponent
 * @class HotelComponent
 * @memberOf app_components
 * @description
 *  **Description**<br>
 *    The __hotel__ component contains all needed to display 'hotel' block. That include itself ( template ).
 *    For more details see:
 *    * {@link https://gitlab.dyninno.net/oaf/standalone/blob/master/web/public/src/components/hotel/hotel.template.html|'hotel' template}
 */
const HotelComponent = {
    controller: function() {},
    controllerAs: 'HotelCtrl',
    template,
    bindings: {
        appData: '<'
    }
};

// Default export
export default HotelComponent;