// Import dependencies
import controller from './footer.controller';
import template from './footer.template.html';

/**
 * @name FooterComponent
 * @class FooterComponent
 * @memberOf app_components
 * @description
 *  **Description**<br>
 *    The __footer__ component contains all needed to display 'footer' block. That include itself ( controller and template ).
 *    For more details see:
 *    * {@link app_controllers.FlightController|FlightController}
 *    * {@link https://gitlab.dyninno.net/oaf/standalone/blob/master/web/public/src/components/footer/footer.template.html|'footer' template}
 */
const FooterComponent = {
    controller,
    controllerAs: 'FooterCtrl',
    template,
    bindings: {
        appData: '<'
    }
};

// Default export
export default FooterComponent;