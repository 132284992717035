(function() {
    'use strict';

    angular.module('app.directives').directive('tipSlider', [

        '$rootScope',
        '$timeout',
        'SharedDataService',
        'CommonMethodsService',

        function(
            $rootScope,
            $timeout,
            SharedDataService,
            CommonMethodsService
        ) {

            return {
                restrict: 'E',
                scope: {
                    isTipOpen: '=',
                    isTipsAmountError: '=',
                    itnForm: '<',
                    onUpdate: '&',
                    appData: '<'
                },
                template: require('./../templates/partials/tipslider.html'),

                link: function(scope, element, attr, ctrl) {

                    // Preparation of tip range array
                    let slider = element[0].querySelector('#slider');
                    let options = {
                        start: [ scope.appData.tipLevels[0] ],
                        connect: true,
                        orientation: 'horizontal',
                        pips: {
                            mode: 'positions',
                            values: [0,16.67,33.33,50,66.67,83.33, 100],
                            density: 100,
                            stepped: true,
                            format: {
                                to: function(value) {
                                    return scope.appData.currency.sign + value.toFixed(2);
                                }
                            }
                        },
                        behaviour: 'tap',
                        range: {
                            'min':    [ scope.appData.tipLevels[0] ],
                            '16.67%': [ scope.appData.tipLevels[1] ],
                            '33.33%': [ scope.appData.tipLevels[2] ],
                            '50%':    [ scope.appData.tipLevels[3] ],
                            '66.67%': [ scope.appData.tipLevels[4] ],
                            '83.33%': [ scope.appData.tipLevels[5] ],
                            'max':    [ scope.appData.tipLevels[6] ]
                        }
                    };

                    noUiSlider.create(slider, options);

                    function updateHandle() {
                        let chosenTips = slider.noUiSlider.get();
                        $timeout(function(){
                            scope.tips.tempTips = Number(chosenTips).toFixed(2);
                            scope.innerTipText = CommonMethodsService.prepareTipText(chosenTips, scope.appData.tipLevels);
                        });
                    }

                    slider.noUiSlider.on('slide', updateHandle);
                    slider.noUiSlider.on('end', updateHandle);

                    // adding custom event for purpose to set value by clicking on scale pips
                    // example and explanation: https://refreshless.com/nouislider/examples/#section-click-pips
                    let pips = slider.querySelectorAll('.noUi-value');

                    function handleClick () {
                        let value = Number(this.getAttribute('data-value'));
                        slider.noUiSlider.set(value);
                        $timeout(function(){
                            scope.tips.tempTips = value.toFixed(2);
                            scope.innerTipText = CommonMethodsService.prepareTipText(value, scope.appData.tipLevels);
                        });
                    }

                    for ( let i = 0, len = pips.length; i < len; i++ ) {
                        pips[i].addEventListener('click', handleClick);
                    }

                    scope.tips = {
                        tempTips: '',
                        minTipsRange: scope.appData.tipLevels[0],
                        maxTipsRange: scope.appData.tipLevels[6],

                        isHardcopy: scope.appData.isHardcopy,
                        employeeName: scope.appData.employee.name,
                        currency: scope.appData.currency,

                        close: function() {
                            scope.isTipOpen = false;
                        },

                        // return link to agent photo from data or link to default photo if no photo provided in data
                        agentPhoto: function() {
                            let employee = scope.appData.employee;
                            let photo = 'src/img/agent_no_photo.png';

                            if (employee && employee.photo) {
                                photo = employee.photo;
                            }

                            return photo;
                        }(),

                        // Returns value (boolean) if in (Appreciation of service) block, the hint of higher the tips is visible
                        isToolTipVisible: function() {
                            return scope.isTipOpen && this.tempTips >= this.maxTipsRange;
                        },

                        update: function(amount) {
                            let numAmount = Number(amount);

                            slider.noUiSlider.set(numAmount);

                            if (numAmount < scope.appData.tipLevels[0] || numAmount > scope.appData.tipLevels[6]) {
                                slider.noUiSlider.set(amount);
                            }

                            this.tempTips = amount;
                        },

                        updateLevel: function(level) {
                            if (level >= 0 && level <= 6) {

                                slider.noUiSlider.set(scope.appData.tipLevels[level]);

                                $timeout(() => {
                                    this.tempTips = scope.appData.tipLevels[level].toFixed(2);
                                });
                            }
                        },

                        confirmTips: function() {

                            $timeout(() => {
                                scope.appData.total.confirmedTips = this.tempTips ? Math.round(Number(this.tempTips.replace(/\.$/, '')) * 100) : 0;
                                scope.isTipOpen = false;
                                scope.onUpdate();
                            });
                        },

                        // Return text string based on tips amount

                    };

                    // Update tips before it will displayed
                    if (scope.isTipOpen) {

                        if (scope.appData.total.confirmedTips) {
                            scope.tips.update(scope.appData.total.confirmedTips)
                        }
                        else {
                            scope.tips.update(scope.tips.minTipsRange)
                        }
                    }

                    // timeout is necessary to give browser some time to draw elements, so y-position will be accurate
                    if (!SharedDataService.isAffirmSuccess) {

                        setTimeout(function(){
                            let elementOffset = angular.element( document.querySelector('#tips-popup'))[0].offsetTop;

                            angular.element(document).on('scroll',function(){

                                if ((elementOffset - window.pageYOffset) < 500) {

                                    $timeout(function(){
                                        scope.isTipOpen = true;
                                        scope.tips.updateLevel(0);
                                    });

                                    angular.element(document).off('scroll');
                                }
                            });

                        }, 1000);
                    }
                }
            };
        }]);
})();