(function() {
    "use strict";

    angular.module('app.filters')
        .filter('priceToWords', [

            'app.currencyText',
            '$filter',

            function(
                currencyText,
                $filter
            ) {

                // if no language provided in parameter, function output string is in English
                return function(value, currency, language) {

                    var lang = language || "en";

                    var currencies = currencyText[lang];

                    // we define default variable values
                    var currencyData = currencies[currency];
                    var cents = 0;
                    var currencyName = currencyData.title.split('/')[1];
                    var centName = currencyData.subunit.split('/')[1];
                    var dollarName = currencyData.unit.split('/')[1];

                    value = $filter('price')(value);

                    if (value && value.indexOf(',')) {
                        // If the value amount is more than 1000 it contains "," after thousand number which we need to remove
                        value = value.replace(',', '');
                    }

                    if (value && value.indexOf('.')) {
                        // We split the received value into 2 variables because we need to get separate number value strings for each of them
                        var splitValue = value.split('.');
                        cents = splitValue[1];
                        value = splitValue[0];
                    }

                    // single/plural form of currency unit
                    if (value && Number(value) === 1) {
                        dollarName = currencyData.unit.split('/')[0];
                        currencyName = currencyData.title.split('/')[0];
                    }
                    else {
                        dollarName = currencyData.unit.split('/')[1];
                        currencyName = currencyData.title.split('/')[1];
                    }

                    // change minor currency unit to single form if it is 1
                    if (cents && Number(cents) === 1) {
                        centName = currencyData.subunit.split('/')[0];
                    }

                    // if languages have different noun/adjective grammar
                    var fullCurrencyName = {
                        en: currencyName + ' ' + dollarName + ' and ',
                        es: ' ' + dollarName + ' ' + currencyName + ' y '
                    };

                    // We change each value to words and return parsed price amount to string
                    var newValue = toWords[lang](value, currencyText);
                    var centValue = toWords[lang](cents, currencyText);
                    return newValue + fullCurrencyName[lang]  + centValue + ' ' + centName;
                };
            }]);


    // Choose which function to call according to language provided
    var toWords = {
        en: toWords_EN,
        es: toWords_ES
    };

    function toWords_EN(value, currencyText) {

        var digit = currencyText.en.numbers.digit;
        var tenAndDigit = currencyText.en.numbers.tenAndDigit;
        var tens = currencyText.en.numbers.tens;

        var valueArray = value.split('');
        var valueString = '';
        for (var i = 0, valueLength = value.length; i < valueLength; i++) {
            // We cycle through each value string and add according string into valueString variable
            if ((valueLength - i) % 3 === 2) {
                // If mod for valueLength equals 2 we go inside this if
                if (valueArray[i] === '1') {
                    // If the valueArray[i] value is 1 then we will use one of tenAndDigit array string and skip one cycle iteration after it
                    valueString += tenAndDigit[valueArray[i + 1]];
                    i++;
                } else if (valueArray[i] !== '0') {
                    // If the valueArray[i] value is not 0 we will use one of tens array string
                    valueString += tens[valueArray[i] - 2];
                }
            } else if (valueArray[i] !== '0') {
                // if valueArray[i] value is not 0 we will use one of digit array string
                valueString += digit[valueArray[i]];

                if ((valueLength - i) % 3 === 0) {
                    // If mod for valueLength equals 0 then we add "hundred" string to valueString
                    valueString += ' hundred';
                }
            } else if ((value === '00' && valueArray[i] === '0') || (value.length === 1 && valueArray[i] === '0')) {
                // This statement is for case when the value is 0 or 00 and we need to add "zero" string for valueString
                valueString += digit[0];
            }
            if ((valueLength - i) === 4) {
                // If value string value was higher than 3 then when we reach the thousand element we add "thousand" string to valueString
                valueString += ' thousand';
            }
            // Add a whitespace after every cycle iteration
            valueString += ' ';
        }
        // We return created valueString
        return valueString;
    }


    // This function is modified version of https://github.com/yamadapc/js-written-number
    function toWords_ES(value, currencyText) {

        // create array of numbers, where unit changes will apply
        var scale = [100];
        for (var i = 1; i <= 16; i++) {
            scale.push(Math.pow(10, i * 3));
        }

        // can handle positive numbers only
        if (value < 0) {
            return "";
        }

        // can handle integers only
        // values like 122.22 will be split into 122 and 22 and transformed separately
        value = Math.round(+value);

        var language = currencyText.es.numbers;

        var units = language.units;
        var unit;

        // transforming notable values array into corresponding translations array
        if (!(units instanceof Array)) {
            var rawUnits = units;

            units = [];
            scale = Object.keys(rawUnits);

            for (var i in scale) {
                units.push(rawUnits[scale[i]]);
                scale[i] = Math.pow(10, parseInt(scale[i]));
            }
        }

        var baseCardinals = language.base;

        // if value is listed in currencyText.es.numbers.unitExceptions, return it
        if (language.unitExceptions[value]) {
            return language.unitExceptions[value];
        }

        // if value is listed in base, return it
        if (baseCardinals[value]) {
            return baseCardinals[value];
        }

        // simplified transformation for numbers 0-99
        if (value < 100) {
            return handleSmallerThan100(value, language, unit, baseCardinals, currencyText);
        }

        // saving decimal
        var residueOf100 = value % 100;
        var result = [];

        // recursive split of numbers to 2-digit number
        if (residueOf100) {
            result.push(language.unitSeparator + toWords_ES(residueOf100, currencyText));
        }

        var firstSignificant;

        // iterating through units array to get values of each number
        for (var i = 0, len = units.length; i < len; i++) {

            // each iteration saving number of current order
            var currentOrder = Math.floor(value / scale[i]);
            var divideBy;

            if (i === len - 1) {
                divideBy = 1000000;
            }
            else {
                divideBy = scale[i + 1] / scale[i];
            }

            // saving residue from current order
            var residue = currentOrder % divideBy;

            // assign unit translation for current order
            unit = units[i];

            if (!residue) continue;
            firstSignificant = scale[i];

            var str;

            // assigning unit name no str variable in plural or singular
            if (typeof unit === "string") {
                str = unit;
            } else {
                str = residue > 1 && unit.plural && !residueOf100
                    ? unit.plural
                    : unit.singular;
            }

            // not creating index if according rule is set in currencyText.es.numbers.units
            // Example: 1000 will be "mil"(thousand) instead of "un mil"(one thousand)
            if (unit.avoidPrefixException && unit.avoidPrefixException.indexOf(residue) > -1) {
                result.push(str);
                continue;
            }

            // residue of current order will be displayed separately (103 = one hundred and three)
            // checking if residue is in exception list, or transform in words
            var exception = language.unitExceptions[residue];
            var number = exception || toWords_ES(residue,currencyText);
            value -= residue * scale[i];
            result.push(number + " " + str);
        }

        // separating orders and residue
        var firstSignificantN = firstSignificant * Math.floor(value / firstSignificant);
        var rest = value - firstSignificantN;

        if (firstSignificant && rest > 0 && result[0].indexOf(language.unitSeparator) !== 0) {
            result = [result[0], language.unitSeparator.replace(/\s+$/, "")].concat(result.slice(1));
        }

        // assemble array of strings into single string in correct order
        return result.reverse().join(" ");
    };

    function handleSmallerThan100(value, language, unit, baseCardinals, currencyText) {
        var decimal = Math.floor(value / 10) * 10;
        unit = value - decimal;
        if (unit) {
            return (
                baseCardinals[decimal] + language.baseSeparator + toWords_ES(unit, currencyText)
            );
        }
        return baseCardinals[decimal];
    };
})();
