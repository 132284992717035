// Import dependencies
import controller from './protection.controller';
import template from './protection.template.html';

/**
 * @name ProtectionComponent
 * @class ProtectionComponent
 * @memberOf app_components
 * @description
 *  **Description**<br>
 *    The __protection__ component contains all needed to display 'protection' block. That include itself ( controller and template ).
 *    For more details see:
 *    * {@link app_controllers.ProtectionController|ProtectionController}
 *    * {@link https://gitlab.dyninno.net/oaf/standalone/blob/master/web/public/src/components/protection/protection.template.html|'protection' template}
 */
const ProtectionComponent = {
    controller,
    controllerAs: 'ProtectionCtrl',
    template,
    bindings: {
        appData: '<',
        itnForm: '<',
        saleFormModel: '='
    }
};

// Default export
export default ProtectionComponent;