(function() {
    "use strict";

    // angularjs implementation of noUiSlider, source: https://refreshless.com/nouislider/
    angular.module('app.directives').directive('callTime', ['$timeout', function($timeout) {
        return {
            restrict: "E",
            scope: {
                timezone: "=",
                time: "="
            },
            link: function(scope, element) {

                // Element for slider is DOM object, not jQlite object
                let slider = element[0];

                // Variables holding slider element for dynamic style change
                let leftHandle, rightHandle;

                // settings variable
                let options = {
                    start: [ scope.time.openTimeStamp, scope.time.closeTimeStamp ], // values in seconds
                    connect: true,
                    orientation: 'horizontal',
                    margin: 3600,  // minimal distance between sliders (1 hour)
                    behaviour: 'tap',
                    tooltips: [{ to: secondsToTime }, { to: secondsToTime }],
                    range: {
                        // 900 - default step (15 minutes).
                        // 0 step for max range is necessary for disabling rounding closeTimeStamp. do not remove it
                        'min': [scope.time.openTimeStamp, 900],
                        'max': [scope.time.closeTimeStamp, 0]
                    }
                };

                function cacheElements() {
                    leftHandle = document.querySelector(".noUi-handle-lower");
                    rightHandle = document.querySelector(".noUi-handle-upper");
                }

                function updateHandle() {
                    let chosenTimes = slider.noUiSlider.get();
                    $timeout(function(){
                        scope.$apply(function(){
                            scope.time.chosenOpenTimeStamp = Number(chosenTimes[0]);  // 1st handle
                            scope.time.chosenCloseTimeStamp = Number(chosenTimes[1]); // 2nd handle
                        });
                    });
                    updateHandleTooltip();
                }

                // Moves slider tooltips away from each other if they getting too close
                function updateHandleTooltip() {
                    let offset =  rightHandle.getBoundingClientRect().left - leftHandle.getBoundingClientRect().right;

                    // minimal distance in px before tooltips collide
                    if (offset !== 0 && offset < 60) {
                        slider.classList.add("proximity");
                    }
                    else {
                        slider.classList.remove("proximity");
                    }
                }

                // function to convert unix time to client readable time
                function secondsToTime(seconds) {
                    let parsedTimezone = Number(scope.timezone) || 0;
                    return moment.unix(seconds).utcOffset(parsedTimezone).format('hh:mm A');
                }

                // creating slider object
                noUiSlider.create(slider, options);
                slider.noUiSlider.on('set', updateHandle);

                // apply initial values to scope
                cacheElements();
                updateHandle();

                // on language or timezone change, recreate slider to show new values
                scope.$watch('timezone', function (newVal, oldVal) {
                    if (newVal !== oldVal) {
                        let temp = slider.noUiSlider.get();
                        slider.noUiSlider.destroy();
                        noUiSlider.create(slider, options);
                        slider.noUiSlider.set(temp);
                        slider.noUiSlider.on('set', updateHandle);
                        cacheElements();
                    }
                });
            }
        };
    }]);
})();