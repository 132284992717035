// Import dependencies
import template from './success-page.template.html';

/**
 * @name SuccessPageComponent
 * @class SuccessPageComponent
 * @memberOf app_components
 * @description
 *  **Description**<br>
 *    The __successPage__ component contains all needed to display success page. That include itself ( template ).
 *    For more details see:
 *    * {@link https://gitlab.dyninno.net/oaf/standalone/blob/master/web/public/src/components/success-page/success-page.template.html|'success-page' template}
 */
const SuccessPageComponent = {
    controller: function() {},
    controllerAs: 'SuccessPageCtrl',
    template,
    bindings: {
        appData: '<'
    }
};

// Default export
export default SuccessPageComponent;