// Import dependencies
import controller from './program.controller';
import template from './program.template.html';

/**
 * @name ProgramComponent
 * @class ProgramComponent
 * @memberOf app_components
 * @description
 *  **Description**<br>
 *    The __program__ component contains all needed to display 'passenger program' block.
 *    That include itself ( controller and template ).
 *    For more details see:
 *    * {@link app_controllers.ProgramController|ProgramController}
 *    * {@link https://gitlab.dyninno.net/oaf/standalone/blob/master/web/public/src/components/passengers/program/program.template.html|'program' template}
 */
const ProgramComponent = {
    controller,
    controllerAs: 'ProgramCtrl',
    template,
    bindings: {
        passenger: '='
    }
};

// Default export
export default ProgramComponent;