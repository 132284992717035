(function() {
    "use strict";

    angular.module('app.directives').directive('affirmLastMinute', [

        'SharedDataService',
        'CommonMethodsService',
        '$rootScope',
        '$timeout',

        function(
            SharedDataService,
            CommonMethodsService,
            $rootScope,
            $timeout
        ) {

            return {
                restrict: "E",
                scope: {
                    appData: '=',
                    postAffirm: '&',
                    isLastMinute: '='
                },
                template: require('./../templates/partials/lastMinute.html'),

                link : function(scope, element, attrs) {

                    // popup duration in seconds;
                    let duration = 25;

                    scope.lastMinute = {

                        timeLeft: duration,
                        isTimerActive: true,
                        progress: 0,
                        sharedData: SharedDataService,
                        isTpShown: scope.appData.protection.isSelected !== '1',
                        isTipsShown: !scope.appData.isTips || scope.appData.total.confirmedTips === 0,
                        isDefaultRange: true,
                        isAmountError: false,

                        timer(){

                            if (this.timeLeft > 0 && this.isTimerActive) {
                                $timeout(()=>{

                                    this.timeLeft--;
                                    this.progress = Math.round((duration - this.timeLeft) * (100/duration));

                                    this.timer();

                                },1000);

                            } else {
                                this.progress = 100;

                                if (this.isTimerActive) {
                                    this.submit();
                                }
                            }
                        },

                        tipsAmountTextKey() {
                            return CommonMethodsService.prepareTipText(this.selectedTipAmount, scope.appData.tipLevels);
                        },

                        submit(){
                            this.isTimerActive = false;
                            scope.isLastMinute = false;
                            scope.postAffirm();
                        },

                        setTP() {
                            scope.appData.protection.isSelected = this.confirmTP ? '1' : '0';
                            $rootScope.$broadcast('update-total-amount');
                        },

                        setTips(){
                            scope.appData.isTips = this.confirmTips;
                            scope.appData.total.confirmedTips = this.confirmTips ? Number(this.selectedTipAmount)*100 : 0;

                            if (Number(this.selectedTipAmount)*100 >= this.tipsMaxAmount()) {
                                this.isAmountError = true;
                            }

                            $rootScope.$broadcast('update-total-amount');
                        },

                        isValidSelectedTips(){
                            return Number(this.selectedTipAmount);
                        },

                        tipsMaxAmount(){

                            let ticketAirline = scope.appData.payment.split_section.airline_amount;
                            let ticketItn = scope.appData.payment.split_section.itn_amount;

                            let maxAmount = (ticketAirline || 0) <= ticketItn ? ticketItn : ticketAirline;
                            return maxAmount || 100000;
                        },

                        unsetTips(){

                            this.confirmTips = false;
                            this.isAmountError = false;

                            this.setTips();

                            if (this.selectedTipAmount ==='other') {
                                this.isDefaultRange = false;
                                this.selectedTipAmount = null;
                            }
                        },

                        closePopup: function() {

                            this.isTimerActive = false;
                            scope.appData.isPopup = false;
                            scope.isLastMinute = false;
                            scope.appData.popupCloseLogic = null;
                        }

                    };

                    scope.appData.isPopup = true;
                    scope.appData.popupCloseLogic = scope.lastMinute.closePopup.bind(scope.lastMinute);

                    scope.lastMinute.timer();

                    scope.$on("$destroy", () => {
                        scope.lastMinute.closePopup();
                    });
                }
            };
        }]);
})();

