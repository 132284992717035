// Import dependencies
import angular from 'angular';
import PassengersComponent from './passenges.component';
import PassengersService from './passengers.service';
import PassengerPreferences from './preferences/preferences.module';
import PassengerProgram from './program/program.module';

/**
 * @name app.passengers
 * @class PassengersModule
 * @memberOf app_modules
 * @description
 *  **Description**<br>
 *    The __app.passengers__ module is module that is responsible to make all preparation of data for passengers block
 *    and display him in application. This module consist from 'PassengersComponent' and 'PassengersService'.
 *    What make each of them, see in relevant section.
 *    * {@link app_components.PassengersComponent|PassengersComponent}
 *    * {@link app_services.PassengersService|PassengersService}
 */
const PassengersModule = angular.module('app.passengers', [
    PassengerPreferences,
    PassengerProgram
])
    .component('passengers', PassengersComponent)
    .service('PassengersService', PassengersService)
    .name;

// Default export
export default PassengersModule;