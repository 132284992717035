// Import dependencies
import angular from 'angular';
import BaggageComponent from './baggage.component';
import BaggageService from './baggage.service';

/**
 * @name app.baggage
 * @class BaggageModule
 * @memberOf app_modules
 * @description
 *  **Description**<br>
 *    The __app.baggage__ module is module that is responsible to make all preparation of data for baggage block
 *    and display him in application. This module consist from 'BaggageComponent' and 'BaggageService'.
 *    What make each of them, see in relevant section.
 *    * {@link app_components.BaggageComponent|BaggageComponent}
 *    * {@link app_services.BaggageService|BaggageService}
 */
const BaggageModule = angular.module('app.baggage', [])
    .component('baggage', BaggageComponent)
    .service('BaggageService', BaggageService)
    .name;

// Default export
export default BaggageModule;