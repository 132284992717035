// Import dependencies
import angular from 'angular';
import ReviewComponent from './review.component';

/**
 * @name app.resend
 * @class ResendModule
 * @memberOf app_modules
 * @description
 *  **Description**<br>
 *    The __app.review__ module is responsible to make all preparation of data for review block
 *    and display him in application. This module consist from 'ReviewComponent'.
 *    What it makes, see in relevant section.
 *    * {@link app_components.ReviewComponent|ReviewComponent}
 */

const ReviewModule = angular.module('app.review', [])
    .component('review', ReviewComponent)
    .name;

// Default export
export default ReviewModule;