// Import dependencies
import angular from 'angular';
import HeaderComponent from './header.component';

/**
 * @name app.header
 * @class HeaderModule
 * @memberOf app_modules
 * @description
 *  **Description**<br>
 *    The __app.header__ module is module that is responsible to make all preparation of data for header block
 *    and display him in application. This module consist from 'HeaderComponent'.
 *    What it makes, see in relevant section.
 *    * {@link app_components.HeaderComponent|HeaderComponent}
 */
const HeaderModule = angular.module('app.header', [])
    .component('header', HeaderComponent)
    .name;

// Default export
export default HeaderModule;