// Import dependencies
import angular from 'angular';
import DatePickerComponent from './date-picker.component';

/**
 * @name app.datePicker
 * @class DatePickerModule
 * @memberOf app_modules
 * @description
 *  **Description**<br>
 *    The __app.datePicker__ module is module that is responsible to make all preparation of data for date picker
 *    and display him in application. This module consist from 'DatePickerComponent'.
 *    What it makes, see in relevant section.
 *    * {@link app_components.DatePickerComponent|DatePickerComponent}
 */
const DatePickerModule = angular.module('app.datePicker', [])
    .component('datePicker', DatePickerComponent)
    .name;

// Default export
export default DatePickerModule;