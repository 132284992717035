import moment from 'moment-timezone';

/**
 * @name ConferenceCallController
 * @class ConferenceCallController
 * @memberOf app_controllers
 * @description
 *  **Description**<br>
 *    The __ConferenceCallController__ is controller for the conference call block.<br><br>
 *  **List of methods**
 *  * [$onInit()]{@link app_controllers.ReviewController.$onInit}
 */
class ConferenceCallController {

    /**
     * @constructs
     * @memberOf app_controllers.ConferenceCallController
     * @description
     *  **Description**<br>
     *    This method contains all initializations for that class
     * @dependency {service} $window {@link https://docs.angularjs.org/api/ng/service/$window|link}
     * @dependency {library} $translate {@link https://github.com/angular-translate/angular-translate|link}
     * @dependency {service} SaleApi {@link SaleApi|link}
     * @dependency {service} CommonMethodsService {@link app_services.CommonMethodsService|link}
     */
    constructor(
        $window,
        $translate,
        SaleApi,
        CommonMethodsService,
        timezones
    ) {

        // Extra dependencies
        this.$window = $window;
        this.$translate = $translate;
        this.SaleApi = SaleApi;
        this.CommonMethodsService = CommonMethodsService;
        this.timezones = timezones;

        this.formData = {};

        this.isVerificationLoading = false;
        this.isCallTimeShown = true;
    }

    /**
     * @name $onInit
     * @function $onInit
     * @memberOf app_controllers.ConferenceCallController
     * @description
     *  **Description**<br>
     *    The __$onInit__ lifecycle hook is for all initialisation of code for a Controller.
     */
    $onInit() {
        this.formData = {
            phoneSelect: this.appData.billingPhoneNumber,
            timeZoneOffset: this.appData.timeZoneOffset
        };

        if (this.appData.isHardcopy) {

            // Phone
            if (this.appData.phone === this.appData.billingPhoneNumber) {
                this.formData.phoneSelect = this.appData.billingPhoneNumber;
            }
            else {
                this.formData.phoneSelect = "text";
                this.formData.phone = this.appData.phone;
            }

            // Comment
            this.formData.comment = this.appData.comment;

            // Timezone
            if (this.appData.timeZone && angular.isString(this.appData.timeZone)) {
                this.formData.timeZoneOffset = this.appData.timeZone.replace('+','');
            }
            else {
                //default tz offset if necessary data is missing
                this.formData.timeZoneOffset = '-8';
            }

            // Call time
            if (this.appData.startTimeStamp && this.appData.endTimeStamp) {
                this.appData.time = {
                    openTimeStamp: Number(this.appData.startTimeStamp),
                    closeTimeStamp: Number(this.appData.endTimeStamp)
                };
            }
            else {
                this.isCallTimeShown = false;
            }
        }
    }

    /**
     * @name isRequired
     * @function isRequired
     * @memberOf app_controllers.ConferenceCallController
     * @description
     *  **Description**<br>
     *    The __isRequired__ method sets/unsets phone text input 'required' attribute
     */
    isRequired() {
        return (this.formData.phoneSelect === "text" || !this.formData.phoneSelect);
    }

    /**
     * @name selectText
     * @function selectText
     * @memberOf app_controllers.ConferenceCallController
     * @description
     *  **Description**<br>
     *    The __selectText__ method sets necessary value, when radio input with provided phone is selected
     */
    selectText() {
        this.formData.phoneSelect = "text";
    }

    /**
     * @name selectPhone
     * @function selectPhone
     * @memberOf app_controllers.ConferenceCallController
     * @description
     *  **Description**<br>
     *    The __selectPhone__ method sets necessary value, when text input for phone is selected
     */
    selectPhone() {
        this.formData.phoneSelect = this.appData.billingPhoneNumber;
    }

    /**
     * @name hourText
     * @function hourText
     * @memberOf app_controllers.ConferenceCallController
     * @description
     *  **Description**<br>
     *    The __hourText__ method is responsible for providing timezone select option names, generated with ng-options
     * @param {String} timezone - selected timezone
     * @returns {String} formatted string for timezone select options
     * @todo prepare timezones in ConferenceCallController || CommonMethodsService
     */
    hourText(timezone) {
        let tz = this.$translate.instant(`verification.timezones.${this.appData.dst}.${timezone}`);
        return tz + ' ' + moment().utc().add(timezone,'h').format(', hh:00 a');
    }

    /**
     * @name submitConferenceCall
     * @function submitConferenceCall
     * @memberOf app_controllers.ConferenceCallController
     * @description
     *  **Description**<br>
     *    The __submitConferenceCall__ method is responsible for submission of conference call verification form
     */
    submitConferenceCall() {
        this.isVerificationLoading = true;

        if (this.conferenceCallForm.$invalid || this.conferenceCallForm.$invalidExplicit) {
            this.CommonMethodsService.setErrors(this.conferenceCallForm);
            this.isVerificationLoading = false;
        }
        else {

            let phone = this.formData.phoneSelect === 'text' ? this.formData.phone : this.appData.billingPhoneNumber;
            let dataToSend = {
                phone: this.$window.intlTelInputUtils.formatNumber(
                    phone,
                    null,
                    0
                ),
                start_time_stamp: this.appData.time.chosenOpenTimeStamp,
                end_time_stamp: this.appData.time.chosenCloseTimeStamp,
                time_zone: this.appData.timeZoneOffset < 0 ? this.formData.timeZoneOffset.toString() : '+' + this.formData.timeZoneOffset,
                comment: this.formData.comment || '',
                dst: this.appData.dst,
                language: this.$translate.use()
            };

            this.SaleApi.sale.postVerificationCall({},
                dataToSend,
                response => {
                    this.isVerificationLoading = false;
                    this.proceed();

                },
                rejection => {
                    this.isVerificationLoading = false;

                    if (rejection && rejection.data && rejection.data.state === 'validation') {
                        this.conferenceCallForm.phone.$dirty = true;
                        this.conferenceCallForm.phone.$setValidity('not_valid',false);
                    }
                    else {
                        this.proceed();
                    }
                }
            );
        }
    }

}

// Injections of necessary dependencies
ConferenceCallController.$inject = [
    '$window',
    '$translate',
    'SaleApi',
    'CommonMethodsService',
    'app.timezones'
];

// Default export
export default ConferenceCallController;