// Import dependencies
import angular from 'angular';
import TipsComponent from './tips.component';

/**
 * @name app.tips
 * @class TipsModule
 * @memberOf app_modules
 * @description
 *  **Description**<br>
 *    The __app.tips__ module holds tips section
 *    What it makes, see in relevant section.
 *    * {@link app_components.TipsComponent|TipsComponent}
 */
const TipsModule = angular.module('app.tips', [])
    .component('tips', TipsComponent)
    .name;

// Default export
export default TipsModule;