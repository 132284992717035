/**
 * @name RulesController
 * @class RulesController
 * @memberOf app_controllers
 * @description
 *  **Description**<br>
 *    The __RulesController__ is controller which control the rules block state.<br><br>
 *  **List of methods**
 *  * [$onInit()]{@link app_controllers.RulesController.$onInit}
 *  * [$onChanges()]{@link app_controllers.RulesController.$onChanges}
 *  * [openNavigationTab()]{@link app_controllers.RulesController.openNavigationTab}
 *  * [closeNavigationTab()]{@link app_controllers.RulesController.closeNavigationTab}
 *  * [updateNavigationTabs()]{@link app_controllers.RulesController.updateNavigationTabs}
 *  * [toggleNavigationTab()]{@link app_controllers.RulesController.toggleNavigationTab}
 */
class RulesController {

    /**
     * @constructs
     * @memberOf app_controllers.RulesController
     * @description
     *  **Description**<br>
     *    This method contains all initializations for that class
     * @property {Object} rules - Contains the data about rules
     * @property {Object} navigation - Contains the state of navigation
     * @dependency {service} $rootScope {@link https://docs.angularjs.org/api/ng/service/$rootScope|link}
     * @dependency {service} $anchorScroll {@link https://docs.angularjs.org/api/ng/service/$anchorScroll|link}
     */
    constructor(
        $rootScope,
        $anchorScroll,
        CommonMethodsService,
        SharedDataService
    ) {

        // Extra dependencies
        this.$rootScope = $rootScope;
        this.$anchorScroll = $anchorScroll;
        this.CommonMethodsService = CommonMethodsService;
        this.SharedDataService = SharedDataService;

        // Properties
        this.rules = {};
        this.isSale;
        this.navigation = {
            isFareTabActive: false,
            isVisaTabActive: false,
            isBaggageTabActive: false
        };
    }

    /**
     * @name $onInit
     * @function $onInit
     * @memberOf app_controllers.RulesController
     * @description
     *  **Description**<br>
     *    The __$onInit__ lifecycle hook is for all initialisation of code for a Controller.
     */
    $onInit() {
        this.rules = this.appData.rules;
        this.appData.isHardcopy && this.updateNavigationTabs('open');

        this.isSale = this.CommonMethodsService.isCorrespondsToTypeOfSale(
            this.SharedDataService.saleType,
            [
                'SALE'
            ]
        );

        this.$rootScope.$on('open-rule-tab', (evant, data) => {
            this.openNavigationTab(data.tabName, data.id);
        });
    }

    /**
     * @name $onChanges
     * @function $onChanges
     * @memberOf app_controllers.RulesController
     * @description
     *  **Description**<br>
     *    The __$onChanges__ lifecycle hook is for update riles data.
     */
    $onChanges(changes) {
        if (!angular.equals({}, changes.appData.previousValue)) {
            this.rules = this.appData.rules;
        }
    }

    /**
     * @name openNavigationTab
     * @function openNavigationTab
     * @memberOf app_controllers.AppController
     * @description
     *  **Description**<br>
     *    The __openNavigationTab__ method open the tab with passed name and scrolls to element with passed ID.
     * @param {String} tabName - Tab name
     * @param {String} id - DOM element identifier
     */
    openNavigationTab(tabName,  id = 'js-tab-container') {
        this.updateNavigationTabs('close');
        this.navigation[`is${tabName}TabActive`] = true;

        this.$anchorScroll(id);
    }

    /**
     * @name closeNavigationTab
     * @function closeNavigationTab
     * @memberOf app_controllers.AppController
     * @description
     *  **Description**<br>
     *    The __closeNavigationTab__ method close the tab with passed name.
     * @param {String} tabName - Tab name
     */
    closeNavigationTab(tabName) {
        this.navigation[`is${tabName}TabActive`] = false;
    }

    /**
     * @name updateNavigationTabs
     * @function updateNavigationTabs
     * @memberOf app_controllers.AppController
     * @description
     *  **Description**<br>
     *    The __updateNavigationTabs__ method close the tab with passed name.
     * @param {String} action - Some action for navigation ( open || close ).
     */
    updateNavigationTabs(action) {
        let value = action === 'open';

        Object.keys(this.navigation).map(key => {
            this.navigation[key] = value;
        });
    }

    /**
     * @name toggleNavigationTab
     * @function toggleNavigationTab
     * @memberOf app_controllers.AppController
     * @description
     *  **Description**<br>
     *    The __toggleNavigationTab__ method ( open || close ) the tab with passed name.
     * @param {String} tabName - Tab name
     * @param {String} id - DOM element identifier
     */
    toggleNavigationTab(tabName, id = 'js-tab-container'){
        if (!this.appData.isHardcopy) {
            let key = `is${tabName}TabActive`;

            if (this.navigation[key]) {
                this.navigation[key] = false;
            }

            else {
                this.updateNavigationTabs('close');
                this.navigation[key] = true;
            }

            this.$anchorScroll(id);
        }
    }

}

// Injections of necessary dependencies
RulesController.$inject = [
    '$rootScope',
    '$anchorScroll',
    'CommonMethodsService',
    'SharedDataService'
];

// Default export
export default RulesController;