(function() {
    "use strict";

    angular.module('app.directives').directive('readOnlyOnBlur', function() {
        return {
            link: function(scope, element) {
                let isActive = false;

                element[0].setAttribute('readonly', true);

                element.on('focus', () => {
                    if (element[0].hasAttribute('readonly') && !isActive) {
                        isActive = true;
                        element[0].removeAttribute('readonly');
                        element[0].blur();
                        setTimeout(() => {
                            element[0].focus();
                        });
                    }
                });

                element.on('blur', () => {
                    if (!isActive) {
                        element[0].setAttribute('readonly', true);
                    }

                    isActive = false;
                });

                scope.$on('$destroy', () => {
                    element.off();
                })
            }
        };
    });

})();
