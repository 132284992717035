// Import dependencies
import angular from 'angular';
import Header from './header/header.module';
import DatePicker from './date-picker/date-picker.module';
import PackageSummary from './package-summary/package-summary.module';
import Hotel from './hotel/hotel.module';
import Flight from './flight/flight.module';
import SaleForm from './sale-form/sale-form.module';
import Footer from './footer/footer.module';
import SuccessPage from './success-page/success-page.module';
import ErrorPage from './error-page/error-page.module';
import Testimonials from './testimonials/testimonials.module'
import Verification from './verification/verfication.module'

/**
 * @name app.modules
 * @class ComponentsCollector
 * @memberOf app_file_collectors
 * @description
 *  **Description**<br>
 *    This module is a collector module. Purpose is to collect all modules inside it as dependencies.
 *    This approach helpful to minimize amount of dependencies in core module.
 */
const ComponentsCollector = angular.module('app.modules', [
    Header,
    DatePicker,
    PackageSummary,
    Hotel,
    Flight,
    SaleForm,
    Footer,
    SuccessPage,
    ErrorPage,
    Testimonials,
    Verification

]).name;

// Default export
export default ComponentsCollector;