/**
 * @name HeaderController
 * @class HeaderController
 * @memberOf app_controllers
 * @description
 *  **Description**<br>
 *    The __HeaderController__ is controller which control the header block state.<br><br>
 *  **List of methods**
 *  * [$onInit()]{@link app_controllers.HeaderController.$onInit}
 */
class HeaderController {

    /**
     * @constructs
     * @memberOf app_controllers.HeaderController
     * @description
     *  **Description**<br>
     *    This method contains all initializations for that class
     * @dependency {service} $rootScope {@link https://docs.angularjs.org/api/ng/service/$rootScope|link}
     * @dependency {library} $translate {@link https://github.com/angular-translate/angular-translate|link}
     * @dependency {service} SharedDataService {@link app_services.SharedDataService|link}
     */
    constructor(
        $rootScope,
        $translate,
        SharedDataService
    ) {

        // Extra dependencies
        this.$rootScope = $rootScope;
        this.$translate = $translate;
        this.SharedDataService = SharedDataService;
    }

    /**
     * @name $onInit
     * @function $onInit
     * @memberOf app_controllers.HeaderController
     * @description
     *  **Description**<br>
     *    The __$onInit__ lifecycle hook is for all initialisation of code for a Controller.
     */
    $onInit() {
        this.headerTitleKey = `header.title.${this.appData.headerTitle}`;
        this.confirmations = this.appData.confirmations;
        this.isLanguageSelectorHidden =
            this.appData.isHardcopy ||
            this.appData.cmsCompany === 'UK' ||
            this.SharedDataService.isAffirmSuccess;
    }

    /**
     * @name changeLanguage
     * @function changeLanguage
     * @memberOf app_services.HeaderController
     * @description
     *  **Description**<br>
     *    The __changeLanguage__ method change language for application.
     */
    changeLanguage() {
        this.$translate.use(this.appData.language);
    }
}

// Injections of necessary dependencies
HeaderController.$inject = [
    '$rootScope',
    '$translate',
    'SharedDataService'
];

// Default export
export default HeaderController;