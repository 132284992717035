import angular from 'angular';

(function() {
    'use strict';

    angular.module('app.directives').directive('autoComplete', [
        '$document',
        '$timeout',

        function(
            $document,
            $timeout
        ) {

            return {
                restrict: 'E',

                scope: {
                    list: '=',
                    originalModel: '=',
                    originalScope: '=',
                    form: '=',
                    language: '=',
                    zipCode: '=',
                    inputType: '@',
                    id: '@'
                },

                template: require(`./../templates/partials/autoComplete.html`),

                link: function(scope, element, attr, ctrl) {

                    function onClick(event){

                        if (event.target && event.target.classList && !event.target.classList.contains('js-autoc-close')) {

                            $document.off();

                            $timeout(function(){

                                scope.$apply(()=>{
                                    scope.autoc.isResultsActive = false;

                                    if (scope.autoc.isEmptyResult) {
                                        scope.autoc.searchText = '';
                                        scope.autoc.isEmptyResult = false;
                                    }
                                    else if (!scope.originalModel || angular.equals({},scope.originalModel)) {
                                        scope.autoc.searchText = '';
                                    }
                                    else {
                                        scope.autoc.searchText = scope.originalModel.title;
                                    }
                                });
                            });
                        }
                    }

                    scope.autoc = {
                        searchText: '',
                        defaultCountryCount: null,
                        errorLabelKey: 'visaInformation.popup.' + (scope.inputType === 'state' ? 'state' : 'country'),
                        isEmptyResult: false,
                        isResultsActive: false,
                        result: {},

                        searchInList: function() {
                            $document.on('click', onClick);

                            this.isResultsActive = true;
                            this.isEmptyResult = false;

                            if (this.searchText) {

                                if (scope && scope.originalScope && scope.originalScope.defaultCountryCount) {
                                    this.defaultCountryCount = scope.originalScope.defaultCountryCount;
                                    scope.originalScope.defaultCountryCount = 0;
                                }

                                this.result = [];

                                angular.forEach(scope.list, item => {
                                    if (item.title.indexOf(this.searchText) !== -1 || item.title.toLowerCase().indexOf(this.searchText) !== -1) {
                                        this.result.push(angular.copy(item));
                                    }
                                });

                                if (angular.equals({}, this.result)) {
                                    this.isEmptyResult = true;
                                }
                            }
                            else {
                                if (this.defaultCountryCount) {
                                    scope.originalScope.defaultCountryCount = this.defaultCountryCount;
                                }
                                this.result = scope.list;
                            }
                        },

                        itemChoice: function(event) {
                            if (event.target && event.target.id) {
                                let chosenItem = null;

                                this.result.map((country) => {
                                    if (country.code === event.target.id) {
                                        chosenItem = country;
                                    }
                                });

                                if (chosenItem) {
                                    $document.off();
                                    this.unsetZipCodeError();

                                    this.isResultsActive = false;
                                    this.searchText = chosenItem.title;
                                    scope.originalModel = chosenItem;

                                    if(scope.originalScope && scope.originalScope.appData && scope.originalScope.appData.isAffirm) {
                                        this.setAffirmError(event.target.id);
                                    }
                                }
                            }
                        },

                        unsetZipCodeError: function() {

                            if(scope.zipCode) {

                                scope.zipCode.$setValidity('not_valid',true);
                            }
                        },

                        setAffirmError: function(value) {

                            if(scope.inputType === 'country' && scope.form && scope.form.ctr && scope.form.st) {

                                if(value !== 'us') {
                                    scope.form.ctr.$setDirty(true);
                                    scope.form.ctr.$setValidity('affirmCountry', false);
                                    scope.form.st.$setValidity('affirmState', true);
                                } else {
                                    scope.form.ctr.$setValidity('affirmCountry', true);
                                }
                            }

                            if(scope.inputType === 'state' && scope.form && scope.form.st) {

                                // need to show affirm specific error if state is West Virginia('wv' code) or Iowa('ia' code)
                                if (value === 'wv' || value === 'ia') {
                                    scope.form.st.$setDirty(true);
                                    scope.form.st.$setValidity('affirmState', false);
                                } else {
                                    scope.form.st.$setValidity('affirmState', true);
                                }
                            }
                        }
                    };

                    if (scope.originalModel && scope.originalModel.code) {

                        let title = scope.language ? scope.originalModel.title[scope.language] : scope.originalModel.title;

                        scope.autoc.searchText = title;
                        scope.autoc.isResultsActive = false;
                    }

                    if (scope.inputType === 'state') {
                        scope.$watch('originalScope.payment.country.code', (newValue, oldValue) => {
                            scope.autoc.isResultsActive = false;
                            if (newValue && newValue !== oldValue) {
                                scope.autoc.searchText = '';
                                scope.originalModel = null;
                            }
                        });
                    }

                    if (scope.language && scope.inputType === 'country') {

                        scope.$watch('language', newValue => {

                            if (newValue && scope.originalModel) {
                                scope.autoc.searchText = scope.originalModel.title[scope.language];
                            }
                        });
                    }
                }
            };
        }]);
})();