// Define name spaces for jsDoc
/**
 * @namespace app_modules
 */

/**
 * @namespace app_components
 */

/**
 * @namespace app_controllers
 */

/**
 * @namespace app_factories
 */

/**
 * @namespace app_services
 */

/**
 * @namespace app_directives
 */

/**
 * @namespace app_file_collectors
 */

// Libraries import
import moment from 'moment-timezone';
import jstz from 'jstz';
import nouislider from 'nouislider';
import fingerprintjs2 from 'fingerprintjs2';
import intltelinput from 'intl-tel-input';

// Set to libraries as global
// TODO remove it's all and use as import in files where it's necessary START ------------------------------------------
window.baseUrl = window.baseUrl || '';
window.moment = moment;
window.jstz = jstz;
window.noUiSlider = nouislider;
window.Fingerprint2 = fingerprintjs2;
window.intltelinput = intltelinput;
// TODO end ------------------------------------------------------------------------------------------------------------

// Angular modules import
import angular from 'angular';
import 'angular-animate';
import 'angular-sanitize';
import 'angular-cookies';
import 'angular-resource';
import 'angular-translate';
import 'angular-messages';
import 'angular-translate-loader-static-files';
import 'angular-css';

import LogService from './services/log.service';
import NormalizeDataService from './services/normalize-data.service';
import CommonMethodsService from './services/common-methods.service';
import SharedDataService from './services/shared-data.service';
import ApiResponseHandlerService from './services/api-response-handler.service';
import AppService from './app.service';

// Define common modules
angular.module('app.modules', []);
angular.module('app.api', []);
angular.module('app.services', []);
angular.module('app.directives', ['app.services']);
angular.module('app.filters', ['app.services']);
angular.module('app.index', []);

/**
 * @name app.core
 * @class AppModule
 * @memberOf app_modules
 * @description
 *  **Description**<br>
 *    The __app.core__ module is the core of application and contains all necessary dependencies.
 *
 *  **Dependencies**<br>
 *  'ngAnimate'<br>
 *  'ngResource'<br>
 *  'ngCookies'<br>
 *  'angularCSS'<br>
 *  'app.api'<br>
 *  'app.services'<br>
 *  'app.directives'<br>
 *  'app.filters'<br>
 *  'app.index'<br>
 *  'pascalprecht.translate'<br>
 *  './components/components.module'
 *
 *   **Common stuff**<br>
 *   If you need to connect to application, some ( services, directives, factories etc. ) you must do it here.<br>
 *   _What is now connected_<br>
 *   * {@link app_services.NormalizeDataService|NormalizeDataService}
 *   * {@link app_services.CommonMethodsService|CommonMethodsService}
 *   * {@link app_services.ApiResponseHandlerService|ApiResponseHandlerService}
 */
angular.module('app.core', [
    'ngAnimate',
    'ngSanitize',
    'ngResource',
    'ngCookies',
    'ngMessages',
    'angularCSS',
    'app.api',
    'app.services',
    'app.directives',
    'app.filters',
    'app.index',
    'pascalprecht.translate',
    require('./components/components-collector.module').default

])
    .component('app', require('./app.component').default)
    .service('LogService', LogService)
    .service('NormalizeDataService', NormalizeDataService)
    .service('CommonMethodsService', CommonMethodsService)
    .service('SharedDataService', SharedDataService)
    .service('ApiResponseHandlerService', ApiResponseHandlerService)
    .service('AppService', AppService)
    .factory('$exceptionHandler', [
        '$log',
        '$injector',

        function(
            $log,
            $injector
        ) {
            return function errorCatcherHandler(exception, cause) {
                $log.error(exception, cause);

                $injector.get('LogService')
                    .assign({
                        exception,
                        appData: $injector.get('AppService').appData
                    })
                    .sendError();
            };
        }
    ]);

