// Import dependencies
import controller from './date-picker.controller';
import template from './date-picker.template.html';

/**
 * @name DatePickerComponent
 * @class DatePickerComponent
 * @memberOf app_components
 * @description
 *  **Description**<br>
 *    The __datePicker__ component contains all needed to display date picker. That include itself ( controller and template ).
 *    For more details see:
 *    * {@link app_controllers.DatePickerController|DatePickerController}
 *    * {@link https://gitlab.dyninno.net/oaf/standalone/blob/master/web/public/src/components/date-picker/date-picker.template.html|'datePicker' template}
 */
const DatePickerComponent = {
    controller,
    controllerAs: 'DatePickerCtrl',
    template,
    bindings: {
        appData: '<',
        passengerNumber: '<',
        passengerDob: '=',
        passengerType: '=',
        isFieldHighlighted: '<'
    }
};

// Default export
export default DatePickerComponent;