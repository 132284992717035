// Import dependencies
import controller from './test-charge.controller';
import template from './test-charge.template.html';

/**
 * @name TestChargeComponent
 * @class TestChargeComponent
 * @memberOf app_components
 * @description
 *  **Description**<br>
 *    The __testCharge__ component contains logic for test charge block. That include itself ( controller and template ).
 *    For more details see:
 *    * {@link app_controllers.TestChargeController|TestChargeController}
 *    * {@link https://gitlab.dyninno.net/oaf/standalone/blob/master/web/public/src/components/verification/test-charge/test-charge.template.html|'test-charge' template}
 */
const TestChargeComponent = {
    controller,
    controllerAs: 'TestChargeCtrl',
    template,
    bindings: {
        appData: '<',
        proceed: '&'
    },
    require: {
        app: '^app'
    }
};

// Default export
export default TestChargeComponent;