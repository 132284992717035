(function() {
    "use strict";

    angular.module('app.api')

        .factory('SaleApi', [
            '$resource',
            'UrlService',

            function(
                $resource,
                UrlService

            ) {

                return {
                    sale: $resource(`${baseUrl}/api/sales/${UrlService.queryParams.id}/:action1/:action2`, {}, {
                        'getSaleData'                 : { method: 'GET' },
                        'getHardcopy'                 : { method: 'GET', params: { action1: 'hardcopy'} },
                        'getVerificationHardcopy'     : { method: 'GET', params: { action1: 'verification', action2: 'hardcopy'} },
                        'getCountries'                : { method: 'GET', params: { action1: 'countries'} },
                        'getVisaData'                 : { method: 'GET', params: { action1: 'visa'} },
                        'validateSaleData'            : { method: 'POST', params: { action1: 'validation' } },
                        'postSaleData'                : { method: 'POST' },
                        'postTestimonialsData'        : { method: 'POST', params: { action1: 'testimonials' } },
                        'postResendEmailConfirmation' : { method: 'POST', params: { action1: 'confirmation' } },
                        'postVerificationCall'        : { method: 'POST', params: { action1: 'verification', action2: 'call' } },
                        'postVerificationCharge'      : { method: 'POST', params: { action1: 'verification', action2: 'charge' } },
                        'logError'                    : { method: 'POST', params: { action1: 'log' } },
                        'postAffirmState'             : { method: 'POST', params: { action1: 'credit', action2: 'state' } }
                    }),

                    hardcopy: $resource(`${baseUrl}/:action1/:action2/${UrlService.queryParams.id}`, {}, {
                            'getHardcopy'                 : { method: 'GET', params: { action1: 'hardcopy'} },
                            'getVerificationHardcopy'     : { method: 'GET', params: { action1: 'hardcopy', action2: 'verification'} }
                    })

                };
            }
        ])

        .factory('itnApi', [
            '$resource',

            function(
                $resource
            ) {
                return $resource(`${baseUrl}/api/:action1` , {}, {
                    'getCountries': { method: 'GET', params: { action1: 'countries'} }
                });
            }
        ]);
})();
