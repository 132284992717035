// Import dependencies
import controller from './error-page.controller';
import template from './error-page.template.html';

/**
 * @name ErrorPageComponent
 * @class ErrorPageComponent
 * @memberOf app_components
 * @description
 *  **Description**<br>
 *    The __errorPage__ component contains all needed to display error page. That include itself ( controller and template ).
 *    For more details see:
 *    * {@link app_controllers.ErrorPageController|ErrorPageController}
 *    * {@link https://gitlab.dyninno.net/oaf/standalone/blob/master/web/public/src/components/error-page/error-page.template.html|'error-page' template}
 * @todo remove 'itn' dependency
 */
const ErrorPageComponent = {
    controller: function() {},
    controllerAs: 'ErrorPageCtrl',
    template,
    bindings: {
        appData: '<'
    }
};

// Default export
export default ErrorPageComponent;