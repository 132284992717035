// Import dependencies
import template from './flight.template.html';

/**
 * @name FlightComponent
 * @class FlightComponent
 * @memberOf app_components
 * @description
 *  **Description**<br>
 *    The __flight__ component contains all needed to display 'flight' block. That include itself ( template ).
 *    For more details see:
 *    * {@link https://gitlab.dyninno.net/oaf/standalone/blob/master/web/public/src/components/flight/flight.template.html|'flight' template}
 */
const FlightComponent = {
    controller: function() {},
    controllerAs: 'FlightCtrl',
    template,
    bindings: {
        appData: '<'
    }
};

// Default export
export default FlightComponent;