(function() {
    "use strict";

    angular.module('app.directives').directive('tpPopup', [

        '$rootScope',
        'SharedDataService',

        function(
            $rootScope,
            SharedDataService
        ) {

            return {
                restrict: "E",
                scope: {
                    tp: '=',
                    protectionData: '=',
                    isPopup: '=',
                    popupCloseLogic: '='
                },
                template: require('./../templates/partials/tpPopup.html'),

                link : function(scope, element, attrs) {

                    scope.tp = {

                        isOpen: false,

                        openPopup: function(){
                            if (!SharedDataService.isAffirmSuccess) {

                                this.isOpen = true;
                                scope.isPopup = true;
                                scope.popupCloseLogic = this.closePopup.bind(this);

                            }
                        },

                        closePopup: function(){
                            this.isOpen = false;
                            scope.isPopup = false;
                            scope.popupCloseLogic = null;
                        },

                        setTicketProtection: function(value) {
                            scope.protectionData.isSelected = value;
                            $rootScope.$broadcast('update-total-amount');

                            this.closePopup();
                        }
                    };
                }
            };
        }]);
})();