// Import dependencies
import angular from 'angular';

/**
 * @class CommonMethodsService
 * @memberOf app_services
 * @description
 *  **Description**<br>
 *    The __CommonMethodsService__ is the class of data normalization.<br><br>
 *  **List of methods**
 *  [prepareCurrency()]{@link app_services.CommonMethodsService.prepareCurrency}
 *  [prepareConfirmations()]{@link app_services.CommonMethodsService.prepareConfirmations}
 *  [isCorrespondsToTypeOfSale()]{@link app_services.CommonMethodsService.isCorrespondsToTypeOfSale}
 *  [getSubmitButtonTextKey()]{@link app_services.CommonMethodsService.getSubmitButtonTextKey}
 *  [createNumberList()]{@link app_services.CommonMethodsService.createNumberList}
 *  [preparePrice()]{@link app_services.CommonMethodsService.preparePrice}
 *  [getData()]{@link app_services.CommonMethodsService.getData}
 *  [prepareTipText()]{@link app_services.CommonMethodsService.prepareTipText}
 *  [setErrors()]{@link app_services.CommonMethodsService.setErrors}
 *  [setServerErrors()]{@link app_services.CommonMethodsService.setServerErrors}
 *  [isFieldErrorShown()]{@link app_services.CommonMethodsService.isFieldErrorShown}
 *  [handleValidationRejection()]{@link app_services.CommonMethodsService.handleValidationRejection}
 *  [scrollToError()]{@link app_services.CommonMethodsService.scrollToError}
 */
class CommonMethodsService {

    /**
     * @constructs
     * @memberOf app_services.CommonMethodsService
     * **Description**<br>
     *   This method contains all initializations for that class
     * @dependency {service} $timeout {@link https://docs.angularjs.org/api/ng/service/$timeout|link}
     * @dependency {service} $anchorScroll {@link https://docs.angularjs.org/api/ng/service/$anchorScroll|link}
     * @dependency {service} NormalizeDataService {@link app_services.NormalizeDataService|link}
     */
    constructor(
        $timeout,
        $anchorScroll,
        NormalizeDataService
    ) {

        // Extra dependencies
        this.$timeout = $timeout;
        this.$anchorScroll = $anchorScroll;
        this.NormalizeDataService = NormalizeDataService;
    }

    /**
     * @name prepareCurrency
     * @function prepareCurrency
     * @memberOf app_services.CommonMethodsService
     * @description
     *  **Description**<br>
     *    The __prepareCurrency__ method prepares currency for application.
     * @param {String} currency - currency ( USD, CAD etc. )
     * @returns {Object} Prepared currency object ( contains sign, code, title and signOrCode )
     */
    prepareCurrency(currency) {
        let tempCurrency = {
            title: currency
        };

        // There are overall 4 different combinations we need in OAF for currency displaying which depend which countries currency we have to use
        // First: "$" or "CAD ", Second: "USD" or "CAD ", Third: "$" or " ", Fourth: "USD" or "CAD"
        // Because of that we need separate variables for those values
        if (currency === 'USD') {
            tempCurrency.sign = tempCurrency.signOrCode = tempCurrency.code = '$';
        }
        else {

            if (currency === 'CAD') {
                tempCurrency.sign = 'C$';
            }
            else if (currency === 'GBP') {
                tempCurrency.sign = '£';
            }

            tempCurrency.signOrCode = tempCurrency.code = currency + ' ';
        }

        return tempCurrency;
    }

    /**
     * @name prepareConfirmations
     * @function prepareConfirmations
     * @memberOf app_services.CommonMethodsService
     * @description
     *  **Description**<br>
     *    The __prepareConfirmations__ method prepares confirmations. Used for passenger program.
     * @param {Object} confirmation - confirmations
     * @returns {Object} Prepared object of confirmations
     * @todo maybe need move this to BE part
     */
    prepareConfirmations(confirmation) {
        let tempConfirmations = {
            airlines: {},
            itn: confirmation.itn
        };

        angular.forEach(confirmation.airlines, function(airline, key) {
            tempConfirmations.airlines[airline.code] = {
                title: airline.title,
                confirmation: airline.confirmation
            };
        });

        return tempConfirmations;
    }

    /**
     * @name isCorrespondsToTypeOfSale
     * @function isCorrespondsToTypeOfSale
     * @memberOf app_services.CommonMethodsService
     * @description
     *  **Description**<br>
     *    The __isCorrespondsToTypeOfSale__ method execute search of the transmitted 'saleType' in the list of sale types.
     *    Possible sale types: 'SALE', 'REFUND', 'EXCHANGE', 'EARLY_VOID', 'LATE_VOID'
     * @param {String} saleType - sale type
     * @param {Array} types - list of sale types where we must make search
     * @returns {Boolean} Return 'true' if 'saleType' exist in 'types'
     */
    isCorrespondsToTypeOfSale(saleType, types) {
        let isSaleType = false;

        for (let i = 0; i < types.length; i++) {
            if (saleType === types[i]) {
                isSaleType = true;
                break;
            }
        }

        return isSaleType;
    }

    /**
     * @name getSubmitButtonTextKey
     * @function getSubmitButtonTextKey
     * @memberOf app_services.CommonMethodsService
     * @description
     *  **Description**<br>
     *    The __getSubmitButtonTextKey__ method defines text key for submit button by sale type
     * @param {String} saleType - sale type
     * @returns {String} Text key for submit button
     * @todo move this to submit component
     */
    getSubmitButtonTextKey(saleType) {
        let key;

        switch (saleType) {

            case 'SALE':
                key = 'submit.paySecurelyNow';
                break;

            case 'EXCHANGE':
                key = 'submit.requestExchange';
                break;

            case 'REFUND':
                key = 'submit.requestRefund';
                break;

            case 'EARLY_VOID':
            case 'LATE_VOID':
                key = 'submit.requestCancellation';
                break;

            default:
                break;
        }

        return key;
    }

    /**
     * @name createNumberList
     * @function createNumberList
     * @memberOf app_services.CommonMethodsService
     * @description
     *  **Description**<br>
     *    The __createNumberList__ method create the array of numbers.
     * @param {Number} from - From what number
     * @param {Number} upTo - Until what number
     * @returns {Array} Prepared price
     */
    createNumberList(from, upTo) {
        let array = [];
        let i = from;

        if (from <= upTo) {
            for (i; i <= upTo; i++) array.push(i);
        }
        else {
            for (i; i >= upTo; i--) array.push(i);
        }

        return array;
    }

    /**
     * @name preparePrice
     * @function preparePrice
     * @memberOf app_services.CommonMethodsService
     * @description
     *  **Description**<br>
     *    The __preparePrice__ method prepares the price. Example: USD 45, CAD 17.75 etc.
     * @param {String} currency - Sale currency
     * @param {String|Number} value - Price value
     * @param {String} space - Key for space
     * ( if passed as 'space' the price will contains the space between currency and price value )
     * @returns {String} Prepared price
     */
    preparePrice(currency, value, space) {
        let price;

        if (space === 'space') {
            price = `${currency} ${value}`;
        }

        else {
            price = currency + value;
        }

        return price;
    }

    /**
     * @name getData
     * @function getData
     * @memberOf app_services.CommonMethodsService
     * @description
     *  **Description**<br>
     *    The __getData__ method provide the functionality to get any data from passed source object.
     * @param {String} path - property name, may be as chain. Example: 'submittedData.passengers'
     *  If property passed as empty string, method returns full source.
     * @param {String} source - source where will occurs search
     * @returns {*} Requested property data
     */
    getData(path, source) {
        let data = angular.copy(source);

        if (path && data) {
            let splittedProperty = path.split('.');

            for (let i = 0; i < splittedProperty.length; i++) {
                data = data[splittedProperty[i]];

                if (this.NormalizeDataService.typeOf(data) === 'undefined') {
                    data = null;
                    break;
                }
            }
        }

        return data;
    }


    /**
     * @name prepareTipText
     * @function prepareTipText
     * @memberOf app_services.CommonMethodsService
     * @description
     *  **Description**<br>
     *    The __prepareTipText__ method defines which range tipamount belongs to and returns according language key.
     * @param {Number} tipAmount - selected tip amount
     * @param {Array} tipLevels - array of numbers representing tip ranges
     * @returns {String} tip text language key
     */
    prepareTipText(tipAmount, tipLevels){

        let tips = Number(tipAmount);

        let tiprange = tips < tipLevels[1] ? '0'
                     : tips < tipLevels[2] ? '1'
                     : tips < tipLevels[3] ? '2'
                     : tips < tipLevels[4] ? '3'
                     : tips < tipLevels[5] ? '4'
                     : tips < tipLevels[6] ? '5' : '6';


        return 'total.tips.tipsText.' + tiprange;
    }



    // TODO move to validation service ---------------------------------------------------------------------------------
    /**
     * @name setErrors
     * @function setErrors
     * @memberOf app_services.CommonMethodsService
     * @description
     *  **Description**<br>
     *    The __setErrors__ method iterates through form and subforms to set all fields to $dirty state for validation.
     * @param {Object} form - angular form
     */
    setErrors(form) {
        angular.forEach(form.$error, error => {
            angular.forEach(error, field => {

                // If field has '$modelValue', it is form field, setting it to dirty state
                if (field.hasOwnProperty('$modelValue')) {
                    field.$setDirty();

                    // Otherwise it is a sub-form, calling setError recursively
                }
                else {
                    this.setErrors(field);
                }
            });
        });
    }

    /**
     * @name setServerErrors
     * @function setServerErrors
     * @memberOf app_services.CommonMethodsService
     * @description
     *  **Description**<br>
     *    The __setServerErrors__ method iterates through form and sub-forms to set errors to invalid fields.
     * @param {Object} errors - errors from server
     * @param {Object} form - angular form
     */
    setServerErrors(errors, form) {
        angular.forEach(errors, (error, key) => {

            let invalidField;

            if (
                this.NormalizeDataService.typeOf(key) === 'string' &&
                key.indexOf('.') !== -1
            ) {

                let subForm, field;
                [subForm, field] = key.split('.');

                invalidField = form[subForm][field];
            }
            else {
                invalidField = form[key];
            }


            if (invalidField && typeof invalidField.$setDirty === 'function') {
                invalidField.$setDirty();
                invalidField.$setValidity(error, false);
            }
        });
    }

    /**
     * @name handleValidationRejection
     * @function handleValidationRejection
     * @memberOf app_services.CommonMethodsService
     * @description
     *  **Description**<br>
     *    The __isFormErrorShown__ method returns true if field is $invalid and $dirty
     * @param {Object} appData - application data object
     * @param {Object} rejection - rejection data
     * @param {Object} form - angular form for error setting
     */
    handleValidationRejection(appData, rejection, form){

        appData.isSubmitBtnDisabled = false;

        if (
            rejection.data &&
            rejection.data.state === 'validation' &&
            this.NormalizeDataService.typeOf(rejection.data.result) === 'object'
        ) {
            this.setServerErrors(rejection.data.result, form);
            this.scrollToError();
        }
        else {
            appData.state = 'error';
        }
    }

    /**
     * @name isFieldErrorShown
     * @function isFieldErrorShown
     * @memberOf app_services.CommonMethodsService
     * @description
     *  **Description**<br>
     *    The __isFormErrorShown__ method returns true if field is $invalid and $dirty
     * @param {Object} field - angular form field
     * @returns {Boolean} Return 'true' if 'if field is $invalid and $dirty
     */
    isFieldErrorShown(field) {
        let isError = false;
        if (angular.isObject(field)) {
            isError = field.$invalid && field.$dirty;
        }
        return isError;
    }

    /**
     * @name scrollToError
     * @function scrollToError
     * @memberOf app_services.CommonMethodsService
     * @description
     *  **Description**<br>
     *    The __scrollToError__ method find first invalid field and scroll the page to it.
     */
    scrollToError() {
        this.$timeout(() => {
            let firstInvalidField = document.querySelector('.js-error-field.ng-invalid');

            if (firstInvalidField && firstInvalidField.id) {
                this.$anchorScroll.yOffset = 50;
                this.$anchorScroll(firstInvalidField.id);
            }

        }, 300);
    }
    // TODO move to validation service ---------------------------------------------------------------------------------
}

// Injections of necessary dependencies
CommonMethodsService.$inject = [
    '$timeout',
    '$anchorScroll',
    'NormalizeDataService'
];

// Default export
export default CommonMethodsService;