/**
 * @class SharedDataService
 * @memberOf app_services
 * @description
 *  **Description**<br>
 *    The __SharedDataService__ is responsible for holding dynamic values required for every component.<br><br>
 */
class SharedDataService {
    /**
     * @constructs
     * @memberOf app_services.SharedDataService
     * @description This method contains all initializations for that class
     */
    constructor(

    ) {
        this.isHardcopy = false;
        this.saleType = 'SALE';
        this.language = 'en';
        this.currency = {
            code: '$',
            sign: '$',
            signOrCode: '$',
            title: 'USD'
        };
        this.cmsCompany = 'ITN';
        this.confirmations = {};
        this.affirmTotal = 0;
        this.isAffirmSuccess = false;
    }
}

// Default export
export default SharedDataService;