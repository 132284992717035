/**
 * @name PaymentService
 * @class PaymentService
 * @memberOf app_services
 * @description
 *  **Description**<br>
 *    The __PaymentService__ contains logic to prepare data for payment block.<br><br>
 *  **List of methods**
 *  * [preparePaymentDataToSend()]{@link app_services.PaymentService.preparePaymentDataToSend}
 *  * [createDataForHardcopy()]{@link app_services.PaymentService.createDataForHardcopy}
 *  * [prepareAffirmPayment()]{@link app_services.PaymentService.prepareAffirmPayment}
 *  * [prepareAffirmBreakdown()]{@link app_services.PaymentService.prepareAffirmBreakdown}
 */
class PaymentService {

    /**
     * @constructs
     * @memberOf app_services.PaymentService
     * @description
     *  **Description**<br>
     *    This method contains all initializations for that class
     * @dependency {service} $window {@link https://docs.angularjs.org/api/ng/service/$window|link}
     * @dependency {service} NormalizeDataService {@link app_services.NormalizeDataService|link}
     */
    constructor(
        $window,
        NormalizeDataService
    ) {

        // Extra dependencies
        this.$window = $window;
        this.NormalizeDataService = NormalizeDataService;
    }

    /**
     * @name preparePaymentDataToSend
     * @function preparePaymentDataToSend
     * @memberOf app_services.PaymentService
     * @description
     *  **Description**<br>
     *    The __preparePaymentDataToSend__ method prepares payment for send to server
     * @param {Object} appData - application data
     * @returns {Object} Prepared payment
     */
    preparePaymentDataToSend(appData) {
        let payment = appData.payment;
        let isCardPayment = appData.paymentType !== 'wt';

        let tempPayment = {
            ticket_total: {
                total_amount: payment.ticket_total.total_amount,
                total_amount_string: payment.ticket_total.total_amount_string,
                total_tp_amount: payment.ticket_total.total_tp_amount,
                total_tp_amount_string: payment.ticket_total.total_tp_amount_string
            },
            tip_amount: payment.tip_amount,
            ticket_protection_selected: appData.protection.isSelected,
            payment_type: isCardPayment ? 'card' : 'wt',
            isCreditSelected:  appData.payment.isCreditSelected === '1' ? '1' : '0'
        };

        // TODO discus with BE, not needed this expression
        // Change to - tempPayment.ticket_total.special_discount = payment.ticket_total.special_discount
        if (payment.ticket_total.special_discount) {
            tempPayment.specialDiscount = payment.ticket_total.special_discount;
        }

        if (isCardPayment) {

            tempPayment.billing_phone_number = this.$window.intlTelInputUtils.formatNumber(
                payment.billing_phone_number,
                null,
                0
            );

            tempPayment.city = payment.city;
            tempPayment.country = payment.country ? payment.country.code : null;
            tempPayment.cardholder_email = payment.cardholder_email;
            tempPayment.state = payment.state ? payment.state.code : null;
            tempPayment.street_address = payment.street_address;
            tempPayment.zip_code = payment.zip_code;

            if (tempPayment.country) {
                tempPayment.countryData = {
                    country: payment.country.title
                };

                if (payment.country.states && payment.state) {
                    tempPayment.countryData.state = payment.state.title || null;
                }
            }
        }

        return tempPayment;
    }

    createDataForHardcopy(serverData) {
        let totalAmountValue;
        let totalAmountText;
        let data = serverData.payment;

        if (serverData.submittedData.payment.ticket_protection_selected === '1') {
            totalAmountValue = serverData.submittedData.payment.ticket_total.total_tp_amount;
            totalAmountText = serverData.submittedData.payment.ticket_total.total_tp_amount_string;
        }
        else {
            totalAmountValue = serverData.submittedData.payment.ticket_total.total_amount;
            totalAmountText = serverData.submittedData.payment.ticket_total.total_amount_string;
        }

        let creditcard = serverData.submittedData.creditcard;
        if (this.NormalizeDataService.typeOf(creditcard) === 'object') {
            creditcard.expiration_date.y = +creditcard.expiration_date.y; // For old sales ( HARDCOPY )
        }

        angular.merge(data, {
            confirmedTips: serverData.submittedData.payment.tip_amount,
            totalAmountValue: totalAmountValue,
            totalAmountText: totalAmountText,
            specialDiscount: serverData.submittedData.payment.specialDiscount || null,
            ticket_protection_selected: serverData.submittedData.payment.ticket_protection_selected,
            billing_phone_number: serverData.submittedData.payment.billing_phone_number,
            city: serverData.submittedData.payment.city,
            country: serverData.submittedData.payment.country,
            cardholder_email: serverData.submittedData.payment.cardholder_email,
            state: serverData.submittedData.payment.state,
            street_address: serverData.submittedData.payment.street_address,
            zip_code: serverData.submittedData.payment.zip_code,
            creditcard: creditcard
        });

        if (serverData.submittedData.payment.countryData) {
            data.countryName = serverData.submittedData.payment.countryData.country;
            data.stateName = serverData.submittedData.payment.countryData.state;
        }

        return data;
    }


    /**
     * @name prepareAffirmPayment
     * @function prepareAffirmPayment
     * @memberOf app_services.PaymentService
     * @description
     *  **Description**<br>
     *    The __prepareAffirmPayment__ method prepares payment amount for affirm
     * @param {Object} data - application data
     * @returns {Number} Prepared payment amount for affirm
     */
    prepareAffirmPayment(data) {
        let paymentAmount = data.protection.isSelected === '1' ?
            data.payment.ticket_total.total_tp_amount :
            data.payment.ticket_total.total_amount;

        return paymentAmount;
    }

    /**
     * @name prepareAffirmBreakdown
     * @function prepareAffirmBreakdown
     * @memberOf app_services.PaymentService
     * @description
     *  **Description**<br>
     *    The __prepareAffirmBreakdown__ method prepares charge breakdown items
     * @param {Object} data - application data
     * @returns {Array} Prepared charge breakdown items
     */
    prepareAffirmBreakdown(data){

        let items = [];
        let sku = data.flight.itineraries["0"].segments["0"].details.flight;

        let airlineAmount = data.payment.split_section.airline_amount;
        let itnAmount = data.payment.split_section.itn_amount;
        let tpAmount = data.payment.ticket_protection.tp_amount;
        let specialDiscount = data.payment.ticket_total.special_discount;

        if (itnAmount) {
            items.push({
                display_name: data.total.itnAmountText,
                sku: sku,
                unit_price: specialDiscount ? Math.round(itnAmount-specialDiscount) : itnAmount,
                qty: 1
            })
        }

        if (airlineAmount) {
            items.push({
                display_name: data.total.airlineAmountText,
                sku: sku,
                unit_price: airlineAmount,
                qty: 1
            })
        }

        if (data.protection && data.protection.isSelected === '1') {
            items.push({
                display_name: 'Ticket Protection (non-refundable charge by ITN LLC)',
                sku: sku,
                unit_price: tpAmount,
                qty: 1
            })
        }

        if (data.total && data.total.confirmedTips) {
            items.push({
                display_name: 'Token of Appreciation (non-refundable charge by ITN LLC)',
                sku: sku,
                unit_price: data.total.confirmedTips,
                qty: 1
            })
        }

        if (data.hotel && data.hotel.isChargeAtCheckIn || data.hotel && data.hotel.isChargePrePayment) {
            items.push({
                display_name: data.hotel.locationText,
                sku: sku,
                unit_price: data.hotel.price,
                qty: 1
            })
        }

        return items;
    }
}

// Injections of necessary dependencies
PaymentService.$inject = [
    '$window',
    'NormalizeDataService'
];

// Default export
export default PaymentService;
