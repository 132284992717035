// Import dependencies
import controller from './rules.controller';
import template from './rules.template.html';

/**
 * @name RulesComponent
 * @class RulesComponent
 * @memberOf app_components
 * @description
 *  **Description**<br>
 *    The __rules__ component contains all needed to display 'rules' block. That include itself ( controller and template ).
 *    For more details see:
 *    * {@link app_controllers.RulesController|RulesController}
 *    * {@link https://gitlab.dyninno.net/oaf/standalone/blob/master/web/public/src/components/rules/rules.template.html|'rules' template}
 */
const RulesComponent = {
    controller,
    controllerAs: 'RulesCtrl',
    template,
    bindings: {
        appData: '<'
    }
};

// Default export
export default RulesComponent;