(function() {
    "use strict";

    angular.module('app.directives').directive('birthDate',[

        'NormalizeDataService',
        'AppService',

        function(
            NormalizeDataService,
            AppService
        ) {

        return {
            require: 'ngModel',
            scope: false,
            link: function(scope, element, attrs, model) {

                model.$formatters.push(value => {
                    if (value) {

                        // Set birthdate field to dirty state if value changed or received
                        model.$setDirty();
                    }

                    // format view value
                    return NormalizeDataService.formatDateOfBirth(value, AppService.appData.cmsCompany);
                });
            }
        };
    }]);
})();