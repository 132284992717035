(function() {
    "use strict";

    angular.module('app.directives').directive('termsPopup', [

        function(

        ) {

            return {
                restrict: "E",
                scope: {
                    terms: '=',
                    protectionData: '=',
                    isPopup: '=',
                    popupCloseLogic: '='
                },
                template: require('./../templates/partials/terms.html'),

                link : function(scope, element, attrs) {

                    scope.terms = {

                        isOpen: false,

                        openPopup: function(){
                            this.isOpen = true;
                            scope.isPopup = true;
                            scope.popupCloseLogic = this.closePopup.bind(this);
                        },

                        closePopup: function(){
                            this.isOpen = false;
                            scope.isPopup = false;
                            scope.popupCloseLogic = null;
                        }
                    };
                }
            };
        }]);
})();