// Import dependencies
import angular from 'angular';

/**
 * @name AppController
 * @class AppController
 * @memberOf app_controllers
 * @description
 *  **Description**<br>
 *    The __PassengersController__ is root controller for application.<br><br>
 *  **List of methods**
 *  * [$onInit()]{@link app_controllers.AppController.$onInit}
 *  * [getMainData()]{@link app_controllers.AppController.getMainData}
 *  * [getHardcopyData()]{@link app_controllers.AppController.getHardcopyData}
 *  * [getVerificationHardcopyData()]{@link app_controllers.AppController.getVerificationHardcopyData}
 *  * [prepareData()]{@link app_controllers.AppController.prepareData}
 *  * [isStateName()]{@link app_controllers.AppController.isStateName}
 *  * [openRuleTab()]{@link app_controllers.AppController.openRuleTab}
 */
class AppController {

    /**
     * @constructs
     * @memberOf app_controllers.AppController
     * @description
     *  **Description**<br>
     *    This method contains all initializations for that class
     * @property {Object} appData - Contains the data for all application
     * @dependency {service} $rootScope {@link https://docs.angularjs.org/api/ng/service/$rootScope|link}
     * @dependency {library} $translate {@link https://github.com/angular-translate/angular-translate|link}
     * @dependency {library} $q {@link https://docs.angularjs.org/api/ng/service/$q|link}
     * @dependency {service} SaleApi {@link app_factories.SaleApi|link}
     * @dependency {service} UrlService {@link app_services.UrlService|link}
     * @dependency {service} NormalizeDataService {@link app_services.NormalizeDataService|link}
     * @dependency {service} SharedDataService {@link app_services.SharedDataService|link}
     * @dependency {service} LogService {@link app_services.LogService|link}
     * @dependency {service} ApiResponseHandlerService {@link app_services.ApiResponseHandlerService|link}
     * @dependency {service} AppService {@link app_services.AppService|link}
     * @dependency {service} VerificationService {@link app_services.VerificationService|link}
     */
    constructor(
        $rootScope,
        $translate,
        $q,
        SaleApi,
        UrlService,
        NormalizeDataService,
        SharedDataService,
        LogService,
        ApiResponseHandlerService,
        AppService,
        VerificationService,
        saleStatus
    ) {

        // Extra dependencies
        this.$rootScope = $rootScope;
        this.$translate = $translate;
        this.$q = $q;
        this.SaleApi = SaleApi;
        this.UrlService = UrlService;
        this.NormalizeDataService = NormalizeDataService;
        this.SharedDataService = SharedDataService;
        this.LogService = LogService;
        this.ApiResponseHandlerService = ApiResponseHandlerService;
        this.AppService = AppService;
        this.VerificationService = VerificationService;
        this.saleStatus = saleStatus;

        // Properties
        this.appData = {
            isInit: true,
            state: 'loading',
            loadingTextKey: 'loading',
            isFormSubmitted: false,
            isSubmitBtnDisabled: false,
            isSaleSubmitError: false,
            isPopup: false,
            popupCloseLogic: null,
            errorMsgKey: 'problemWithLink',
            total: {}
        };
    }

    /**
     * @name $onInit
     * @function $onInit
     * @memberOf app_controllers.AppController
     * @description
     *  **Description**<br>
     *    The __$onInit__ lifecycle hook is for all initialisation of code for a Controller.
     */
    $onInit() {
        if (this.UrlService.queryParams.id) {

            if (window.location.href.search('/hardcopy/verification') !== -1 || this.UrlService.queryParams.vhc) {
                this.getVerificationHardcopyData();
            }
            else if (window.location.href.search('/hardcopy') !== -1 || this.UrlService.queryParams.hc) {
                this.getHardcopyData();
            }
            else {
                this.getMainData();
            }
        }
        else {
            this.SharedDataService.state = 'error';
        }
    }

    /**
     * @name getMainData
     * @function getMainData
     * @memberOf app_services.AppController
     * @description
     *  **Description**<br>
     *    The __getMainData__ method is responsible to execute API request to get sale data.
     */
    getMainData() {
        this.SaleApi.sale.getSaleData({},
            response => {
                if (this.ApiResponseHandlerService.checkSaleData(response)) {
                    this.appData.isInit = false;
                    this.prepareData(response.state, response);
                } else {
                    this.prepareData('error', {
                        result: {}
                    });
                }
            },
            rejection => {
                this.prepareData('error', rejection.data);
            }
        );
    }


    /**
     * @name closePopup
     * @function closePopup
     * @memberOf app_services.AppController
     * @description
     *  **Description**<br>
     *    The __closePopup__ closes popup overlay and calls popup close logic.
     */
    closePopup() {
        if (this.appData.popupCloseLogic && typeof this.appData.popupCloseLogic === 'function') {
            this.appData.popupCloseLogic();
        }
    }

    /**
     * @name getHardcopyData
     * @function getHardcopyData
     * @memberOf app_services.AppController
     * @description
     *  **Description**<br>
     *    The __getHardcopyData__ method is responsible to execute API request to get hardcopy data.
     */
    getHardcopyData() {

        let hardcopyApi = this.UrlService.queryParams.hc ?
            // Old Method
            'sale' :
            // New Method
            'hardcopy';


        this.SaleApi[hardcopyApi].getHardcopy({},
            response => {
                if (this.ApiResponseHandlerService.checkHardcopyData(response)) {
                    this.prepareData('hardcopy', response).then(() => {

                        // Logging successful hc only during the old method
                        if (this.UrlService.queryParams.hc) {

                            this.LogService
                                .assign({
                                    _target: 'create-hardcopy',
                                    appData: this.appData
                                })
                                .sendInfo();
                        }

                    });
                } else {
                    // TODO do something if response is not as expected.
                    console.log('Data for hardcopy not valid...');
                }
            }
        );
    }

    /**
     * @name getVerificationHardcopyData
     * @function getVerificationHardcopyData
     * @memberOf app_services.AppController
     * @description
     *  **Description**<br>
     *    The __getVerificationHardcopyData__ method is responsible to execute API request to get verification hardcopy data.
     */
    getVerificationHardcopyData() {

        let hardcopyApi = this.UrlService.queryParams.hc ?
            // Old Method
            'sale' :
            // New Method
            'hardcopy';

        this.SaleApi[hardcopyApi].getVerificationHardcopy({},
            response => {
                if (this.ApiResponseHandlerService.checkVerificationHardcopyData(response)) {
                    this.prepareData('verification_hardcopy', response).then(() => {
                        this.LogService
                            .assign({
                                _target: 'create-verification-hardcopy',
                                appData: this.appData
                            })
                            .sendInfo();
                    });
                } else {
                    // TODO do something if response is not as expected.
                    console.log('Data for verification hardcopy not valid...');
                }
            }
        );
    }

    /**
     * @name prepareData
     * @function prepareData
     * @memberOf app_services.AppController
     * @description
     *  **Description**<br>
     *    The __prepareData__ method prepares data for application. In fact this method as switch.
     *    Based on the param 'key' it call a necessary method to prepare data.
     * @param {Object} key - the name of data that need prepare.
     * @param {Object} response - response form server.
     */
    prepareData(key, response) {
        let data;
        let defer = this.$q.defer();
        let serverData = angular.copy(response ? response.result : null);

        if (this.NormalizeDataService.typeOf(serverData) === 'object') {
            let language = this.AppService.defineLanguage(serverData);
            serverData.appState = response.state;

            this.$translate.use(language).then(() => {

                switch (key) {

                    case this.saleStatus.SALE_DEFAULT:
                        data = this.AppService.createDefaultSaleData(serverData, {
                            isHardcopy: false
                        });
                        break;

                    case this.saleStatus.SALE_UPDATED:
                        data = this.AppService.updateSaleData(this.appData, serverData);
                        break;

                    case this.saleStatus.SALE_HARDCOPY:
                        data = this.AppService.createHardcopyData(serverData);
                        break;

                    case this.saleStatus.SALE_VERIFICATION_HARDCOPY:
                        data = this.AppService.createVerificationHardcopyData(serverData);
                        break;

                    case this.saleStatus.SUBMITTED_WITHOUT_VERIFICATION:
                        switch (this.VerificationService.resolveVerificationType(serverData.verification_type)) {
                            case 'test-charge':
                                data = this.AppService.createVerificationTestChargeData(serverData);
                                break;

                            case 'conference-call':
                                data = this.AppService.createVerificationConferenceCallData(serverData);
                                break;

                            case 'none':
                                data = this.AppService.createTestimonialsData(serverData);
                                break;
                        }

                        break;

                    case this.saleStatus.SUBMITTED_WITHOUT_TESTIMONIALS:
                        data = this.AppService.createTestimonialsData(serverData);
                        break;

                    case this.saleStatus.SALE_FILLED_AND_SUBMITTED:
                        data = this.AppService.createSuccessData(serverData);
                        break;

                    default:
                        data = this.AppService.createErrorData(serverData);
                        break;
                }

                this.AppService.appData = this.appData = angular.copy(angular.merge(this.appData, data));
                defer.resolve();

                window.isHardcopyReady = true;
            });
        }
        else {
            this.prepareData('error', {
                result: {}
            });
        }

        return defer.promise;
    }

    /**
     * @name isStateName
     * @function isStateName
     * @memberOf app_services.AppController
     * @description
     *  **Description**<br>
     *    The __isStateName__ method is responsible for state handling
     * @param {String} state - state name
     * @returns {Boolean} returns true if state param equals app state
     */
    isStateName(state) {
        return state === this.appData.state;
    }

    /**
     * @name openRuleTab
     * @function openRuleTab
     * @memberOf app_controllers.AppController
     * @description
     *  **Description**<br>
     *    The __openRuleTab__ method broadcast the event 'open-rule-tab'. Used for interaction with Rules component.
     * @param {String} tabName - Tab name
     * @param {String} id - DOM element identifier
     */
    openRuleTab(tabName, id) {
        this.$rootScope.$broadcast('open-rule-tab', {
            tabName: tabName,
            id: id
        });
    }
}

// Injections of necessary dependencies
AppController.$inject = [
    '$rootScope',
    '$translate',
    '$q',
    'SaleApi',
    'UrlService',
    'NormalizeDataService',
    'SharedDataService',
    'LogService',
    'ApiResponseHandlerService',
    'AppService',
    'VerificationService',
    'app.saleStatus'
];

// Default export

export default AppController;
