// Import dependencies
import angular from 'angular';
import ProgramComponent from './program.component';

/**
 * @name app.program
 * @class ProgramModule
 * @memberOf app_modules
 * @description
 *  **Description**<br>
 *    The __app.program__ module is module that is responsible to make all preparation of data for passenger
 *    program block and display him in application. This module consist from 'ProgramComponent'.
 *    What it makes, see in relevant section.
 *    * {@link app_components.ProgramComponent|ProgramComponent}
 */
const ProgramModule = angular.module('app.program', [])
    .component('program', ProgramComponent)
    .name;

// Default export
export default ProgramModule;