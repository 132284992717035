/**
 * @name FooterController
 * @class FooterController
 * @memberOf app_controllers
 * @description
 *  **Description**<br>
 *    The __FooterController__ is controller which control the footer block state.<br><br>
 *  **List of methods**
 *  * [$onInit()]{@link app_controllers.FooterController.$onInit}
 */
class FooterController {

    /**
     * @constructs
     * @memberOf app_controllers.FooterController
     * @description
     *  **Description**<br>
     *    This method contains all initializations for that class
     */
    constructor() {}

    /**
     * @name $onInit
     * @function $onInit
     * @memberOf app_controllers.FooterController
     * @description
     *  **Description**<br>
     *    The __$onInit__ lifecycle hook is for all initialisation of code for a Controller.
     */
    $onInit() {
        this.isUkFooter = this.appData.cmsCompany === 'UK';
        this.copyRightYear = new Date().getFullYear();
    }

}

// Default export
export default FooterController;