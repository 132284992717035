// Import dependencies
import angular from 'angular';

/**
 * @class NormalizeDataService
 * @memberOf app_services
 * @description
 *  **Description**<br>
 *    The __NormalizeDataService__ is the class of data normalization.
 *    Basically methods in this class do some preparation of data.
 *    Each of methods have return statement and do not mutate the received attributes.<br><br>
 *  **List of methods**
 *  * {@link app_services.NormalizeDataService.copyProperties|copyProperties()}
 *  * {@link app_services.NormalizeDataService.typeOf|typeOf()}
 *  * {@link app_services.NormalizeDataService.formatDateOfBirth|formatDateOfBirth()}
 */
class NormalizeDataService {

    /**
     * @constructs
     * @memberOf app_services.NormalizeDataService
     * @description This method contains all initializations for that class
     */
    constructor() {}

    /**
     * @name copyProperties
     * @function copyProperties
     * @memberOf app_services.NormalizeDataService
     * @description
     *  **Description**<br>
     *    The __copyProperties__ is method that copies properties from the source object.<br>
     *    __NOTE__: This method can copy only the first level of the object
     * @param {Object} source - the object from which certain data will be copied
     * @param {Array} properties - the names of properties which will be copied from source object
     * @returns {Object} The new object which contains all properties from transferred properties array
     */
    copyProperties(source, properties) {
        let newObject = {};
        let sourceCopy = angular.copy(source);

        angular.forEach(properties, function(property) {
            newObject[property] = sourceCopy[property] !== undefined ? sourceCopy[property] : '';
        });

        return newObject;
    }

    /**
     * @name typeOf
     * @function typeOf
     * @memberOf app_services.NormalizeDataService
     * @description
     *  **Description**<br>
     *    The __typeOf__ is method that defines the type of passed value ( Object, String, Array etc. ).
     * @param {*} value - any variable
     * @returns {String} The type of passed variable
     */
    typeOf(value) {
        return ({}).toString.call(value).match(/\s([a-zA-Z]+)/)[1].toLowerCase();
    }

    /**
     * @name formatDateOfBirth
     * @function formatDateOfBirth
     * @memberOf app_services.NormalizeDataService
     * @description
     *  **Description**<br>
     *    The __formatDateOfBirth__ is method that formats date depending
     *    on location of cms company ( UK, ITN, PH, ME, BOG, CA etc.)
     * @param {String} date - date to format, in format YYYY-MM-DD
     * @param {String} cmsCompany - cms company location
     * @returns {String} The date in corresponding format
     */
    formatDateOfBirth(date, cmsCompany) {

        let dob = '';

        if (angular.isString(date) && date.split('-').length === 3) {

            // splitting YYYY-MM-DD
            let tempDate = date.split('-');

            if (cmsCompany !== 'UK') {
                // MM/DD/YYYY for non-UK sales
                dob = `${tempDate[1]}/${tempDate[2]}/${tempDate[0]}`;
            } else {
                // DD/MM/YYYY for UK sales
                dob = `${tempDate[2]}/${tempDate[1]}/${tempDate[0]}`;
            }
        }

        return dob;
    }
}

// Default export
export default NormalizeDataService;