// Import dependencies
import angular from 'angular';

/**
 * @name PackageSummaryService
 * @class PackageSummaryService
 * @memberOf app_services
 * @description
 *  **Description**<br>
 *    The __PackageSummaryService__ contains logic to prepare data for package summary block.<br><br>
 *  **List of methods**
 *  * [fetchData()]{@link app_services.PackageSummaryService.fetchData}
 *  * [createData()]{@link app_services.PackageSummaryService.createData}
 *  * [getFlight()]{@link app_services.PackageSummaryService.getFlight}
 */
class PackageSummaryService {

    /**
     * @constructs
     * @memberOf app_services.PackageSummaryService
     * @description
     *  **Description**<br>
     *    This method contains all initializations for that class
     * @dependency {library} $translate {@link https://github.com/angular-translate/angular-translate|link}
     * @dependency {service} NormalizeDataService {@link app_services.NormalizeDataService|link}
     * @dependency {service} CommonMethodsService {@link app_services.CommonMethodsService|link}
     * @dependency {service} SharedDataService {@link app_services.SharedDataService|link}
     */
    constructor(
        NormalizeDataService,
        CommonMethodsService,
        SharedDataService
    ) {

        // Extra dependencies
        this.NormalizeDataService = NormalizeDataService;
        this.CommonMethodsService = CommonMethodsService;
        this.SharedDataService = SharedDataService;

        // Properties
        this.packageSummaryData = null;
    }

    /**
     * @name fetchData
     * @function fetchData
     * @memberOf app_services.PackageSummaryService
     * @description
     *  **Description**<br>
     *    The __fetchData__ method returns prepared package summary data.
     *    If data already were created method returns data otherwise create data, assigns it to
     *    'packageSummaryData' variable and returns created data.
     * @returns {Object} Prepared package summary data
     */
    fetchData(serverData) {
        let data;

        if (this.packageSummaryData) {
            data = this.packageSummaryData;
        }
        else if(serverData) {
            data = this.packageSummaryData = this.createData(serverData);
        }
        else {
            data = null;
        }

        return data;
    }

    /**
     * @name createData
     * @function createData
     * @memberOf app_services.PackageSummaryService
     * @description
     *  **Description**<br>
     *    The __createData__ method execute all necessary actions to prepare protection data.
     * @param {Object} serverData - source data
     * @returns {Object} prepared data for protection block
     */
    createData(serverData) {
        let data;

        this.hotel = serverData.hotel;
        this.trip = serverData.trip;

        if (this.hotel && this.trip) {
            data = {
                isCarRequested: serverData.submittedData ? serverData.submittedData.isCarRequested : false,
                hotel: this.NormalizeDataService.copyProperties(this.hotel, [
                    'checkIn',
                    'checkOut',
                    'title',
                    'address'
                ]),
                flight: this.getFlight()
            };
        }
        else {
            data = null;
        }

        return data;
    }

    /**
     * @name getFlight
     * @function getFlight
     * @memberOf app_services.PackageSummaryService
     * @description
     *  **Description**<br>
     *    The __getFlight__ method prepares flight data.
     * @returns {Object} prepared flight data
     */
    getFlight() {
        let lastItinerary = this.trip.itineraries[this.trip.itineraries.length-1];
        let lastSegment = lastItinerary.segments[lastItinerary.segments.length-1];
        let finalDestination;

        angular.forEach(this.trip.itineraries, itinerary => {
            angular.forEach(itinerary.segments, segment => {
                if (segment.final_destination) {
                    finalDestination = segment.final_destination;
                }
            });
        });

        return {
            finalDestination : finalDestination,
            checkIn: {
                en: this.CommonMethodsService.getData('0.segments.0.departure.date.en', this.trip.itineraries),
                es: this.CommonMethodsService.getData('0.segments.0.departure.date.es', this.trip.itineraries)
            },
            checkOut: {
                en: this.CommonMethodsService.getData('destination.date.en', lastSegment),
                es: this.CommonMethodsService.getData('destination.date.es', lastSegment)
            },
            airline: {
                title: this.CommonMethodsService.getData('0.segments.0.flight_details.airline.details.title', this.trip.itineraries),
                logo: this.CommonMethodsService.getData('0.segments.0.flight_details.airline.logotype', this.trip.itineraries)
            }
        };
    }

}

// Injections of necessary dependencies
PackageSummaryService.$inject = [
    'NormalizeDataService',
    'CommonMethodsService',
    'SharedDataService'
];

// Default export
export default PackageSummaryService;