// Import dependencies
import template from './testimonials.template.html';

/**
 * @name TestimonialsComponent
 * @class TestimonialsComponent
 * @memberOf app_components
 * @description
 *  **Description**<br>
 *    The __Testimonials__ component contains logic for testimonials page. That include itself ( template ).
 *    For more details see:
 *    * {@link https://gitlab.dyninno.net/oaf/standalone/blob/master/web/public/src/components/testimonials/testimonials.template.html|'testimonials' template}
 */
const TestimonialsComponent = {
    controller: function() {},
    controllerAs: 'TestimonialsCtrl',
    template,
    bindings: {
        appData: '<'
    }
};

// Default export
export default TestimonialsComponent;