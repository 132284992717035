// Import dependencies
import controller from './app.controller';
import template from './app.template.html';

/**
 * @name AppComponent
 * @class AppComponent
 * @memberOf app_components
 * @description
 *  **Description**<br>
 *    The __app__ component contains all needed to display application. That include itself ( controller and template ).
 *    This component contains common logic for the application and also includes within self all necessary services
 *    for managing the application
 *    For more details see:
 *    * {@link app_controllers.AppController|AppController}
 *    * {@link https://gitlab.dyninno.net/oaf/standalone/blob/master/web/public/src/app.template.html|'app' template}
 */
const AppComponent = {
    controller,
    controllerAs: 'AppCtrl',
    template
};

// Default export
export default AppComponent;