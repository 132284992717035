// Import dependencies
import controller from './header.controller';
import template from './header.template.html';

/**
 * @name HeaderComponent
 * @class HeaderComponent
 * @memberOf app_components
 * @description
 *  **Description**<br>
 *    The __header__ component contains all needed to display 'header' block. That include itself ( controller and template ).
 *    For more details see:
 *    * {@link app_controllers.HeaderController|HeaderController}
 *    * {@link https://gitlab.dyninno.net/oaf/standalone/blob/master/web/public/src/components/header/header.template.html|'header' template}
 */
const HeaderComponent = {
    controller,
    controllerAs: 'HeaderCtrl',
    template,
    bindings: {
        appData: '<'
    }
};

// Default export
export default HeaderComponent;