(function() {
    "use strict";

    angular.module('app.filters').filter('price', [
        function() {

            return function(input, currency, space) {
                let price = (input / 100).toFixed(2).toString();

                if (input >= 100000) {
                    price = price.split('');
                    price.splice(price.length - 6, 0, ',');
                    price = price.join('');
                }

                if (currency) {
                    if (space === 'space') {
                        price = `${currency} ${price}`;
                    }
                    else {
                        price = currency + price;
                    }
                }

                return price;
            }

        }
    ]);

})();