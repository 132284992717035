// Import dependencies
import angular from 'angular';
import SuccessPageComponent from './success-page.component';

/**
 * @name app.successPage
 * @class SuccessPageModule
 * @memberOf app_modules
 * @description
 *  **Description**<br>
 *    The __app.successPage__ module is module that is responsible to make all preparation of data for success page
 *    and display him in application. This module consist from 'SuccessPageComponent'.
 *    What it makes, see in relevant section.
 *    * {@link app_components.SuccessPageComponent|SuccessPageComponent}
 */
const SuccessPageModule = angular.module('app.successPage', [])
    .component('successPage', SuccessPageComponent)
    .name;

// Default export
export default SuccessPageModule;