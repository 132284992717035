// Import dependencies
import angular from 'angular';
import ResendComponent from './resend.component';

/**
 * @name app.resend
 * @class ResendModule
 * @memberOf app_modules
 * @description
 *  **Description**<br>
 *    The __app.resend__ module is responsible to make all preparation of data for resend block
 *    and display him in application. This module consist from 'ResendComponent'.
 *    What it makes, see in relevant section.
 *    * {@link app_components.ResendComponent|ResendComponent}
 */

const ResendModule = angular.module('app.resend', [])
    .component('resend', ResendComponent)
    .name;

// Default export
export default ResendModule;