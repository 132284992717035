/**
 * @name PreferencesController
 * @class PreferencesController
 * @memberOf app_controllers
 * @description
 *  **Description**<br>
 *    The __PreferencesController__ is controller which control the preferences block state.<br><br>
 *  **List of methods**
 *  * [$onInit()]{@link app_controllers.PreferencesController.$onInit}
 *  * [togglePreferences()]{@link app_controllers.PreferencesController.togglePreferences}
 *  * [isPreferencesVisible()]{@link app_controllers.PreferencesController.isPreferencesVisible}
 */
class PreferencesController {

    /**
     * @constructs
     * @memberOf app_controllers.PreferencesController
     * @description
     *  **Description**<br>
     *    This method contains all initializations for that class
     * @dependency {service} SharedDataService {@link app_services.SharedDataService|link}
     */
    constructor(
        SharedDataService,
        programPreferences
    ) {

        // Extra dependencies
        this.SharedDataService = SharedDataService;
        this.preferences = programPreferences;
    }

    /**
     * @name $onInit
     * @function $onInit
     * @memberOf app_controllers.PassengersController
     * @description
     *  **Description**<br>
     *    The __$onInit__ lifecycle hook is for all initialisation of code for a Controller.
     */
    $onInit() {
        this.passenger.isPreferencesOpened = false;
    }

    /**
     * @name togglePreferences
     * @function togglePreferences
     * @memberOf app_controllers.PassengersController
     * @description
     *  **Description**<br>
     *    The __togglePreferences__ method opens a preferences block or close it.
     */
    togglePreferences() {
        this.passenger.isPreferencesOpened = !this.passenger.isPreferencesOpened;
    }

    /**
     * @name isPreferencesVisible
     * @function isPreferencesVisible
     * @memberOf app_controllers.PassengersController
     * @description
     *  **Description**<br>
     *    The __isPreferencesVisible__ method defines the need of display the block of passenger preferences.
     * @returns {Boolean} Return 'true' if the block of passenger preferences need display.
     */
    isPreferencesVisible() {
        return this.passenger.isPreferencesOpened || this.SharedDataService.isHardcopy;
    }
}

// Injections of necessary dependencies
PreferencesController.$inject = [
    'SharedDataService',
    'app.programPreferences'
];

// Default export
export default PreferencesController;