// Import dependencies
import angular from 'angular';
import FlightComponent from './flight.component';
import FlightService from './flight.service';

/**
 * @name app.flight
 * @class FlightModule
 * @memberOf app_modules
 * @description
 *  **Description**<br>
 *    The __app.flight__ module is module that is responsible to make all preparation of data for flight block
 *    and display him in application. This module consist from 'FlightComponent' and 'FlightService'.
 *    What make each of them, see in relevant section.
 *    * {@link app_components.FlightComponent|FlightComponent}
 *    * {@link app_services.FlightService|FlightService}
 */
const FlightModule = angular.module('app.flight', [])
    .component('flight', FlightComponent)
    .service('FlightService', FlightService)
    .name;

// Default export
export default FlightModule;