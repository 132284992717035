// Import dependencies
import angular from 'angular';
import TestimonialsComponent from './testimonials.component';
import Resend from './resend/resend.module';
import Review from './review/review.module';

/**
 * @name app.testimonials
 * @class TestimonialsModule
 * @memberOf app_modules
 * @description
 *  **Description**<br>
 *    The __app.testimonials__ module is responsible to make all preparation of data for testimonials page
 *    and display him in application. This module consist from 'TestimonialsComponent'.
 *    What it makes, see in relevant section.
 *    * {@link app_components.TestimonialsComponent|TestimonialsComponent}
 */

const TestimonialsModule = angular.module('app.testimonials', [
    Resend,
    Review
])
    .component('testimonials', TestimonialsComponent)
    .name;

// Default export
export default TestimonialsModule;