// Import dependencies
import angular from 'angular';
import ProtectionComponent from './protection.component';
import ProtectionService from './protection.service';

/**
 * @name app.protect
 * @class ProtectionModule
 * @memberOf app_modules
 * @description
 *  **Description**<br>
 *    The __app.protection__ module is module that is responsible to make all preparation of data for protection block
 *    and display him in application. This module consist from 'ProtectionComponent' and 'ProtectionService'.
 *    What make each of them, see in relevant section.
 *    * {@link app_components.ProtectionComponent|ProtectionComponent}
 *    * {@link app_services.ProtectionService|ProtectionService}
 */
const ProtectionModule = angular.module('app.protection', [])
    .component('protection', ProtectionComponent)
    .service('ProtectionService', ProtectionService)
    .name;

// Default export
export default ProtectionModule;