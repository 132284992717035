// Import dependencies
import angular from 'angular';
import PackageSummaryComponent from './package-summary.component';
import PackageSummaryService from './package-summary.service';

/**
 * @name app.package-summary
 * @class PackageSummaryModule
 * @memberOf app_modules
 * @description
 *  **Description**<br>
 *    The __app.package-summary__ module is module that is responsible to make all preparation of data for package summary block
 *    and display him in application. This module consist from 'PackageSummaryComponent' and 'PackageSummaryService'.
 *    What make each of them, see in relevant section.
 *    * {@link app_components.PackageSummaryComponent|PackageSummaryComponent}
 *    * {@link app_services.PackageSummaryService|PackageSummaryService}
 */
const PackageSummaryModule = angular.module('app.package-summary', [])
    .component('packageSummary', PackageSummaryComponent)
    .service('PackageSummaryService', PackageSummaryService)
    .name;

// Default export
export default PackageSummaryModule;