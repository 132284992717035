(function() {

    "use strict";

    angular.module('app.services')
        .service('tokenService', [
            'UrlService',

            function(
                UrlService

            ) {
                this.request = function(config) {
                    config.headers = config.headers || {};
                    if (config.url.indexOf('api/sales/') >= 0) {
                        if (UrlService.queryParams.hc) {
                            if (config.params) {
                                config.params.hc = UrlService.queryParams.hc;
                            } else {
                                config.params = {
                                    hc: UrlService.queryParams.hc
                                };
                            }
                        }
                        if (UrlService.queryParams.vhc) {
                            if (config.params) {
                                config.params.vhc = UrlService.queryParams.vhc;
                            } else {
                                config.params = {
                                    vhc: UrlService.queryParams.vhc
                                };
                            }
                        }
                    }
                    return config;
                };
            }
        ]);
})();
