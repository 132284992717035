// Import dependencies
import controller from './tips.controller';
import template from './tips.template.html';

/**
 * @name TotalComponent
 * @class TotalComponent
 * @memberOf app_components
 * @description
 *  **Description**<br>
 *    The __total__ component contains all needed to display 'tips' block. That include itself ( controller and template ).
 *    For more details see:
 *    * {@link app_controllers.TipsController|TipsController}
 *    * {@link https://gitlab.dyninno.net/oaf/standalone/blob/master/web/public/src/components/tips/tips.template.html|'tips' template}
 */
const TipsComponent = {
    controller,
    controllerAs: 'TipsCtrl',
    template,
    bindings: {
        appData: '<'
    }
};

// Default export
export default TipsComponent;