// Import dependencies
import angular from 'angular';
import StackTrace from 'stacktrace-js';

/**
 * @class LogService
 * @memberOf app_services
 * @description
 *  **Description**<br>
 *    The __LogService__ is the class of data normalization.<br><br>
 *  **List of methods**
 *  * [getStackTrace()]{@link app_services.LogService.getStackTrace}
 *  * [assign()]{@link app_services.LogService.assign}
 *  * [send()]{@link app_services.LogService.send}
 *  * [sendError()]{@link app_services.LogService.sendError}
 *  * [sendInfo()]{@link app_services.LogService.sendInfo}
 *  * [prepareData()]{@link app_services.LogService.prepareData}
 */
class LogService {

    /**
     * @constructs
     * @memberOf app_services.LogService
     * @description
     * **Description**<br>
     *   This method contains all initializations for that class.<br><br>
     * @dependency {service} $q {@link https://docs.angularjs.org/api/ng/service/$q|link}
     * @dependency {service} $injector {@link https://docs.angularjs.org/api/auto/service/$injector|link}
     */
    constructor(
        $q,
        $injector
    ) {

        // Extra dependencies
        this.$q = $q;
        this.$injector = $injector;

        // Properties
        this.dataToSend = {};
        this.maxErrorCount = 10;
    }

    /**
     * @name getStackTrace
     * @function getStackTrace
     * @memberOf app_services.LogService
     * @description
     *  **Description**<br>
     *    The __getStackTrace__ method collect common data for logging.
     * @param {Object} exception - exception data object
     * @returns {Promise} Promise of 'StackTrace'. It may contain resolved data of exception or
     *   the error of preparation of that data.
     */
    getStackTrace(exception) {
        let defer = this.$q.defer();

        StackTrace.fromError(exception).then(stackFrames => {
            defer.resolve({
                type: 'success',
                stackFrames: stackFrames,
                message: exception.toString()
            });
        }).catch(stackTraceException => {
            defer.resolve({
                type: 'error',
                error: stackTraceException,
                message: exception.toString()
            });
        });

        return defer.promise;
    }

    /**
     * @name assign
     * @function assign
     * @memberOf app_services.LogService
     * @description
     *  **Description**<br>
     *    The __assign__ method assigns data to send.
     *  @param {Object} data - data to send
     */
    assign(data) {
        angular.forEach(data, (value, key) => {
            this.dataToSend[key] = value;
        });

        return this;
    }

    /**
     * @name send
     * @function send
     * @memberOf app_services.LogService
     * @description
     *  **Description**<br>
     *    The __send__ method makes sending log data to server.
     */
    send() {
        this.maxErrorCount--;

        if (this.maxErrorCount > 0) {
            this.$injector.get('SaleApi').sale.logError(this.prepareData());
        }
    }

    /**
     * @name sendError
     * @function sendError
     * @memberOf app_services.LogService
     * @description
     *  **Description**<br>
     *    The __sendError__ method prepares data and calls send() method to send data to server.
     *    This method used for logging 'application exceptions' or for some rejections of requests.
     */
    sendError() {
        this.dataToSend._level = 'error';

        this.getStackTrace(this.dataToSend.exception).then((result) => {
            this.dataToSend._message = result.message;

            if (result.type === 'success') {
                this.dataToSend._stackFrames = result.stackFrames;
            }

            this.send();
        });
    }

    /**
     * @name sendInfo
     * @function sendInfo
     * @memberOf app_services.LogService
     * @description
     *  **Description**<br>
     *    The __sendInfo__ method prepares data and calls send() method to send data to server.
     *    This method used for logging some info data for application life cycle ( send form, create data, etc. ).
     */
    sendInfo() {
        this.dataToSend._level = 'info';
        this.send();
    }

    /**
     * @name prepareData
     * @function prepareData
     * @memberOf app_services.LogService
     * @description
     *  **Description**<br>
     *    The __prepareData__ method returns prepared log data.
     * @returns {Object} Prepared log data
     */
    prepareData() {
        let tempData = angular.copy(this.dataToSend);
        delete tempData.exception;

        if (tempData.appData) {
            delete tempData.appData.creditcard;

            if (tempData.appData.hotel) {
                delete tempData.appData.hotel.amenities;
            }

            if (tempData.appData.payment) {
                delete tempData.appData.payment.country;
                delete tempData.appData.payment.creditcard;
            }
            else if (tempData.appData.sale && tempData.appData.sale.payment) {
                delete tempData.appData.sale.payment.country;
            }

            if (tempData.appData.passengers) {
                angular.forEach(tempData.appData.passengers, function(passenger) {
                    delete passenger.dates;
                });
            }

            if (tempData.appData.sale) {
                delete tempData.appData.sale.creditcard;
                angular.forEach(tempData.appData.sale.passengers, function(passenger) {
                    delete passenger.dates;
                });
            }

        }

        this.dataToSend = {};

        return tempData;
    }

}

// Injections of necessary dependencies
LogService.$inject = [
    '$q',
    '$injector'
];

// Default export
export default LogService;
