// Import dependencies
import angular from 'angular';
import SaleFormComponent from './sale-form.component';
import Passengers from '../passengers/passengers.module';
import Rules from '../rules/rules.module';
import Protection from '../protection/protection.module';
import Payment from '../payment/payment.module';
import Total from '../total/total.module';
import Tips from '../tips/tips.module';
import FormSubmit from '../form-submit/form-submit.module';

/**
 * @name app.saleForm
 * @class SaleFormModule
 * @memberOf app_modules
 * @description
 *  **Description**<br>
 *    The __app.saleForm__ module is module that is responsible to make all preparation of data for sale form block
 *    and display him in application. This module consist from 'SaleFormComponent'.
 *    What it makes, see in relevant section.
 *    * {@link app_components.SaleFormComponent|SaleFormComponent}
 */
const SaleFormModule = angular.module('app.saleForm', [
    Passengers,
    Rules,
    Protection,
    Payment,
    Total,
    Tips,
    FormSubmit
])
    .component('saleForm', SaleFormComponent)
    .name;

// Default export
export default SaleFormModule;