// Import dependencies
import angular from 'angular';
import ConferenceCallComponent from './conference-call.component';

/**
 * @name app.conference-call
 * @class ConferenceCallModule
 * @memberOf app_modules
 * @description
 *  **Description**<br>
 *    The __app.conferenceCall__ module is responsible to make all preparation of data for conference call block
 *    and display him in application. This module consist from 'ConferenceCallComponent'.
 *    What it makes, see in relevant section.
 *    * {@link app_components.ConferenceCallComponent|ConferenceCallComponent}
 */

const ConferenceCallModule = angular.module('app.conference-call', [])
    .component('conferenceCall', ConferenceCallComponent)
    .name;

// Default export
export default ConferenceCallModule;