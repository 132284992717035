(function() {
    "use strict";

    angular.module('app.directives').directive('disablePageScroll', [

        function() {

            return {
                restrict: "A",
                scope: {},

                link : function(scope, element, attrs) {

                    let $body = document.querySelector('body');
                    $body.classList.add('overflow-hidden');

                    scope.$on("$destroy", function(){
                        $body.classList.remove('overflow-hidden');
                    });

                }
            };
        }]);
})();

