// Import dependencies
import angular from 'angular';
import ErrorPageComponent from './error-page.component';

/**
 * @name app.errorPage
 * @class ErrorPageModule
 * @memberOf app_modules
 * @description
 *  **Description**<br>
 *    The __app.errorPage__ module is module that is responsible to make all preparation of data for error page
 *    and display him in application. This module consist from 'ErrorPageComponent'.
 *    What it makes, see in relevant section.
 *    * {@link app_components.ErrorPageComponent|ErrorPageComponent}
 */
const ErrorPageModule = angular.module('app.errorPage', [])
    .component('errorPage', ErrorPageComponent)
    .name;

// Default export
export default ErrorPageModule;