// Import dependencies
import angular from 'angular';
import moment from 'moment-timezone';

/**
 * @name DatePickerController
 * @class DatePickerController
 * @memberOf app_controllers
 * @description
 *  **Description**<br>
 *    The __DatePickerController__ is controller which control the date picker.<br><br>
 *  **List of methods**
 *  * [$onInit()]{@link app_controllers.DatePickerController.$onInit}
 *  * [updateYears()]{@link app_controllers.DatePickerController.updateYears}
 *  * [updateShownDate()]{@link app_controllers.DatePickerController.updateShownDate}
 *  * [updatePassengerDob()]{@link app_controllers.DatePickerController.updatePassengerDob}
 *  * [updatePassengerType()]{@link app_controllers.DatePickerController.updatePassengerType}
 *  * [prepareDateOfBirth()]{@link app_controllers.DatePickerController.prepareDateOfBirth}
 *  * [selectDate()]{@link app_controllers.DatePickerController.selectDate}
 *  * [selectedDayClass()]{@link app_controllers.DatePickerController.selectedDayClass}
 *  * [toggleVisibility()]{@link app_controllers.DatePickerController.toggleVisibility}
 *  * [close()]{@link app_controllers.DatePickerController.close}
 */
class DatePickerController {

    /**
     * @constructs
     * @memberOf app_controllers.DatePickerController
     * @description
     *  **Description**<br>
     *    This method contains all initializations for that class
     * @property {String} dayOfWeek - used for offset creation in CSS, for days.
     * @property {Boolean} isVisible - describes is date picker visible or not.
     * @property {Object} availableChoiceOf - available choices of ( years, months and days ).
     * @property {Object} chosen - the date that selected by user.
     * @dependency {service} NormalizeDataService {@link app_services.NormalizeDataService|link}
     * @dependency {service} CommonMethodsService {@link app_services.CommonMethodsService|link}
     */
    constructor(
        NormalizeDataService,
        CommonMethodsService,
        dateSettings
    ) {

        // Extra dependencies
        this.NormalizeDataService = NormalizeDataService;
        this.CommonMethodsService = CommonMethodsService;
        this.dateSettings = dateSettings;

        // Properties
        this.dayOfWeek = null;
        this.isVisible = false;
        this.availableChoiceOf = {
            days: [],
            months: [],
            years: []
        };
        this.chosen = {
            day: null,
            month: null,
            year: null
        };

        this.passengerMonth = 0;
        this.passengerYear = 0;
        this.dayClass = '';
    }

    /**
     * @name $onInit
     * @function $onInit
     * @memberOf app_controllers.DatePickerController
     * @description
     *  **Description**<br>
     *    The __$onInit__ lifecycle hook is for all initialisation of code for a Controller.
     */
    $onInit() {
        if (this.passengerDob) {
            this.chosen = this.prepareDateOfBirth(angular.copy(this.passengerDob));
            this.updateShownDate();
            this.updatePassengerType();
        }

        this.updateYears();
    }

    /**
     * @name updateYears
     * @function updateYears
     * @memberOf app_controllers.DatePickerController
     * @description
     *  **Description**<br>
     *    The __updateYears__ method updates years in date picker.
     */
    updateYears() {
        this.availableChoiceOf.years = this.CommonMethodsService.createNumberList(
            new Date().getFullYear(),
            this.dateSettings.minimalYear
        );
    }

    /**
     * @name updateShownDate
     * @function updateShownDate
     * @memberOf app_controllers.DatePickerController
     * @description
     *  **Description**<br>
     *    The __updateShownDate__ method update visible days when year/month is selected
     */
    updateShownDate() {
        let today = new Date();
        let currentDay = today.getDate();
        let currentMonth = (today.getMonth()+1);
        let currentYear = today.getFullYear();

        let firstMonth = this.dateSettings.firstMonth;
        let lastMonth = this.chosen.year !== currentYear ? this.dateSettings.lastMonth : currentMonth;

        this.availableChoiceOf.months = this.CommonMethodsService.createNumberList(firstMonth, lastMonth);

        // After ( year and month ) are selected we can update days.
        if (this.chosen.year && this.chosen.month) {
            let daysInMonth = new Date(this.chosen.year, this.chosen.month, 0).getDate();
            this.dayOfWeek = `day-${new Date(this.chosen.year, this.chosen.month - 1, 1).getDay()}`;
            this.availableChoiceOf.days = this.CommonMethodsService.createNumberList(
                this.dateSettings.firstDay,
                daysInMonth
            );

            // Resetting day choice if selected month has less days
            if (this.chosen.day > daysInMonth){
                this.chosen.day = null;
            }

            // If chosen year equal to current year, we need check, if selected ( month and day )
            // are available for choice.
            if (this.chosen.year === currentYear) {

                // If chosen month bigger of current month - sets ( month and day ) to null.
                if (this.chosen.month > currentMonth) {
                    this.chosen.month = null;
                    this.chosen.day = null;
                }

                // If chosen month equal of current month - we update available days and check chosen day.
                else if (this.chosen.month === currentMonth) {

                    // If present year & month selected, days in list will be up to present date
                    this.availableChoiceOf.days = this.CommonMethodsService.createNumberList(
                        this.dateSettings.firstDay,
                        currentDay
                    );

                    // Resetting day choice if current year & month are selected and day selected is in future
                    if (this.chosen.day > currentDay){
                        this.chosen.day = null;
                    }
                }
            }
        }

        // changing css class to highlight selected day
        this.selectedDayClass();
    }

    /**
     * @name updatePassengerDob
     * @function updatePassengerDob
     * @memberOf app_controllers.DatePickerController
     * @description
     *  **Description**<br>
     *    The __updatePassengerDob__ method updates date in the input field.
     */
    updatePassengerDob() {
        if (this.chosen.year && this.chosen.month && this.chosen.day) {

            let month = this.chosen.month < 10 ? '0'+this.chosen.month : this.chosen.month;
            let day = this.chosen.day < 10 ? '0'+this.chosen.day : this.chosen.day;

            this.passengerDob = `${this.chosen.year}-${month}-${day}`;
        }
    }

    /**
     * @name updatePassengerType
     * @function updatePassengerType
     * @memberOf app_controllers.DatePickerController
     * @description
     *  **Description**<br>
     *    The __updatePassengerType__ method updates passenger type in the input field.
     */
    updatePassengerType() {
        let itineraries = this.appData.flight.itineraries;

        if (itineraries && itineraries.length) {

            // If all birth data select boxes have a selected value we accordingly check and update passengerType
            let lastItinerary = itineraries[itineraries.length - 1];

            // We find from our data last itineraries last segment destination arrival time
            let segmentCount = lastItinerary.segments.length - 1;
            let lastSegment = lastItinerary.segments[segmentCount];

            // We format dateOfBirth and lastArrivalDate using moment by passing previous value and making them same style
            let dateOfBirth = moment(`${this.chosen.year}-${this.chosen.month}-${this.chosen.day}`, 'YYYY-MM-DD');
            let lastArrivalDate = moment(lastSegment.destination.dateTime.split(' ')[0], 'MM/DD/YYYY');

            // We compare lastArrivalDate with dateOfBirth and save how many full years were selected
            let passengerAge = lastArrivalDate.diff(dateOfBirth, 'y');

            this.passengerType = passengerAge < this.dateSettings.childMinAge ? 'infant' :
                                 passengerAge < this.dateSettings.adultMinAge ? 'child'  : 'adult';
        }
    }

    /**
     * @name prepareDateOfBirth
     * @function prepareDateOfBirth
     * @memberOf app_services.PassengersService
     * @description
     *  **Description**<br>
     *    The __prepareDateOfBirth__ method prepares date of birth.
     * @param {Object} dob - passenger DOB
     * @returns {Object} Prepared date of birth
     */
    prepareDateOfBirth(dob) {
        let tempDate = {};

        // Way to prepare dob if it passed in string format
        if (angular.isString(dob) && dob.split('-').length === 3) {
            let splitDate = dob.split('-');

            tempDate = {
                day: +splitDate[2],
                month: +splitDate[1],
                year: +splitDate[0]
            };
        }

        return tempDate;
    }

    /**
     * @name selectDate
     * @function selectDate
     * @memberOf app_controllers.DatePickerController
     * @description
     *  **Description**<br>
     *    The __selectDate__ method sets date as selected and after that, close date picker
     *    and updates passenger ( DOB and type ).
     */
    selectDate(day) {
        this.chosen.day = day;

        this.close();
        this.updatePassengerDob();
        this.updatePassengerType();
    }

    /**
     * @name selectedDayClass
     * @function selectedDayClass
     * @memberOf app_controllers.DatePickerController
     * @description
     *  **Description**<br>
     *    The __selectedDayClass__ method sets/unsets css class to highlight selected day in calender
     */
    selectedDayClass() {

        if (this.passengerMonth === this.chosen.month && this.passengerYear === this.chosen.year){
            this.dayClass = 'chosen-' + this.chosen.day;
        } else {
            this.dayClass = '';
        }
    }

    /**
     * @name toggleVisibility
     * @function toggleVisibility
     * @memberOf app_controllers.DatePickerController
     * @description
     *  **Description**<br>
     *    The __toggleVisibility__ method is a visibility switch for date picker.
     */
    toggleVisibility() {
        if (!this.appData.isHardcopy) {
            this.isVisible = !this.isVisible;

            if (this.isVisible) {

                this.chosen = this.prepareDateOfBirth(angular.copy(this.passengerDob));

                if (this.chosen.month && this.chosen.year) {

                    this.passengerMonth = this.chosen.month;
                    this.passengerYear = this.chosen.year;

                }

                this.updateShownDate();
            }
        }
    }

    /**
     * @name close
     * @function close
     * @memberOf app_controllers.DatePickerController
     * @description
     *  **Description**<br>
     *    The __close__ method close date picker.
     */
    close() {
        this.isVisible = false;
    }
}

// Injections of necessary dependencies
DatePickerController.$inject = [
    'NormalizeDataService',
    'CommonMethodsService',
    'app.dateSettings'
];

// Default export
export default DatePickerController;