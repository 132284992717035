// Import dependencies
import controller from './conference-call.controller';
import template from './conference-call.template.html';

/**
 * @name ConferenceCallComponent
 * @class ConferenceCallComponent
 * @memberOf app_components
 * @description
 *  **Description**<br>
 *    The __ConferenceCall__ component contains logic for conference call block. That include itself ( controller and template ).
 *    For more details see:
 *    * {@link app_controllers.ConferenceCallController|ConferenceCallController}
 *    * {@link https://gitlab.dyninno.net/oaf/standalone/blob/master/web/public/src/components/verification/conference-call/conference-call.template.html|'conference-call' template}
 */
const ConferenceCallComponent = {
    controller,
    controllerAs: 'ConferenceCallCtrl',
    template,
    bindings: {
        appData: '<',
        proceed: '&'
    }
};

// Default export
export default ConferenceCallComponent;