// Import dependencies
import angular from 'angular';

/**
 * @name BaggageService
 * @class BaggageService
 * @memberOf app_services
 * @description
 *  **Description**<br>
 *    The __BaggageService__ contains logic to prepare data for baggage block.<br><br>
 *  **List of methods**
 *  * [fetchData()]{@link app_services.BaggageService.fetchData}
 *  * [updateData()]{@link app_services.BaggageService.updateData}
 *  * [createData()]{@link app_services.BaggageService.createData}
 *  * [getTrip()]{@link app_services.BaggageService.getTrip}
 *  * [getCarryOn()]{@link app_services.BaggageService.getCarryOn}
 *  * [getDetails()]{@link app_services.BaggageService.getDetails}
 *  * [getBaggageTitle()]{@link app_services.BaggageService.getBaggageTitle}
 *  * [getBaggageText()]{@link app_services.BaggageService.getBaggageText}
 */
class BaggageService {

    /**
     * @constructs
     * @memberOf app_services.BaggageService
     * @description
     *  **Description**<br>
     *    This method contains all initializations for that class
     * @property {Object} baggage - Contains baggage data ( not prepared )
     * @property {Object} preparedBaggage - Contains prepared baggage data
     * @dependency {library} $translate {@link https://github.com/angular-translate/angular-translate|link}
     * @dependency {service} NormalizeDataService {@link app_services.NormalizeDataService|link}
     * @dependency {service} CommonMethodsService {@link app_services.CommonMethodsService|link}
     * @dependency {service} SharedDataService {@link app_services.SharedDataService|link}
     */
    constructor(
        $translate,
        NormalizeDataService,
        CommonMethodsService,
        SharedDataService
    ) {

        // Extra dependencies
        this.$translate = $translate;
        this.NormalizeDataService = NormalizeDataService;
        this.CommonMethodsService = CommonMethodsService;
        this.SharedDataService = SharedDataService;

        // Properties
        this.baggageData = null;
    }

    /**
     * @name fetchData
     * @function fetchData
     * @memberOf app_services.BaggageService
     * @description
     *  **Description**<br>
     *    The __fetchData__ method returns prepared baggage data.
     *    If data already were created method returns data otherwise create data, assigns it to
     *    'rulesData' variable and returns created data.
     * @returns {Object} Prepared baggage data
     */
    fetchData(serverData) {
        let data;

        if (this.baggageData) {
            data = this.baggageData;
        }
        else if(serverData) {
            data = this.baggageData = this.createData(serverData);
        }
        else {
            data = null;
        }

        return data;
    }

    /**
     * @name updateData
     * @function updateData
     * @memberOf app_services.BaggageService
     * @description
     *  **Description**<br>
     *    The __updateData__ method returns updated baggage data.
     * @returns {Object} Updated rules data
     */
    updateData(serverData) {
        return this.createData(serverData);
    }

    /**
     * @name createData
     * @function createData
     * @memberOf app_services.BaggageService
     * @description
     *  **Description**<br>
     *    The __createData__ method execute all necessary actions to prepare baggage data.
     * @param {Object} serverData - source data
     * @returns {Object} prepared data for baggage block
     */
    createData(serverData) {
        let data;

        this.baggage = serverData.baggage;

        if (this.baggage) {
            data = {
                trip: this.getTrip(),
                carryOn: this.getCarryOn(),
                details: this.getDetails(),
                dump: this.baggage.dump
            };
        }
        else {
            data = null;
        }

        return data;
    }

    /**
     * @name getTrip
     * @function getTrip
     * @memberOf app_services.BaggageService
     * @description
     *  **Description**<br>
     *    The __getTrip__ method prepares data of trip for the data of baggage.
     * @returns {Object} Trip data
     */
    getTrip() {
        let trip = this.baggage.trip;

        if (
            this.NormalizeDataService.typeOf(trip) === 'object' &&
            this.NormalizeDataService.typeOf(trip.departure) === 'object' &&
            this.NormalizeDataService.typeOf(trip.destination) === 'object'
        ) {
            trip.departureTitle = this.getBaggageTitle(trip.departure);
            trip.destinationTitle = this.getBaggageTitle(trip.destination);
        }

        return trip;
    }

    /**
     * @name getCarryOn
     * @function getCarryOn
     * @memberOf app_services.BaggageService
     * @description
     *  **Description**<br>
     *    The __getCarryOn__ method prepares data of carry-on for the data of baggage.
     * @returns {Object} Carry-on data
     */
    getCarryOn() {
        let carryOn = this.baggage.carryon;

        if (carryOn && carryOn.is_data_available) {
            carryOn.weightText = this.getBaggageText(carryOn, 'weight');
            carryOn.sizeText = this.getBaggageText(carryOn, 'size');
        }

        return carryOn;
    }

    /**
     * @name getDetails
     * @function getDetails
     * @memberOf app_services.BaggageService
     * @description
     *  **Description**<br>
     *    The __getDetails__ method prepares data of details of baggage.
     * @returns {Object} Baggage details data
     */
    getDetails() {
        let details = this.baggage.details;

        if (details && this.NormalizeDataService.typeOf(details) === 'object') {

            if (typeof details[1] != 'undefined') {
                details[1].weightText = this.getBaggageText(details[1], "weight");
                details[1].sizeText = this.getBaggageText(details[1], "size");
                details[1].feeText = this.getBaggageText(details[1], "fee");
            }
            if (typeof details[2] != 'undefined') {
                details[2].feeText = this.getBaggageText(details[2], "fee");
            }
        }

        return details;
    }

    /**
     * @name getBaggageTitle
     * @function getBaggageTitle
     * @memberOf app_services.BaggageService
     * @description
     *  **Description**<br>
     *    The __getBaggageTitle__ method prepares the title for baggage.
     * @returns {String} Baggage title
     */
    getBaggageTitle(direction) {
        let title = '';
        let language = this.$translate.use();

        if (
            direction.city &&
            direction.city.title &&
            direction.country &&
            direction.country.title &&
            direction.airport && direction.airport.code
        ) {
            title = `${direction.city.title[language]}, ${direction.country.title[language]} (${direction.airport.code})`;
        }

        return title;
    }

    /**
     * @name getBaggageText
     * @function getBaggageText
     * @memberOf app_services.BaggageService
     * @description
     *  **Description**<br>
     *    The __getBaggageText__ method prepares the text for baggage.
     * @param {Object} data - target data ( carry-on, details etc. )
     * @param {String} type - the type of preparation ( weight, size, fee etc. )
     * @returns {String} Text that dependent of transmitted params
     */
    getBaggageText(data, type) {
        let text;

        if (type === 'fee') {
            if (data.no_fee_flag) {
                text = 'FREE';
            }

            else if (data.fee_amount && data.fee_currency && data.fee_amount > 0) {
                text = `${data.fee_amount} ${data.fee_currency}`;
            }

            else {
                text = 'Data not available!';
            }
        }

        else {
            if ((data.weight_in_lb && data.weight_in_kg) && type === 'weight') {
                text = `up to ${data.weight_in_lb}LB / ${data.weight_in_kg}KG`;
            }

            else if ((data.size_in_inches && data.size_in_cm) && type === "size") {
                text = `up to ${data.size_in_inches}I / ${data.size_in_cm}CM`;
            }

            else {
                text = 'Please verify with your agent';
            }
        }

        return text;
    }
}

// Injections of necessary dependencies
BaggageService.$inject = [
    '$translate',
    'NormalizeDataService',
    'CommonMethodsService',
    'SharedDataService'
];

// Default export
export default BaggageService;