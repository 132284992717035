/**
 * @name ProtectionService
 * @class ProtectionService
 * @memberOf app_services
 * @description
 *  **Description**<br>
 *    The __ProtectionService__ contains logic to prepare data for protection block.<br><br>
 *  **List of methods**
 *  * [createData()]{@link app_services.ProtectionService.createData}
 *  * [isTpSelected()]{@link app_services.ProtectionService.isTpSelected}
 *  * [isShown()]{@link app_services.ProtectionService.isShown}
 *  * [getTpPrice()]{@link app_services.ProtectionService.getTpPrice}
 *  * [getPrice()]{@link app_services.ProtectionService.getPrice}
 */
class ProtectionService {

    /**
     * @constructs
     * @memberOf app_services.ProtectionService
     * @description
     *  **Description**<br>
     *    This method contains all initializations for that class
     * @dependency {service} NormalizeDataService {@link app_services.NormalizeDataService|link}
     * @dependency {service} CommonMethodsService {@link app_services.CommonMethodsService|link}
     * @dependency {service} SharedDataService {@link app_services.SharedDataService|link}
     */
    constructor(
        NormalizeDataService,
        CommonMethodsService,
        SharedDataService
    ) {

        // Extra dependencies
        this.NormalizeDataService = NormalizeDataService;
        this.CommonMethodsService = CommonMethodsService;
        this.SharedDataService = SharedDataService;

        // Properties
        this.submittedPayment = null;
    }


    /**
     * @name createData
     * @function createData
     * @memberOf app_services.ProtectionService
     * @description
     *  **Description**<br>
     *    The __createData__ method execute all necessary actions to prepare protection data.
     * @param {Object} serverData - source data
     * @returns {Object} prepared data for protection block
     */
    createData(serverData) {
        let data;

        this.payment = serverData.payment;
        this.submittedPayment = serverData.submittedData ? serverData.submittedData.payment : null;

        if (this.payment) {
            data = {
                isSelected: this.isTpSelected(),
                isTpVisible: this.isShown('TP'),
                isTcVisible: this.isShown('TC'),
                tpPrice: this.getTpPrice()
            };

            let price;
            let currencyTitle = this.SharedDataService.currency.title;

            price = this.getPrice('compensation');
            data.compensationPrice = this.CommonMethodsService.preparePrice(currencyTitle, price, 'space');

            price = this.getPrice('baggageMishandle');
            data.baggageMishandlePrice = this.CommonMethodsService.preparePrice(currencyTitle, price, 'space');

            price = this.getPrice('voucher');
            data.voucherPrice = this.CommonMethodsService.preparePrice(currencyTitle, price, 'space');
        }
        else {
            data = null;
        }

        return data;
    }

    /**
     * @name isTpSelected
     * @function isTpSelected
     * @memberOf app_services.ProtectionService
     * @description
     *  **Description**<br>
     *    The __isTpSelected__ method defines if TP are selected and sets corresponding flag.
     * @returns {String} TP are selected or not ( '0' || '1' )
     */
    isTpSelected() {
        let isSelected;

        if (this.SharedDataService.isHardcopy) {
            isSelected = this.submittedPayment.ticket_protection_selected;
        }

        else if (this.payment.ticket_protection.tp_requested) {
            isSelected = '1';
        }

        return isSelected;
    }

    /**
     * @name isShown
     * @function isShown
     * @memberOf app_services.ProtectionService
     * @description
     *  **Description**<br>
     *    The __isShown__ method defines if block will visible.
     * @param {String} target - The target of check ( Ticket Protection || Terms & Conditions )
     * @returns {Boolean} Target section will displayed or not
     */
    isShown(target) {
        let isShown = false;

        let isTpDisplay = this.CommonMethodsService.getData('ticket_protection.tp_display', this.payment);
        let isCorresponds = this.CommonMethodsService.isCorrespondsToTypeOfSale(
            this.SharedDataService.saleType,
            [
                'SALE',
                'EXCHANGE'
            ]
        );

        isShown = isCorresponds && isTpDisplay;

        if(target === 'TC') {
            isShown = this.SharedDataService.isHardcopy && isShown;
        }

        return isShown;
    }

    /**
     * @name getTpPrice
     * @function getTpPrice
     * @memberOf app_services.ProtectionService
     * @description
     *  **Description**<br>
     *    The __getTpPrice__ method prepares TP price and define if it will displayed.
     * @returns {Object} TP price.
     */
    getTpPrice() {
        let tpAmount = this.CommonMethodsService.getData('ticket_protection.tp_amount', this.payment);

        let tpPrice = {
            isVisible: !!tpAmount
        };

        if (tpPrice.isVisible) {
            let currency = this.SharedDataService.currency.signOrCode;

            if (this.NormalizeDataService.typeOf(tpAmount) === 'number') {
                let tpString = tpAmount.toString();
                let integer = tpString.substring(0, tpString.length - 2);
                let float = tpString.substring(tpString.length - 2);

                tpPrice.value = `${currency + integer}.<sup>${float}</sup>`;
            }
        }

        return tpPrice;
    }

    /**
     * @name getPrice
     * @function getPrice
     * @memberOf app_services.ProtectionService
     * @description
     *  **Description**<br>
     *    The __getPrice__ method defines correct price value that dependent on the currency of sale.
     * @param {String} target -
     * @returns {Number} Correct price value
     */
    getPrice(target) {
        let priceSet;

        switch (this.SharedDataService.currency.title) {

            case 'GBP':
                priceSet = {
                    compensation: 250,
                    baggageMishandle: 400,
                    voucher: 15
                };
                break;

            case 'CAD':
                priceSet = {
                    compensation: 350,
                    baggageMishandle: 500,
                    voucher: 25
                };
                break;

            default:
                priceSet = {
                    compensation: 300,
                    baggageMishandle: 500,
                    voucher: 25
                };
                break;
        }

        return priceSet[target];
    }
}

// Injections of necessary dependencies
ProtectionService.$inject = [
    'NormalizeDataService',
    'CommonMethodsService',
    'SharedDataService'
];

// Default export
export default ProtectionService;
