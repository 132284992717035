/**
 * @name ResendController
 * @class ResendController
 * @memberOf app_controllers
 * @description
 *  **Description**<br>
 *    The __ResendController__ is controller for the resend block.<br><br>
 *  **List of methods**
 *  * [unsetError()]{@link app_controllers.ResendController.unsetError}
 *  * [resendConfirmation()]{@link app_controllers.ResendController.resendConfirmation}
 */
class ResendController {

    /**
     * @constructs
     * @memberOf app_controllers.ResendController
     * @description
     *  **Description**<br>
     *    This method contains all initializations for that class
     * @dependency {service} SaleApi {@link app_services.SaleApi|link}
     * @dependency {service} CommonMethodsService {@link app_services.CommonMethodsService|link}
     */
    constructor(
        SaleApi,
        CommonMethodsService,
        UrlService
    ) {
        // Extra dependencies
        this.SaleApi = SaleApi;
        this.CommonMethodsService = CommonMethodsService;
        this.UrlService = UrlService;

        this.formData = {};

        this.isResendAllowed = true;
        this.isResendError = false;
        this.isButtonDisabled = false;

    }

    /**
     * @name unsetError
     * @function unsetError
     * @memberOf app_controllers.ResendController
     * @description
     *  **Description**<br>
     *    The __unsetError__ method unset form error
     */
    unsetError() {
        this.isButtonDisabled = this.isResendError = false;
    }

    /**
     * @name resendConfirmation
     * @function resendConfirmation
     * @memberOf app_controllers.ResendController
     * @description
     *  **Description**<br>
     *    The __resendConfirmation__ method handles resend email form submission
     */
    resendConfirmation() {
        if (this.resendConfirmationForm.$invalid || this.resendConfirmationForm.$invalidExplicit) {
            this.CommonMethodsService.setErrors(this.resendConfirmationForm);
        }
        else {
            this.isButtonDisabled = true;

            if (this.appData.confirmationEmail === this.formData.email && !this.isResendAllowed) {
                this.isResendError = true;
            }
            else {

                // If no errors are present send resendConfirmation form data to BE
                this.SaleApi.sale.postResendEmailConfirmation({
                        email: this.formData.email
                    },
                    response => {
                        this.isButtonDisabled = this.isResendAllowed = false;

                        if (response.result && response.result.email) {
                            if (this.appData.confirmationEmail !== response.result.email) {
                                this.isResendAllowed = true;
                                this.appData.confirmationEmail = response.result.email;
                            }
                        }
                    },
                    rejection => {
                        if (rejection.data && rejection.data.result && rejection.data.result.email === 'not_valid') {
                            this.resendConfirmationForm.email.$dirty = true;
                            this.resendConfirmationForm.email.$setValidity('not_valid', false);
                        }

                        this.isButtonDisabled = false;
                    }
                );
            }
        }
    }

}

// Injections of necessary dependencies
ResendController.$inject = [
    'SaleApi',
    'CommonMethodsService',
    'UrlService'
];

// Default export
export default ResendController;