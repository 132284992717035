/**
 * @name TestChargeController
 * @class TestChargeController
 * @memberOf app_controllers
 * @description
 *  **Description**<br>
 *    The __TestChargeController__ is controller for the test charge block.<br><br>
 *  **List of methods**
 *  * [$onInit()]{@link app_controllers.TestChargeController.$onInit}
 *  * [submitTestCharge()]{@link app_controllers.TestChargeController.submitTestCharge}
 */
class TestChargeController {

    /**
     * @constructs
     * @memberOf app_controllers.TestChargeController
     * @description
     *  **Description**<br>
     *    This method contains all initializations for that class
     * @dependency {library} $translate {@link https://github.com/angular-translate/angular-translate|link}
     * @dependency {service} SaleApi {@link app_services.SaleApi|link}
     * @dependency {service} CommonMethodsService {@link app_services.CommonMethodsService|link}
     * @dependency {service} AppService {@link app_services.AppService|link}
     */
    constructor(
        $translate,
        SaleApi,
        CommonMethodsService,
        AppService,
        verificationType
    ) {

        // Extra dependencies
        this.$translate = $translate;
        this.SaleApi = SaleApi;
        this.CommonMethodsService = CommonMethodsService;
        this.AppService = AppService;
        this.verificationType = verificationType;

        this.formData = null;

        this.isVerificationLoading = false;
    }

    /**
     * @name $onInit
     * @function $onInit
     * @memberOf app_controllers.TestChargeController
     * @description
     *  **Description**<br>
     *    The __$onInit__ lifecycle hook is for all initialisation of code for a Controller.
     */
    $onInit() {
        this.formData = {
            amount: null
        };

        if (this.appData.isHardcopy) {
            this.formData = {
                amount: this.appData.amount
            };
        }
    }

    /**
     * @name submitTestCharge
     * @function submitTestCharge
     * @memberOf app_controllers.TestChargeController
     * @description
     *  **Description**<br>
     *    The __submitTestCharge__ method is responsible for submission of test charge verification form
     */
    submitTestCharge() {
        this.isVerificationLoading = true;

        if (this.testChargeForm.$invalid || this.testChargeForm.$invalidExplicit) {
            this.CommonMethodsService.setErrors(this.testChargeForm);
            this.isVerificationLoading = false;
        }
        else {

            let dataToSend = {
                amount: this.formData.amount,
                language: this.$translate.use()
            };

            this.SaleApi.sale.postVerificationCharge({},
                dataToSend,
                response => {
                    this.isVerificationLoading = false;

                    if (response && response.result && response.result.type && !response.result.status) {

                        this.testChargeForm.amount.$setValidity('not_valid', false);

                        // if verification type will change from TC to CC, specific data must be prepared
                        if (response.result.type === this.verificationType.CONFERENCE_CALL) {
                            this.AppService.serverData.verification_type = response.result.type;

                            this.app.prepareData('sale_submitted_without_verification', {
                                state: this.appData.appState,
                                result: this.AppService.serverData
                            });
                        }
                    }
                    else {
                        this.proceed();
                    }
                },
                rejection => {
                    this.isVerificationLoading = false;

                    if (rejection && rejection.data && rejection.data.state === 'validation') {
                        this.testChargeForm.amount.$dirty = true;
                        this.testChargeForm.amount.$setValidity('not_valid', false);
                    }
                    else {
                        this.proceed();
                    }
                }
            );
        }
    }

}

// Injections of necessary dependencies
TestChargeController.$inject = [
    '$translate',
    'SaleApi',
    'CommonMethodsService',
    'AppService',
    'app.verificationType'
];

// Default export
export default TestChargeController;