/**
 * @name VerificationController
 * @class VerificationController
 * @memberOf app_controllers
 * @description
 *  **Description**<br>
 *    The __VerificationController__ is controller for the verification state page.<br><br>
 *  **List of methods**
 *  * [proceed()]{@link app_controllers.VerificationController.proceed}
 */
class VerificationController {

    /**
     * @constructs
     * @memberOf app_controllers.VerificationController
     * @description
     *  **Description**<br>
     *    This method contains all initializations for that class
     * @dependency {library} $translate {@link https://github.com/angular-translate/angular-translate|link}
     * @dependency {service} AppService {@link app_services.AppService|link}
     */
    constructor(
        $translate,
        AppService
    ) {

        // Extra dependencies
        this.$translate = $translate;
        this.AppService = AppService;
    }

    /**
     * @name proceed
     * @function proceed
     * @memberOf app_services.VerificationController
     * @description
     *  **Description**<br>
     *    The __proceed__ method is responsible for changing app state upon verification submit.
     *    That is shared method for ( test-charge & conference-call ) components.
     */
    proceed() {
        if (this.appData.isTestimonialsSubmitted) {
            this.app.prepareData('sale_is_filled_and_submitted', {
                state: this.appData.appState,
                result: {
                    language: this.$translate.use(),
                    client_email: this.appData.confirmation_email,
                    agent_phone: this.appData.employee.phone || '',
                    cms_company: this.appData.cmsCompany
                }
            });
        }
        else {
            this.AppService.serverData.language = this.$translate.use();

            this.app.prepareData('sale_submitted_without_testimonials', {
                state: this.appData.appState,
                result: this.AppService.serverData
            });
        }
    }

}

// Injections of necessary dependencies
VerificationController.$inject = [
    '$translate',
    'AppService'
];

// Default export
export default VerificationController;