/**
 * @name FormSubmitController
 * @class FormSubmitController
 * @memberOf app_controllers
 * @description
 *  **Description**<br>
 *    The __FormSubmitController__ is controller which control the form submit block state.<br><br>
 *  **List of methods**
 *  * [$onInit()]{@link app_controllers.FormSubmitController.$onInit}
 *  * [isStop()]{@link app_controllers.FormSubmitController.isStop}
 *  * [getPrivacyPolicyLink()]{@link app_controllers.FormSubmitController.getPrivacyPolicyLink}
 */
class FormSubmitController {

    /**
     * @constructs
     * @memberOf app_controllers.FormSubmitController
     * @description
     *  **Description**<br>
     *    This method contains all initializations for that class
     * @property {Object} submit - Contains the data about submit
     * @dependency {service} $rootScope {@link https://docs.angularjs.org/api/ng/service/$rootScope|link}
     * @dependency {service} SharedDataService {@link app_services.SharedDataService|link}
     * @dependency {service} CommonMethodsService {@link app_services.CommonMethodsService|link}
     */
    constructor(
        $rootScope,
        SharedDataService,
        CommonMethodsService
    ) {

        // Extra dependencies
        this.$rootScope = $rootScope;
        this.SharedDataService = SharedDataService;
        this.CommonMethodsService = CommonMethodsService;

        // Properties
        this.submit = {};
    }

    /**
     * @name $onInit
     * @function $onInit
     * @memberOf app_controllers.FormSubmitController
     * @description
     *  **Description**<br>
     *    The __$onInit__ lifecycle hook is for all initialisation of code for a Controller.
     */
    $onInit() {
        this.agreement
            = this.stopInEurope
            = this.stopInCanada
            = this.stopInUsa
            = this.stopInNigeria
            = this.appData.isHardcopy;

        this.isStopInEurope = this.isStop('europe');
        this.isStopInCanada = this.isStop('canada');
        this.isStopInUsa = this.isStop('usa');
        this.isStopInNigeria = this.isStop('nigeria');
        this.privacyPolicyLink = this.getPrivacyPolicyLink();
    }

    /**
     * @name isStop
     * @function isStop
     * @memberOf app_services.FormSubmitController
     * @description
     *  **Description**<br>
     *    The __isStop__ method defines the possibility of stop in some destination.
     *    Possible destinations: 'usa', 'canada', 'europe', 'nigeria'
     * @param {String} destination - destination name.
     * @returns {Boolean} 'true' if stop available
     */
    isStop(destination) {
        let isStop = false;

        let isNotUk = this.appData.cmsCompany !== 'UK';
        let flight = this.appData.flight;

        let isCorresponds = this.CommonMethodsService.isCorrespondsToTypeOfSale(
            this.appData.saleType,
            [
                'SALE',
                'EXCHANGE'
            ]
        );

        if (flight) {
            switch (destination){

                case 'usa':
                    isStop = flight.is_stop_in_usa && isNotUk && isCorresponds;
                    break;

                case 'canada':
                    isStop = flight.is_stop_in_canada && isNotUk && isCorresponds;
                    break;

                case 'europe':
                    isStop = flight.are_stops_in_europe && isCorresponds;
                    break;

                case 'nigeria':
                    isStop = flight.is_nigeria_one_way && isCorresponds;
                    break;

                default:
                    break;
            }
        }

        return isStop;
    }

    /**
     * @name getPrivacyPolicyLink
     * @function getPrivacyPolicyLink
     * @memberOf app_services.FormSubmitController
     * @description
     *  **Description**<br>
     *    The __getPrivacyPolicyLink__ method defines the link of privacy policy
     * @returns {Boolean} 'true' if stop available
     */
    getPrivacyPolicyLink() {
        let link;

        switch(this.appData.cmsCompany) {

            case 'UK':
                link = 'https://www.asaptickets.co.uk/privacy';
                break;

            case 'CA':
                link = 'https://www.asaptickets.ca/privacy';
                break;

            case 'BOG': // ITN, PH, ME, BOG
                link = 'https://www.asaptickets.com/us-es/privacy';
                break;

            default:
                link = 'https://www.asaptickets.com/privacy';
                break;
        }

        return link;
    }

}

// Injections of necessary dependencies
FormSubmitController.$inject = [
    '$rootScope',
    'SharedDataService',
    'CommonMethodsService'
];

// Default export
export default FormSubmitController;