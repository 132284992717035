(function() {
    'use strict';

    // angularjs implementation of intl-tel-input, source: https://github.com/jackocnr/intl-tel-input
    angular.module('app.directives').directive('phoneInputVerification', [
        '$timeout',
        'SharedDataService',

        function(
            $timeout,
            SharedDataService
        ) {

        return {
            restrict: 'A',
            scope: 'true',
            require: 'ngModel',
            link: function(scope, element, attr, ctrl) {

                if (!SharedDataService.isHardcopy) {

                    let defaultPreferredCountries = ['ar', 'ca', 'co', 'ec', 'pe', 'ph', 'us'];
                    let selectedCountry = 'us';

                    switch (SharedDataService.cmsCompany) {
                        case 'UK':
                            defaultPreferredCountries = ['ar', 'ca', 'co', 'ec', 'pe', 'ph', 'gb', 'us'];
                            selectedCountry = 'gb';
                            break;
                        case 'CA':
                            selectedCountry = 'ca';
                            break;
                    }

                    let options = {
                        utilsScript :require('intl-tel-input/build/js/utils.js'),
                        preferredCountries: defaultPreferredCountries,
                        initialCountry: selectedCountry,
                        nationalMode: true,
                        autoPlaceholder: 'off'
                    };

                    let telElement = window.intltelinput(element[0], options);

                    element.on('countrychange focus', function() {
                        if (ctrl.$viewValue) {
                            $timeout(function(){
                                ctrl.$setViewValue(telElement.getNumber());
                            });
                        }
                    });

                    ctrl.$validators.intlTelValidity = function (value) {
                        // if phone number is deleted / empty do not run phone number validation
                        if (attr.required && (value || element[0].value.length > 0)) {
                            return telElement.isValidNumber();
                        } else {
                            return true;
                        }
                    };

                    ctrl.$parsers.push(function () {
                        let num = telElement.getNumber();
                        let country = telElement.getSelectedCountryData().iso2;
                        let formatedNum = intlTelInputUtils.formatNumber(num, country, 1);
                        let forbiddenChars = new RegExp(/[^0-9\+\-\(\)\s]/);
                        formatedNum = formatedNum.replace(forbiddenChars, '');

                        ctrl.$setViewValue(formatedNum);
                        ctrl.$render();
                        return formatedNum;
                    });

                    // Set input value to model value and trigger evaluation.
                    ctrl.$formatters.push(function (value) {
                        if (value) {
                            if(value.charAt(0) !== '+') {
                                value = '+' + value;
                            }
                            telElement.setNumber(value);
                        }
                        let country = telElement.getSelectedCountryData().iso2;
                        return intlTelInputUtils.formatNumber(value, country, 1);
                    });

                    scope.$on('$destroy', function() {
                        telElement.destroy();
                    });
                }
            }
        };
    }]);
})();
