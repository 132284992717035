// Import dependencies
import controller from './payment.controller';
import template from './payment.template.html';

/**
 * @name PaymentComponent
 * @class PaymentComponent
 * @memberOf app_components
 * @description
 *  **Description**<br>
 *    The __payment__ component contains all needed to display 'payment' block. That include itself ( controller and template ).
 *    For more details see:
 *    * {@link app_controllers.PaymentController|PaymentController}
 *    * {@link https://gitlab.dyninno.net/oaf/standalone/blob/master/web/public/src/components/payment/payment.template.html|'payment' template}
 */
const PaymentComponent = {
    controller,
    controllerAs: 'PaymentCtrl',
    template,
    bindings: {
        appData: '<',
        itnForm: '<',
        onSubmit: '&',
        validateForm: '&',
        checkForm: '&'
    }
};

// Default export
export default PaymentComponent;