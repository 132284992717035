// Import dependencies
import controller from './passengers.controller';
import template from './passengers.template.html';

/**
 * @name PassengersComponent
 * @class PassengersComponent
 * @memberOf app_components
 * @description
 *  **Description**<br>
 *    The __passengers__ component contains all needed to display 'passengers' block. That include itself ( controller and template ).
 *    For more details see:
 *    * {@link app_controllers.PassengersController|PassengersController}
 *    * {@link https://gitlab.dyninno.net/oaf/standalone/blob/master/web/public/src/components/passengers/passengers.template.html|'passengers' template}
 */
const PassengersComponent = {
    controller,
    controllerAs: 'PassengersCtrl',
    template,
    bindings: {
        appData: '<',
        itnForm: '<',
        saleFormModel: '='
    }
};

// Default export
export default PassengersComponent;