// Import dependencies
import angular from 'angular';
import FooterComponent from './footer.component';

/**
 * @name app.footer
 * @class FooterModule
 * @memberOf app_modules
 * @description
 *  **Description**<br>
 *    The __app.footer__ module is module that is responsible to make all preparation of data for footer block
 *    and display him in application. This module consist from 'FooterComponent'.
 *    What it makes, see in relevant section.
 *    * {@link app_components.FooterComponent|FooterComponent}
 */
const FooterModule = angular.module('app.footer', [])
    .component('footer', FooterComponent)
    .name;

// Default export
export default FooterModule;