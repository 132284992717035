// Import dependencies
import angular from 'angular';

/**
 * @name FlightService
 * @class FlightService
 * @memberOf app_services
 * @description
 *  **Description**<br>
 *    The __FlightService__ contains logic to prepare data for flight block.<br><br>
 *  **List of methods**
 *  * [fetchData()]{@link app_services.FlightService.fetchData}
 *  * [updateData()]{@link app_services.FlightService.updateData}
 *  * [createData()]{@link app_services.FlightService.createData}
 *  * [prepareItineraries()]{@link app_services.FlightService.prepareItineraries}
 *  * [prepareSegments()]{@link app_services.FlightService.prepareSegments}
 *  * [getLocation()]{@link app_services.FlightService.getLocation}
 *  * [getFlightDetails()]{@link app_services.FlightService.getFlightDetails}
 *  * [flightTypeText()]{@link app_services.FlightService.flightTypeText}
 *  * [cabinClassText()]{@link app_services.FlightService.cabinClassText}
 *  * [travelTypeText()]{@link app_services.FlightService.travelTypeText}
 *  * [layoverText()]{@link app_services.FlightService.layoverText}
 *  * [arrivalText()]{@link app_services.FlightService.arrivalText}
 *  * [isLandTransport()]{@link app_services.FlightService.isLandTransport}
 *  * [prepareAirportText()]{@link app_services.FlightService.prepareAirportText}
 *  * [prepareAffirmFlight()]{@link app_services.FlightService.prepareAffirmFlight}
 *  * [prepareStopText()]{@link app_services.FlightService.prepareStopText}
 */
class FlightService {

    /**
     * @constructs
     * @memberOf app_services.FlightService
     * @description
     *  **Description**<br>
     *    This method contains all initializations for that class
     * @dependency {library} $translate {@link https://github.com/angular-translate/angular-translate|link}
     * @dependency {service} NormalizeDataService {@link app_services.NormalizeDataService|link}
     * @dependency {service} CommonMethodsService {@link app_services.CommonMethodsService|link}
     * @dependency {service} SharedDataService {@link app_services.SharedDataService|link}
     */
    constructor(
        $translate,
        NormalizeDataService,
        CommonMethodsService,
        SharedDataService
    ) {

        // Extra dependencies
        this.$translate = $translate;
        this.NormalizeDataService = NormalizeDataService;
        this.CommonMethodsService = CommonMethodsService;
        this.SharedDataService = SharedDataService;

        // Properties
        this.flightData = null;
    }

    /**
     * @name fetchData
     * @function fetchData
     * @memberOf app_services.FlightService
     * @description
     *  **Description**<br>
     *    The __fetchData__ method returns prepared flight data.
     *    If data already were created method returns data otherwise create data, assigns it to
     *    'flightData' variable and returns created data.
     * @returns {Object} Prepared flight data
     */
    fetchData(serverData) {
        let data;

        if (this.flightData) {
            data = this.flightData;
        }
        else if(serverData) {
            data = this.flightData = this.createData(serverData);
        }
        else {
            data = null;
        }

        return data;
    }

    /**
     * @name updateData
     * @function updateData
     * @memberOf app_services.FlightService
     * @description
     *  **Description**<br>
     *    The __updateData__ method returns updated flight data.
     * @returns {Object} Updated flight data
     */
    updateData(serverData) {
        return this.createData(serverData);
    }

    /**
     * @name createData
     * @function createData
     * @memberOf app_services.FlightService
     * @description
     *  **Description**<br>
     *    The __createData__ method execute all necessary actions to prepare flight data.
     * @param {Object} serverData - source data
     * @returns {Object} prepared data for flight block
     */
    createData(serverData) {
        let data;

        this.flight = serverData.trip;

        if (this.flight) {

            // Copy necessary properties. See more NormalizeDataService.copyProperties()
            data = this.NormalizeDataService.copyProperties(this.flight, [
                'are_stops_in_europe',
                'is_nigeria_one_way',
                'is_stop_in_canada',
                'is_stop_in_usa',
                'trip_type'
            ]);

            data.itineraries = this.prepareItineraries();
        }
        else {
            data = null;
        }

        return data;
    }

    /**
     * @name prepareItineraries
     * @function prepareItineraries
     * @memberOf app_services.FlightService
     * @description
     *  **Description**<br>
     *    The __prepareItineraries__ method goes through array of itineraries and prepares data for each itinerary.
     */
    prepareItineraries() {
        let itineraries = [];

        if (this.flight.itineraries && this.flight.itineraries.length) {

            angular.forEach(this.flight.itineraries, (itinerary, key) => {
                itineraries.push({
                    flightType: this.flightTypeText(key),
                    totalTravelTime: itinerary.total_travel_time,
                    segments: this.prepareSegments(itinerary.segments)
                });
            });

        }

        return itineraries;
    }

    /**
     * @name prepareSegments
     * @function prepareSegments
     * @memberOf app_services.FlightService
     * @description
     *  **Description**<br>
     *    The __prepareSegments__ method goes through array of segments and prepares data for each segment.
     * @param {Array} segments - source data
     * @returns {Array} Prepared segments
     */
    prepareSegments(segments) {
        let preparedSegments = [];

        if (segments && segments.length) {

            angular.forEach(segments, (segment, key) => {
                let language = this.$translate.use();
                let flightDetails = segment.flight_details;

                let tempSegment = {
                    departure: this.getLocation(segment, 'departure'),
                    destination: this.getLocation(segment, 'destination'),
                    duration: flightDetails.duration,
                    details: this.getFlightDetails(flightDetails),
                    isServiceStop: flightDetails.duration && flightDetails.is_service_stop,
                    flightDurationStop: this.prepareStopText({
                        from: this.CommonMethodsService.getData('departure.location.airport.code', segment),
                        to: this.CommonMethodsService.getData('destination.location.airport.code', segment),
                        duration: flightDetails.duration
                    })
                };

                // Checks previous segment
                let prevSegment = preparedSegments[key - 1];
                if (tempSegment.isServiceStop && prevSegment) {
                    tempSegment.flightDurationStopPrev = this.prepareStopText({
                        from: prevSegment.departure.airportCode,
                        to: prevSegment.destination.airportCode,
                        duration: prevSegment.duration
                    });
                }

                // Checks the change of airport
                let nextSegment = segments[key + 1];
                if (nextSegment) {
                    let nextSegmentAirportCode = this.CommonMethodsService.getData(
                        'departure.location.airport.code',
                        nextSegment
                    );
                    let currentSegmentAirportCode = this.CommonMethodsService.getData(
                        'destination.location.airport.code',
                        segment
                    );
                    tempSegment.isAirportChange = currentSegmentAirportCode !== nextSegmentAirportCode;
                }
                else {
                    tempSegment.isAirportChange = false;
                }

                // Sets layover
                if (segment.layover) {
                    tempSegment.layover = {
                        city: segment.layover.city.title[language],
                        text: this.layoverText(segment.layover.time_unix),
                        time: segment.layover.time,
                        time_unix: segment.layover.time_unix
                    };
                }

                preparedSegments.push(tempSegment);
            });

        }

        return preparedSegments;
    }

    /**
     * @name getLocation
     * @function getLocation
     * @memberOf app_services.FlightService
     * @description
     *  **Description**<br>
     *    The __getLocation__ method prepares location data.
     */
    getLocation(segment, type) {
        let data = null;
        let language = this.$translate.use();

        let location = this.CommonMethodsService.getData(`${type}.location`, segment);

        if (location) {
            data = {
                airport: this.prepareAirportText(location),
                airportTitle: this.CommonMethodsService.getData('airport.title', location) || '',
                airportCode: this.CommonMethodsService.getData('airport.code', location) || '',
                stateTitle: this.CommonMethodsService.getData('state.title', location) || '',
                countryTitle: this.CommonMethodsService.getData(`country.title.${language}`, location) || '',
                date: this.CommonMethodsService.getData(`${type}.date.${language}`, segment) || '',
                time: this.CommonMethodsService.getData(`${type}.time`, segment) || '',
                dateTime: this.CommonMethodsService.getData(`${type}.datetime`, segment) || ''
            };
        }

        if (type === 'destination') {
            data.arrivalText = this.arrivalText(segment[type].arrival);
        }

        return data;
    }

    /**
     * @name getFlightDetails
     * @function getFlightDetails
     * @memberOf app_services.FlightService
     * @description
     *  **Description**<br>
     *    The __getFlightDetails__ method prepares flight details data.
     */
    getFlightDetails(flightDetails) {
        return {
            flight: flightDetails.flight,
            airlineLogo: flightDetails.airline.logotype,
            isServiceStop: flightDetails.is_service_stop,
            travelType: this.travelTypeText(flightDetails.aircraft),
            operatedBy: flightDetails.operated_by,
            isLandTransport: this.isLandTransport(flightDetails.aircraft),
            cabinClass: this.cabinClassText(flightDetails.cabin_class)
        };
    }

    /**
     * @name flightTypeText
     * @function flightTypeText
     * @memberOf app_services.FlightService
     * @description
     *  **Description**<br>
     *    The __flightTypeText__ method returns prepared text for flight type.
     * @param {Number} index - itinerary index
     * @returns {String} Translated text for flight type
     */
    flightTypeText(index) {
        let text = '';

        if (this.flight.trip_type === 'RT') {
            switch (index) {
                case 0:
                    text = this.$translate.instant('flights.outbound');
                    break;
                case 1:
                    text = this.$translate.instant('flights.inbound');
                    break;
            }
        }
        else {
            text = `${this.$translate.instant('flights.flight')} ${(index + 1)}`;
        }

        return text;
    }

    /**
     * @name cabinClassText
     * @function cabinClassText
     * @memberOf app_services.FlightService
     * @description
     *  **Description**<br>
     *    The __cabinClassText__ method returns prepared text for cabin class.
     * @param {String} cabinClass - cabin class
     * @returns {String} Translated text for cabin class
     */
    cabinClassText(cabinClass) {
        let key = 'flights.economy';

        if (cabinClass === 'premiumEconomy') {
            key = 'flights.premiumEconomy';
        }
        else if (cabinClass === 'business'){
            key = 'flights.business';
        }
        else if (cabinClass === 'first') {
            key = 'flights.first';
        }

        return this.$translate.instant(key);
    }

    /**
     * @name travelTypeText
     * @function travelTypeText
     * @memberOf app_services.FlightService
     * @description
     *  **Description**<br>
     *    The __travelTypeText__ method returns prepared text for transport type.
     * @param {String} aircraft - transport type
     * @returns {String} Translated text for travel type
     */
    travelTypeText(aircraft) {
        let key = 'flights.flight';

        if (aircraft === 'BUS') {
            key = 'flights.bus';
        }
        else if (aircraft === 'TRN') {
            key = 'flights.train';
        }

        return this.$translate.instant(key);
    }

    /**
     * @name layoverText
     * @function layoverText
     * @memberOf app_services.FlightService
     * @description
     *  **Description**<br>
     *    The __layoverText__ method returns prepared text for layover.
     * @param {Number} duration - arrival type
     * @returns {String} Translated text for layover
     */
    layoverText(duration) {
        let key = '';

        if (duration < 3600) {
            key = 'flights.short';
        }
        else if (duration >= 28800 && duration < 86400) {
            key = 'flights.long';
        }

        return this.$translate.instant(key);
    }

    /**
     * @name arrivalText
     * @function arrivalText
     * @memberOf app_services.FlightService
     * @description
     *  **Description**<br>
     *    The __arrivalText__ method returns prepared text for arrival.
     * @param {String} value - arrival type
     * @returns {String} Translated text for arrival
     */
    arrivalText(value) {
        let key = '';

        if (value === '+1') {
            key = 'flights.nextDay';
        }
        else if (value === '+2') {
            key = 'flights.twoDays';
        }
        else if (value === '-1') {
            key = 'flights.previousDay';
        }

        return this.$translate.instant(key);
    }

    /**
     * @name isLandTransport
     * @function isLandTransport
     * @memberOf app_services.FlightService
     * @description
     *  **Description**<br>
     *    The __isLandTransport__ method defines if transport is terrestrial.
     * @param {String} aircraft - transport type
     * @returns {Boolean} Returns 'true' if transport type is terrestrial
     */
    isLandTransport(aircraft) {
        return aircraft === 'BUS' || aircraft === 'TRN';
    }

    /**
     * @name prepareAirportText
     * @function prepareAirportText
     * @memberOf app_services.FlightService
     * @description
     *  **Description**<br>
     *    The __prepareAirportText__ method returns prepared text for airport.
     *  **Return example**<br>
     *    Newark, NJ (EWR)
     * @param {object} location - the source of data about location
     * @returns {String} Prepared text for airport
     */
    prepareAirportText(location) {
        let text = '';
        let language = this.$translate.use();

        if (location) {
            let cityTitle = location.city.title[language];
            let airportCode = location.airport.code;
            let stateCode = location.state.code;

            text = cityTitle;

            if (stateCode) {
                text += `, ${stateCode}`
            }

            text += ` (${airportCode})`;
        }

        return text;
    }

    /**
     * @name prepareAffirmFlight
     * @function prepareAffirmFlight
     * @memberOf app_services.FlightService
     * @description
     *  **Description**<br>
     *    The __prepareAffirmFlight__ method returns prepared text for airport.
     * @param {object} data - app data
     * @returns {Array} Array of segments
     */
    prepareAffirmFlight(data) {
        let segmentList = [];
        let segment = {};

        if (data && data.flight) {

            angular.forEach(data.flight.itineraries, itinerary => {
                angular.forEach(itinerary.segments, segment => {
                    segment = {
                        type: 'flight',
                        display_name: `${segment.departure.airport} - ${segment.destination.airport} (${segment.departure.date})`,

                        //TODO: currently not receiving UTC format dparture datetime, BE changes needed
                        date: segment.departure.dateTime,
                        qty: data.passengers.length,
                        sku: segment.details.flight || '',
                        origin: segment.departure.airportCode,
                        destination: segment.destination.airportCode,
                        fare_type: segment.details.cabinClass,
                    };

                    segmentList.push(segment);
                })
            });


        }

        return segmentList;
    }

    /**
     * @name prepareStopText
     * @function prepareStopText
     * @memberOf app_services.FlightService
     * @description
     *  **Description**<br>
     *    The __prepareStopText__ method returns prepared text for stops.
     *  **Return example**<br>
     *    FNA-ROB: 6h 35m
     * @param {object} data - the source of data about stops
     * @returns {String} Prepared text for stops
     */
    prepareStopText(data) {
        return `${data.from}-${data.to}: ${data.duration}`;
    }
}

// Injections of necessary dependencies
FlightService.$inject = [
    '$translate',
    'NormalizeDataService',
    'CommonMethodsService',
    'SharedDataService'
];

// Default export
export default FlightService;
