(function() {
    "use strict";

    angular.module('app.directives').directive('clearServerErr', function() {
        return {
            require: 'ngModel',
            scope: false,
            link: function(scope, element, attrs, model) {

                // 'not_valid' error is received with server response and should be unset on field change
                model.$validators.not_valid = function (value) {
                    return true;
                };
            }
        };
    });
})();

