// Import dependencies
import angular from 'angular';

/**
 * @name RulesService
 * @class RulesService
 * @memberOf app_services
 * @description
 *  **Description**<br>
 *    The __RulesService__ contains logic to prepare data for rules block.<br><br>
 *  **List of methods**
 *  * [fetchData()]{@link app_services.RulesService.fetchData}
 *  * [updateData()]{@link app_services.RulesService.updateData}
 *  * [createData()]{@link app_services.RulesService.createData}
 *  * [getCancellationRulesBeforeDeparture()]{@link app_services.RulesService.getCancellationRulesBeforeDeparture}
 *  * [getCancellationRulesAfterDeparture()]{@link app_services.RulesService.getCancellationRulesAfterDeparture}
 *  * [getExchangeRulesBeforeDeparture()]{@link app_services.RulesService.getExchangeRulesBeforeDeparture}
 *  * [getExchangeRulesAfterDeparture()]{@link app_services.RulesService.getExchangeRulesAfterDeparture}
 *  * [getTermsInfo()]{@link app_services.RulesService.getTermsInfo}
 */
class RulesService {

    /**
     * @constructs
     * @memberOf app_services.RulesService
     * @description
     *  **Description**<br>
     *    This method contains all initializations for that class
     * @dependency {library} $translate {@link https://github.com/angular-translate/angular-translate|link}
     * @dependency {service} NormalizeDataService {@link app_services.NormalizeDataService|link}
     * @dependency {service} CommonMethodsService {@link app_services.CommonMethodsService|link}
     * @dependency {service} SharedDataService {@link app_services.SharedDataService|link}
     */
    constructor(
        $translate,
        NormalizeDataService,
        CommonMethodsService,
        SharedDataService
    ) {

        // Extra dependencies
        this.$translate = $translate;
        this.NormalizeDataService = NormalizeDataService;
        this.CommonMethodsService = CommonMethodsService;
        this.SharedDataService = SharedDataService;

        // Properties
        this.rulesData = null;
        this.isFareShown = false;
        this.rulesCurrency = '';
    }

    /**
     * @name fetchData
     * @function fetchData
     * @memberOf app_services.RulesService
     * @description
     *  **Description**<br>
     *    The __fetchData__ method returns prepared rules data.
     *    If data already were created method returns data otherwise create data, assigns it to
     *    'rulesData' variable and returns created data.
     * @returns {Object} Prepared rules data
     */
    fetchData(serverData) {
        let data;

        if (this.rulesData) {
            data = this.rulesData;
        }
        else if(serverData) {
            data = this.rulesData = this.createData(serverData);
        }
        else {
            data = null;
        }

        return data;
    }

    /**
     * @name updateData
     * @function updateData
     * @memberOf app_services.RulesService
     * @description
     *  **Description**<br>
     *    The __updateData__ method returns updated rules data.
     * @returns {Object} Updated rules data
     */
    updateData(serverData) {
        return this.createData(serverData);
    }

    /**
     * @name createData
     * @function createData
     * @memberOf app_services.RulesService
     * @description
     *  **Description**<br>
     *    The __createData__ method execute all necessary actions to prepare rules data.
     * @param {Object} serverData - source data
     * @returns {Object} prepared data for rules block
     */
    createData(serverData) {
        let data;

        this.rules = serverData.rules;

        if (this.rules) {
            this.isFareShown = this.rules.show_fare_rules;
            this.rulesCurrency = this.rules.currency + ' ';

            data = {
                cancellations: [
                    this.getCancellationRulesBeforeDeparture(),
                    this.getCancellationRulesAfterDeparture()
                ],
                exchanges: [
                    this.getExchangeRulesBeforeDeparture(),
                    this.getExchangeRulesAfterDeparture()
                ],
                termsInfo: this.getTermsInfo(serverData.legal_entity),
                isCanadaSegment: serverData.trip.is_canada_segment,
                lowFareVoucherPrice: this.lowFareVoucherPrice()
            };

            // If any exchange rule persists, new rule is being added
            if(this.rules.exch_a || this.rules.exch_b) {
                data.exchanges.push(this.$translate.instant('fareRules.exchange.fareDiff'));
            }

            // If rule exch_b provided, check for exchange time limit (exch_d). If provided, generate additional rule
            if (
                this.rules.exch_b &&
                parseFloat(this.rules.exch_b) &&
                this.rules.exch_d &&
                parseInt(this.rules.exch_d)
            ) {

                let plural = parseInt(this.rules.exch_d) > 1 ? 's' : '';
                let limitRule =
                    this.$translate.instant('fareRules.exchange.limitFirst') +
                    this.rules.exch_d + ' ' + this.rules.exch_time + plural +
                    this.$translate.instant('fareRules.exchange.limitSecond');

                data.exchanges.push(limitRule);
            }

        }
        else {
            data = null;
        }

        return data;
    }

    /**
     * @name getCancellationRulesBeforeDeparture
     * @function getCancellationRulesBeforeDeparture
     * @memberOf app_services.RulesService
     * @description
     *  **Description**<br>
     *    The __getCancellationRulesBeforeDeparture__ method defines text for cancellation rules before departure.
     * @returns {String} Cancellation rules before departure
     */
    getCancellationRulesBeforeDeparture() {
        let rule;

        if (this.rules.ref_a) {

            if(this.isFareShown && parseFloat(this.rules.ref_a) > 0) {
                rule =
                    this.$translate.instant('fareRules.cancel.penaltyFirstBefore') +
                    this.rulesCurrency +
                    this.rules.ref_a +
                    this.$translate.instant('fareRules.penaltySecond');
            }
            else {
                rule = this.$translate.instant('fareRules.cancel.before');
            }
        }
        else {
            rule = this.$translate.instant('fareRules.cancel.notBefore');
        }

        return rule;
    }

    /**
     * @name getCancellationRulesAfterDeparture
     * @function getCancellationRulesAfterDeparture
     * @memberOf app_services.RulesService
     * @description
     *  **Description**<br>
     *    The __getCancellationRulesAfterDeparture__ method defines text for cancellation rules after departure.
     * @returns {String} Cancellation rules after departure
     */
    getCancellationRulesAfterDeparture() {
        let rule;

        if (this.rules.ref_b) {

            if(this.isFareShown && parseFloat(this.rules.ref_b) > 0) {
                rule =
                    this.$translate.instant('fareRules.cancel.penaltyFirstAfter') +
                    this.rulesCurrency +
                    this.rules.ref_b +
                    this.$translate.instant('fareRules.penaltySecond');
            }
            else {
                rule = this.$translate.instant('fareRules.cancel.after');
            }
        }
        else {
            rule = this.$translate.instant('fareRules.cancel.notAfter');
        }

        return rule;
    }

    /**
     * @name getExchangeRulesBeforeDeparture
     * @function getExchangeRulesBeforeDeparture
     * @memberOf app_services.RulesService
     * @description
     *  **Description**<br>
     *    The __getExchangeRulesBeforeDeparture__ method defines text for exchange rules before departure.
     * @returns {String} Exchange rules before departure
     */
    getExchangeRulesBeforeDeparture() {
        let rule;

        if (this.rules.exch_a) {

            if(this.isFareShown && parseFloat(this.rules.exch_a) > 0) {
                rule =
                    this.$translate.instant('fareRules.exchange.penaltyFirstBefore') +
                    this.rulesCurrency +
                    this.rules.exch_a +
                    this.$translate.instant('fareRules.penaltySecond');
            }
            else {
                rule = this.$translate.instant('fareRules.exchange.before');
            }
        }
        else {
            rule = this.$translate.instant('fareRules.exchange.notBefore');
        }

        return rule;
    }

    /**
     * @name getExchangeRulesAfterDeparture
     * @function getExchangeRulesAfterDeparture
     * @memberOf app_services.RulesService
     * @description
     *  **Description**<br>
     *    The __getExchangeRulesAfterDeparture__ method defines text for exchange rules after departure.
     * @returns {String} Exchange rules after departure
     */
    getExchangeRulesAfterDeparture() {
        let rule;

        if (this.rules.exch_b) {

            if(this.isFareShown && parseFloat(this.rules.exch_b) > 0){
                rule =
                    this.$translate.instant('fareRules.exchange.penaltyFirstAfter') +
                    this.rulesCurrency +
                    this.rules.exch_b +
                    this.$translate.instant('fareRules.penaltySecond');
            }
            else {
                rule = this.$translate.instant('fareRules.exchange.after');
            }
        }
        else {
            rule = this.$translate.instant('fareRules.exchange.notAfter');
        }

        return rule;
    }

    /**
     * @name getTermsInfo
     * @function getTermsInfo
     * @memberOf app_services.RulesService
     * @description
     *  **Description**<br>
     *    The __getTermsInfo__ method defines information about trems.
     * @returns {Object} Terms information
     */
    getTermsInfo(legalEntity) {
        let termsInfo;

        switch(this.SharedDataService.cmsCompany) {

            case 'UK':
                termsInfo = {
                    shopName: 'International Travel Network',
                    legalName: 'TravelTech Enterprises trading as Asaptickets',
                    addressName: '17 Hanover Square, Office 3.18, London, England, W1S 1BN',
                    addressLink: 'https://goo.gl/maps/tuvW2uPbBewRU6jS9',
                    moreInfoLink: 'https://www.asaptickets.co.uk/customer-service/merchant-location',
                    disputeEmail: 'info@asaptickets.co.uk',
                    disputeLink: {
                        en: 'https://blog.asaptickets.com/wp-content/uploads/2020/09/Arbitration-opt-out-letter-Asaptickets.co_.uk_.pdf',
                        es: 'https://blog.asaptickets.com/wp-content/uploads/2020/09/SPANISH-Arbitration-letter-Asaptickets.co_.uk_.pdf'
                    }
                };
                break;

            case 'CA':
                termsInfo = {
                    shopName: 'Asaptickets',
                    legalName: 'Aviajet, Inc. trading as Asaptickets',
                    addressName: '31 Principale E, bureau 102 Ste Agathe-Des-Monts, Quebec, J8C 1J5, Canada',
                    addressLink: 'https://goo.gl/maps/c4w78TEt5nv3ZBaRA',
                    moreInfoLink: 'https://www.asaptickets.ca/customer-service/merchant-location',
                    disputeEmail: 'info@asaptickets.com',
                    disputeLink: {
                        en: 'https://blog.asaptickets.com/wp-content/uploads/2020/09/Arbitration-opt-out-letter-Asaptickets.ca_.pdf',
                        es: 'https://blog.asaptickets.com/wp-content/uploads/2020/09/SPANISH-Arbitration-letter-Asaptickets.ca_.pdf'
                    }
                };
                break;

            default: // ITN, PH, ME, BOG
                termsInfo = {
                    shopName: 'International Travel Network',
                    legalName: 'International Travel Network, LLC trading as Asaptickets',
                    addressName: '1000 N West Street, Suite 1200, Wilmington, DE 19801',
                    addressLink: 'https://goo.gl/maps/YvUSVLm9XNYt3dBx6',
                    moreInfoLink: 'https://www.asaptickets.com/customer-service/merchant-location',
                    disputeEmail: 'info@asaptickets.com',
                    disputeLink: {
                        en: 'https://blog.asaptickets.com/wp-content/uploads/2020/09/Arbitration-opt-out-letter-Asaptickets.com_.pdf',
                        es: 'https://blog.asaptickets.com/wp-content/uploads/2020/09/Arbitration-opt-out-letter-ES-Asaptickets.com_.pdf'
                    }
                };
                break;
        }

        if (legalEntity) {
            termsInfo.legalName = legalEntity.name;
            termsInfo.addressName = legalEntity.address;
            termsInfo.addressLink = `https://www.google.com/maps/search/?api=1&query=${legalEntity.address}`;
            termsInfo.isLegalEntityAvailable = Boolean(legalEntity);
        }

        return termsInfo;
    }

    lowFareVoucherPrice() {
        let price;

        if (this.SharedDataService.currency.title === 'GBP') {
            price = 40;
        }
        else {
            price = 50;
        }

        return this.SharedDataService.currency.sign + price;
    }
}

// Injections of necessary dependencies
RulesService.$inject = [
    '$translate',
    'NormalizeDataService',
    'CommonMethodsService',
    'SharedDataService'
];

// Default export
export default RulesService;
