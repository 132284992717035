// Import dependencies
import angular from 'angular';
import TestChargeComponent from './test-charge.component';

/**
 * @name app.test-charge
 * @class TestChargeModule
 * @memberOf app_modules
 * @description
 *  **Description**<br>
 *    The __app.test-charge__ module is responsible to make all preparation of data for test-charge block
 *    and display him in application. This module consist from 'TestChargeComponent'.
 *    What it makes, see in relevant section.
 *    * {@link app_components.TestChargeComponent|TestChargeComponent}
 */

const TestChargeModule = angular.module('app.test-charge', [])
    .component('testCharge', TestChargeComponent)
    .name;

// Default export
export default TestChargeModule;