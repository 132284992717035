// Import dependencies
import angular from 'angular';
import TotalComponent from './total.component';

/**
 * @name app.total
 * @class TotalModule
 * @memberOf app_modules
 * @description
 *  **Description**<br>
 *    The __app.total__ module is module that is responsible to make all preparation of data for total block
 *    and display him in application. This module consist from 'TotalComponent'.
 *    What it makes, see in relevant section.
 *    * {@link app_components.TotalComponent|TotalComponent}
 */
const TotalModule = angular.module('app.total', [])
    .component('total', TotalComponent)
    .name;

// Default export
export default TotalModule;