/**
 * @name TipsController
 * @class TipsController
 * @memberOf app_controllers
 * @description
 *  **Description**<br>
 *    The __TipsController__ is controller which control the total block state.<br><br>
 *  **List of methods**
 *  * [$onInit()]{@link app_controllers.TipsController.$onInit}
 *  * [isTipsError()]{@link app_controllers.TipsController.isTipsError}
 *  * [toggleTips()]{@link app_controllers.TipsController.toggleTips}
 *  * [tipCheckboxClass()]{@link app_controllers.TipsController.tipCheckboxClass}
 *  * [tipAgreement()]{@link app_controllers.TipsController.tipAgreement}
 *  * [getTipsMaxAmount()]{@link app_controllers.TipsController.getTipsMaxAmount}
 *  * [checkTipsAmount()]{@link app_controllers.TipsController.checkTipsAmount}
 */
class TipsController {

    /**
     * @constructs
     * @memberOf app_controllers.TipsController
     * @description
     *  **Description**<br>
     *    This method contains all initializations for that class
     * @property {Object} total - Contains the data about total
     * @dependency {service} $rootScope {@link https://docs.angularjs.org/api/ng/service/$rootScope|link}
     * @dependency {service} $anchorScroll {@link https://docs.angularjs.org/api/ng/service/$anchorScroll|link}
     * @dependency {service} SharedDataService {@link app_services.SharedDataService|link}
     */
    constructor(
        $rootScope,
        $anchorScroll,
        SharedDataService,
        CommonMethodsService,
        tips
    ) {

        // Extra dependencies
        this.$rootScope = $rootScope;
        this.$anchorScroll = $anchorScroll;
        this.SharedDataService = SharedDataService;
        this.CommonMethodsService = CommonMethodsService;
        this.tips = tips;
    }

    /**
     * @name $onInit
     * @function $onInit
     * @memberOf app_controllers.TipsController
     * @description
     *  **Description**<br>
     *    The __$onInit__ lifecycle hook is for all initialisation of code for a Controller.
     */
    $onInit() {

        if (this.appData.payment){
            this.ticketTotal = this.appData.payment.ticket_total.total_amount;
            this.ticketAirline = this.appData.payment.split_section.airline_amount;
            this.ticketItn = this.appData.payment.split_section.itn_amount;

            let ticketPrice = Number(this.appData.payment.ticket_total.total_amount) / 100;

            let cmsCompany = 'itn';

            if (this.appData.cmsCompany === 'UK' || this.appData.cmsCompany === 'CA'){
                cmsCompany = this.appData.cmsCompany.toLowerCase();
            }

            let ticketAmountRange = (ticketPrice < this.tips.normalTotalAmount)   ? 'sm'  :
                                    (ticketPrice < this.tips.averageTotalAmount)  ? 'nrm' :
                                    (ticketPrice < this.tips.largeTotalAmount)    ? 'avg' : 'lrg';

            this.appData.tipLevels = this.tips.ranges[cmsCompany][ticketAmountRange];
        }

        this.currency = this.appData.currency;
        this.isBogSale = this.appData.cmsCompany === 'BOG';
        this.isTipVisible = !this.appData.isHardcopy;
        this.isTipOpen = false;
        this.isTipsAmountError = false;

        if (!this.appData.isHardcopy) {
            this.updateTotalAmount();
        } 
    }


    /**
     * @name isTipsError
     * @function isTipsError
     * @memberOf app_controllers.TipsController
     * @description
     *  **Description**<br>
     *    The __tipCheckboxClass__ method defines is tips valid or not.
     * @returns {Boolean} Returns is tips valid or not
     */
    isTipsError() {
        return this.appData.total.confirmedTips && !this.appData.isTips && !this.isTipsAmountError && this.appData.isFormSubmitted;
    }

    /**
     * @name toggleTips
     * @function toggleTips
     * @memberOf app_controllers.TipsController
     * @description
     *  **Description**<br>
     *    The __toggleTips__ is method that toggle the tips from visible state to not visible and vice versa.
     */
    toggleTips() {
        this.isTipOpen = !this.isTipOpen;

        if (this.isTipOpen) {
            this.$anchorScroll.yOffset = 230;
            this.$anchorScroll('tips-popup');
        }
    }

    /**
     * @name tipCheckboxClass
     * @function tipCheckboxClass
     * @memberOf app_controllers.TipsController
     * @description
     *  **Description**<br>
     *    The __tipCheckboxClass__ method defines is tips valid and returns corresponding class name.
     * @returns {String} Returns class name for tip checkbox
     */
    tipCheckboxClass() {
        let isValidAmount = !this.appData.isTips && this.appData.total.confirmedTips > 0 && !this.appData.isHardcopy;

        return isValidAmount ? 'invalid-checkbox' : '';
    }

    /**
     * @name tipAgreement
     * @function tipAgreement
     * @memberOf app_controllers.TipsController
     * @description
     *  **Description**<br>
     *    The __tipAgreement__ method handle the tips confirmation event.
     *    When it fired, checks tips amount and update total amount value.
     */
    tipAgreement() {
        this.appData.isTips = false;
        this.checkTipsAmount();
        this.updateTotalAmount();
    }

    /**
     * @name getTipsMaxAmount
     * @function getTipsMaxAmount
     * @memberOf app_controllers.TipsController
     * @description
     *  **Description**<br>
     *    The __getTipsMaxAmount__ method defines maximum available amount of tips.
     * @returns {Number} maximum amount
     */
    getTipsMaxAmount() {
        let maxAmount = (this.ticketAirline || 0) <= this.ticketItn ? this.ticketItn : this.ticketAirline;
        return maxAmount || 100000;
    }

    /**
     * @name checkTipsAmount
     * @function checkTipsAmount
     * @memberOf app_controllers.TipsController
     * @description
     *  **Description**<br>
     *    The __checkTipsAmount__ method checks tips amount. If tips amount is bigger than tips maximum amount,
     *    assigns error message for tips.
     */
    checkTipsAmount() {
        if (this.appData.total.confirmedTips >= this.getTipsMaxAmount()) {
            this.isTipsAmountError = true;

            if (this.ticketTotal && this.ticketTotal === 0) {
                this.tipsAmountErrorKey = 'total.tips.exceedThousand';
                this.isTipsExceedThousand = true;
            }
            else {
                this.tipsAmountErrorKey = 'total.tips.exceedAirline';
                this.isTipsExceedThousand = false;
            }

        }
        else {
            this.isTipsAmountError = false;
        }
    }


    /**
     * @name updateTotalAmount
     * @function updateTotalAmount
     * @memberOf app_controllers.TipsController
     * @description
     *  **Description**<br>
     *    The __updateTotalAmount__ method updates ( value and tex ) of total amount.
     */
    updateTotalAmount() {
        this.$rootScope.$broadcast('update-total-amount');
    }


    /**
     * @name tipText
     * @function tipText
     * @memberOf app_controllers.TipsController
     * @description
     *  **Description**<br>
     *    The __tipText__ method returns prepared tip text via CommonMethodsService.prepareTipText.
     */
    get tipText() { return this.CommonMethodsService.prepareTipText(this.appData.total.confirmedTips/100, this.appData.tipLevels) }

}

// Injections of necessary dependencies
TipsController.$inject = [
    '$rootScope',
    '$anchorScroll',
    'SharedDataService',
    'CommonMethodsService',
    'app.tips'
];

// Default export
export default TipsController;