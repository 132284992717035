(function() {
    "use strict";

    angular.module('app.index')
        // Base application url, derived from $baseUrl variable
        .value('app.apiPath', baseUrl)

        // Status codes for get/post sale request rejections
        .value('app.saleStatus',{
            SUBMITTED_WITHOUT_TESTIMONIALS : 'sale_submitted_without_testimonials',
            SALE_FILLED_AND_SUBMITTED : 'sale_is_filled_and_submitted',
            SUBMITTED_WITHOUT_VERIFICATION : 'sale_submitted_without_verification',
            SALE_NOT_VALID : 'sale_not_valid_status',
            APP_UNEXPECTED_EXCEPTION: 'application_unexpected_exception',
            SALE_UPDATED: 'sale_updated',
            SALE_DEFAULT: 'sale_default',
            SALE_HARDCOPY: 'hardcopy',
            SALE_VERIFICATION_HARDCOPY: 'verification_hardcopy'
        })

        // String constants for possible verification types
        .value('app.verificationType',{
            CONFERENCE_CALL: 'Conference Call',
            TEST_CHARGE: 'Test Charge',
            TEST_CHARGE_CC_CHECK: 'Test charge/CC check',
            TEST_CHARGE_CC: 'Test charge/Conference call',
            CC_CHECK: 'CC Check',
            UNDEFINED_TYPE: 'Undefined Verification Type',
            OTHER_TYPE: 'Other Type of Verification',
            NO_VERIFICATION: 'No Verification',
            CC_CHECK_PLUS: 'CC Check+'
        })

        // Data for special consolidators
        .value('app.specialConsolidators',{
            list: ['Skylord Travel','Brightsun UK', 'Travelpack'],
            title: {
                'Skylord Travel': 'Skylord Travel Plc',
                'Brightsun UK': 'Brightsun Travel (UK) Ltd',
                'Travelpack': 'Travelpack'
            }
        })

        // Strings for Terms and Conditions fare rules
        .value('app.fareRules', {
            languages: ["en", "es"],
            en : {
                cancel: {
                    notBefore: "Cancellation and refunds before departure not permitted.",
                    notAfter: "Cancellation and refunds after departure not permitted.",
                    before: "Cancellation and refunds before departure permitted.",
                    after: "Cancellation and refunds after departure permitted.",
                    penaltyFirstBefore: "Cancellation and refunds before departure subject to ",
                    penaltyFirstAfter: "Cancellation and refunds after departure subject to "
                },
                exchange: {
                    notBefore: "Changes before departure not permitted.",
                    notAfter: "Changes after departure not permitted.",
                    before: "Changes before departure permitted.",
                    after: "Changes after departure permitted.",
                    penaltyFirstBefore: "Changes before departure subject to ",
                    penaltyFirstAfter: "Changes after departure subject to ",
                    penaltyExSecond: " penalty per ticket.",
                    fareDiff: "All the tickets are subject to fare difference when exchanged.",
                    limitFirst: "Travel must be completed no later than ",
                    limitSecond: " after departure from fare origin."
                },
                penaltySecond: " penalty per ticket."
            },
            es : {
                cancel: {
                    notBefore: "No se permiten cancelaciones y reembolsos antes de la salida.",
                    notAfter: "No se permiten cancelaciones y reembolsos después de la salida.",
                    before: "Se permiten cancelaciones y reembolsos antes de la salida.",
                    after: "Se permiten cancelaciones y reembolsos después de la salida.",
                    penaltyFirstBefore: "Las cancelaciones y reembolsoses antes de la salida están sujetos a una penalidad de ",
                    penaltyFirstAfter: "Las cancelaciones y reembolsoses después de la salida están sujetos a una penalidad de "
                },
                exchange: {
                    notBefore: "No se permiten cambios antes de la salida.",
                    notAfter: "No se permiten cambios después de la salida.",
                    before: "Se permiten cambios antes de la salida.",
                    after: "Se permiten cambios después de la salida.",
                    penaltyFirstBefore: "Los cambios antes de la salida están sujetos a una penalidad de ",
                    penaltyFirstAfter: "Los cambios después de la salida están sujetos a una penalidad de ",
                    penaltyExSecond: " por boleto.",
                    fareDiff: "Todos boletos están sujetos a la diferencia de tarifas en caso de cambios.",
                    limitFirst: "El viaje debe completarse, no más tarde de ",
                    limitSecond: " antes de la salida desde el origen de la tarifa."
                },
                penaltySecond: " penalty por boleto."
            }
        })

        // Numbers for day/month/year object creation
        .value('app.dateSettings',{
            minimalYear : 1901,
            firstDay : 1,
            firstMonth : 1,
            lastMonth : 12,
            lastDay : 31,
            cardExpireYears : 10,
            childMinAge : 2,
            adultMinAge : 12
        })

        // Currency strings for priceToWords filter
        .value('app.currencyText', {
            en : {
                USD: {
                    title: 'US/US',
                    unit: 'dollar/dollars',
                    subunit: 'cent/cents'
                },
                CAD: {
                    title: 'Canadian/Canadian',
                    unit: 'dollar/dollars',
                    subunit: 'cent/cents'
                },
                GBP: {
                    title: 'British/British',
                    unit: 'pound/pounds',
                    subunit: 'penny/pence'
                },
                numbers: {
                    digit : ['zero', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine'],
                    tenAndDigit : ['ten', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'],
                    tens : ['twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety']
                }
            },
            es : {
                USD: {
                    title: 'EE.UU./EE.UU.',
                    unit: 'dólar/dólares',
                    subunit: 'centavo/centavos'
                },
                CAD: {
                    title: 'canadiense/canadienses',
                    unit: 'dólar/dólares',
                    subunit: 'centavo/centavos'
                },
                GBP: {
                    title: 'esterlina/esterlinas',
                    unit: 'libra/libras',
                    subunit: 'penique/peniques'
                },
                numbers: {
                    "baseSeparator": " y ",
                    "unitSeparator": "",
                    "base": {
                        "0": "cero",
                        "1": "uno",
                        "2": "dos",
                        "3": "tres",
                        "4": "cuatro",
                        "5": "cinco",
                        "6": "seis",
                        "7": "siete",
                        "8": "ocho",
                        "9": "nueve",
                        "10": "diez",
                        "11": "once",
                        "12": "doce",
                        "13": "trece",
                        "14": "catorce",
                        "15": "quince",
                        "16": "dieciséis",
                        "17": "diecisiete",
                        "18": "dieciocho",
                        "19": "diecinueve",
                        "20": "veinte",
                        "21": "veintiuno",
                        "22": "veintidós",
                        "23": "veintitrés",
                        "24": "veinticuatro",
                        "25": "veinticinco",
                        "26": "veintiséis",
                        "27": "veintisiete",
                        "28": "veintiocho",
                        "29": "veintinueve",
                        "30": "treinta",
                        "40": "cuarenta",
                        "50": "cincuenta",
                        "60": "sesenta",
                        "70": "setenta",
                        "80": "ochenta",
                        "90": "noventa",
                        "100": "cien",
                        "200": "doscientos",
                        "300": "trescientos",
                        "400": "cuatrocientos",
                        "500": "quinientos",
                        "600": "seiscientos",
                        "700": "setecientos",
                        "800": "ochocientos",
                        "900": "novecientos",
                        "1000": "mil"
                    },
                    "unitExceptions": {
                        "1": "un"
                    },
                    "units" : [
                        {
                            "singular": "ciento",
                            "useBaseException": [1]
                        },
                        {
                            "singular": "mil",
                            "avoidPrefixException": [1]
                        },
                        {
                            "singular": "millón",
                            "plural": "millones"
                        },
                        {
                            "singular": "billón",
                            "plural": "billones"
                        },
                        {
                            "singular": "trillón",
                            "plural": "trillones"
                        },
                        {
                            "singular": "cuatrillón",
                            "plural": "cuatrillones"
                        },
                        {
                            "singular": "quintillón",
                            "plural": "quintillones"
                        },
                        {
                            "singular": "sextillón",
                            "plural": "sextillones"
                        },
                        {
                            "singular": "septillón",
                            "plural": "septillones"
                        },
                        {
                            "singular": "octillón",
                            "plural": "octillones"
                        },
                        {
                            "singular": "nonillón",
                            "plural": "nonillones"
                        },
                        {
                            "singular": "decillón",
                            "plural": "decillones"
                        },
                        {
                            "singular": "undecillón",
                            "plural": "undecillones"
                        },
                        {
                            "singular": "duodecillón",
                            "plural": "duodecillones"
                        },
                        {
                            "singular": "tredecillón",
                            "plural": "tredecillones"
                        },
                        {
                            "singular": "cuatrodecillón",
                            "plural": "cuatrodecillones"
                        },
                        {
                            "singular": "quindecillón",
                            "plural": "quindecillones"
                        }
                    ]
                }
            }
        })

        // array of passenger program special preferences
        .value('app.programPreferences',{
            "meals" : [
                "AVML", "BBML", "BLML", "CHML", "DBML", "FPML", "HNML", "KSML", "LCML", "LFML", "LSML", "MOML", "NLML", "RVML", "SFML", "VGML", "VJML", "VLML", "VOML", "GFML", "SPML"
            ],
            "seats": [
                "aisle", "window", "predefined"
            ],
            "services": [
               "BLND","DEAF","WCBD","WCHC","WCHR","WCHS"
            ]
        })

        // array of timezone values for conference call verification page
        // changes in this array should be made also for verification.timezones in en.json/es.json
        .value('app.timezones',[
            "-12", "-11", "-10", "-9", "-8", "-7", "-6", "-5", "-4", "-3", "-2", "-1", "0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"
        ])

        .value('app.tiptext',{
            ranges: ['It was OK', 'It was good', 'Nicely done', 'I\'m fully satisfied', 'Outstanding', 'It was exceptional']
        })

        // Settings for tip section
        .value('app.tips', {
            normalTotalAmount : 1000,
            averageTotalAmount : 1500,
            largeTotalAmount : 2500,

            // Range values must be >= 5 , and aliquot to 5 (5 * integer)
            ranges : {
                uk: {
                    sm : [10, 15, 20, 25, 30, 40, 50],
                    nrm : [15, 20, 25, 30, 35, 45, 55],
                    avg : [20, 25, 30, 35, 40, 55, 70],
                    lrg : [25, 30, 40, 50, 70, 100, 150]
                },
                ca: {
                    sm : [10, 15, 20, 25, 30, 40, 50],
                    nrm : [15, 20, 25, 30, 35, 45, 55],
                    avg : [20, 25, 30, 35, 40, 55, 70],
                    lrg : [25, 30, 40, 50, 70, 100, 150]
                },
                itn: {
                    sm : [5, 10, 15, 20, 25, 30, 40],
                    nrm : [10, 15, 20, 30, 40, 50, 60],
                    avg : [15, 25, 35, 50, 60, 80, 100],
                    lrg : [20, 30, 40, 60, 80, 100, 150]
                }
            }
        });
})();
