// Import dependencies
import angular from 'angular';
import PaymentComponent from './payment.component';
import PaymentService from './payment.service';

/**
 * @name app.payment
 * @class PaymentModule
 * @memberOf app_modules
 * @description
 *  **Description**<br>
 *    The __app.payment__ module is module that is responsible to make all preparation of data for payment block
 *    and display him in application. This module consist from 'PaymentComponent' and 'PaymentService'.
 *    What make each of them, see in relevant section.
 *    * {@link app_components.PaymentComponent|PaymentComponent}
 *    * {@link app_services.PaymentService|PaymentService}
 */
const PaymentModule = angular.module('app.payment', [])
    .component('payment', PaymentComponent)
    .service('PaymentService', PaymentService)
    .name;

// Default export
export default PaymentModule;