// Import dependencies
import angular from 'angular';

/**
 * @name HotelService
 * @class HotelService
 * @memberOf app_services
 * @description
 *  **Description**<br>
 *    The __HotelService__ contains logic to prepare data for hotel block.<br><br>
 *  **List of methods**
 *  * [fetchData()]{@link app_services.HotelService.fetchData}
 *  * [updateData()]{@link app_services.HotelService.updateData}
 *  * [createData()]{@link app_services.HotelService.createData}
 *  * [getRating()]{@link app_services.HotelService.getRating}
 *  * [getImages()]{@link app_services.HotelService.getImages}
 *  * [getLocationText()]{@link app_services.HotelService.getLocationText}
 */
class HotelService {

    /**
     * @constructs
     * @memberOf app_services.HotelService
     * @description
     *  **Description**<br>
     *    This method contains all initializations for that class
     * @dependency {library} $translate {@link https://github.com/angular-translate/angular-translate|link}
     * @dependency {service} NormalizeDataService {@link app_services.NormalizeDataService|link}
     * @dependency {service} CommonMethodsService {@link app_services.CommonMethodsService|link}
     * @dependency {service} SharedDataService {@link app_services.SharedDataService|link}
     */
    constructor(
        $translate,
        NormalizeDataService,
        CommonMethodsService,
        SharedDataService
    ) {

        // Extra dependencies
        this.$translate = $translate;
        this.NormalizeDataService = NormalizeDataService;
        this.CommonMethodsService = CommonMethodsService;
        this.SharedDataService = SharedDataService;

        // Properties
        this.hotelData = null;
    }

    /**
     * @name fetchData
     * @function fetchData
     * @memberOf app_services.HotelService
     * @description
     *  **Description**<br>
     *    The __fetchData__ method returns prepared hotel data.
     *    If data already were created method returns data otherwise create data, assigns it to
     *    'hotelData' variable and returns created data.
     * @returns {Object} Prepared hotel data
     */
    fetchData(serverData) {
        let data;

        if (this.hotelData) {
            data = this.hotelData;
        }
        else if(serverData) {
            data = this.hotelData = this.createData(serverData);
        }
        else {
            data = null;
        }

        return data;
    }

    /**
     * @name updateData
     * @function updateData
     * @memberOf app_services.HotelService
     * @description
     *  **Description**<br>
     *    The __updateData__ method returns updated hotel data.
     * @returns {Object} Updated hotel data
     */
    updateData(serverData) {
        return this.createData(serverData);
    }

    /**
     * @name createData
     * @function createData
     * @memberOf app_services.HotelService
     * @description
     *  **Description**<br>
     *    The __createData__ method execute all necessary actions to prepare hotel data.
     * @param {Object} serverData - source data
     * @returns {Object} prepared data for hotel block
     */
    createData(serverData) {
        let data;

        this.hotel = serverData.hotel;
        this.payment = serverData.payment;

        if (this.hotel && this.payment) {
            if (this.NormalizeDataService.typeOf(this.hotel) === 'array') {
                data = {
                    isHotelDataAvailable: false
                };
            }
            else {

                // Copy necessary properties. See more NormalizeDataService.copyProperties()
                data = this.NormalizeDataService.copyProperties(this.hotel, [
                    'title',
                    'address',
                    'guests',
                    'amenities',
                    'rooms',
                    'checkIn',
                    'gallery'
                ]);

                let ticketTotal = this.payment.ticket_total;
                angular.merge(data, {
                    isHotelDataAvailable: true,
                    rating: this.hotel.rating ? this.getRating() : {},
                    images: this.getImages(),
                    currency: ticketTotal.hotel_currency,
                    price: ticketTotal.hotel_amount,
                    isChargePrePayment: ticketTotal.hotel_payment_type === 'prePayment',
                    isChargeAtCheckIn: ticketTotal.hotel_payment_type === 'atCheckIn',
                    processedByCompany: this.hotel.vendor === 'expedia' ? 'Expedia' : 'ITN LLC',
                    locationText: this.getLocationText(),
                    roomTextKey: this.hotel.rooms > 1 ? 'hotelInfo.rooms' : 'hotelInfo.room',
                    guestTextKey: this.hotel.guests > 1 ? 'hotelInfo.guests' : 'hotelInfo.guest'
                });
            }
        }
        else {
            data = null;
        }

        return data;
    }

    /**
     * @name getRating
     * @function getRating
     * @memberOf app_services.HotelService
     * @description
     *  **Description**<br>
     *    The __getRating__ prepares hotel rating.
     * @returns {Object} rating data
     */
    getRating() {
        let fullRating = Math.floor(Number(this.hotel.rating));

        return {
            full: fullRating,
            isHalf: this.hotel.rating > fullRating
        };
    }

    /**
     * @name getImages
     * @function getImages
     * @memberOf app_services.HotelService
     * @description
     *  **Description**<br>
     *    The __getImages__ prepares hotel images.
     * @returns {Array} list of images
     */
    getImages() {
        let images = [
            'src/img/hotel_placeholder.gif'
        ];

        if (this.NormalizeDataService.typeOf(this.hotel.images) === 'array' && this.hotel.images.length) {

            if (this.hotel.images.length <= 3) {
                images = this.hotel.images;
            } else {
                images = this.hotel.images.slice(0,3);
            }

        }

        return images;
    }

    /**
     * @name getLocationText
     * @function getLocationText
     * @memberOf app_services.HotelService
     * @description
     *  **Description**<br>
     *    The __getLocationText__ method constructs location string depending of data available.
     * @returns {String} Constructed string
     */
    getLocationText() {
        let text = this.hotel.title;
        let language = this.$translate.use();

        if (this.hotel.city && this.hotel.city[language]) {
            text += `, ${this.hotel.city[language]}`;
        }

        if (this.hotel.country && this.hotel.country[language]) {
            text += `, ${this.hotel.country[language]}`;
        }

        let type = this.CommonMethodsService.getData('ticket_total.hotel_payment_type', this.payment);
        if (type === 'atCheckIn') {
            text += '*';
        }

        return text;
    }
}

// Injections of necessary dependencies
HotelService.$inject = [
    '$translate',
    'NormalizeDataService',
    'CommonMethodsService',
    'SharedDataService'
];

// Default export
export default HotelService;