// Import dependencies
import template from './baggage.template.html';

/**
 * @name BaggageComponent
 * @class BaggageComponent
 * @memberOf app_components
 * @description
 *  **Description**<br>
 *    The __baggage__ component contains all needed to display 'baggage' block. That include itself ( template ).
 *    For more details see:
 *    * {@link https://gitlab.dyninno.net/oaf/standalone/blob/master/web/public/src/components/rules/baggage/baggage.template.html|'baggage' template}
 */
const BaggageComponent = {
    controller: function() {},
    controllerAs: 'BaggageCtrl',
    template,
    bindings: {
        appData: '<'
    },
    require: {
        rules: '^rules'
    }
};

// Default export
export default BaggageComponent;