// Import dependencies
import angular from 'angular';

/**
 * @name PassengersService
 * @class PassengersService
 * @memberOf app_services
 * @description
 *  **Description**<br>
 *    The __PassengersService__ contains logic to prepare data for passengers block.<br><br>
 *  **List of methods**
 *  * [fetchData()]{@link app_services.PassengersService.fetchData}
 *  * [createData()]{@link app_services.PassengersService.createData}
 *  * [updateData()]{@link app_services.PassengersService.updateData}
 *  * [createPassengerDataForNotSubmittedSale()]{@link app_services.PassengersService.createPassengerDataForNotSubmittedSale}
 *  * [createPassengerDataForHardcopy()]{@link app_services.PassengersService.createPassengerDataForHardcopy}
 *  * [createCommonPassengerData()]{@link app_services.PassengersService.createCommonPassengerData}
 *  * [prepareProgram()]{@link app_services.PassengersService.prepareProgram}
 *  * [getHighlightedFields()]{@link app_services.PassengersService.getHighlightedFields}
 *  * [isTicketPriceVisible()]{@link app_services.PassengersService.isTicketPriceVisible}
 *  * [preparePassengersDataToSend()]{@link app_services.PassengersService.preparePassengersDataToSend}
 */
class PassengersService {

    /**
     * @constructs
     * @memberOf app_services.PassengersService
     * @description
     *  **Description**<br>
     *    This method contains all initializations for that class
     * @dependency {service} NormalizeDataService {@link app_services.NormalizeDataService|link}
     * @dependency {service} CommonMethodsService {@link app_services.CommonMethodsService|link}
     * @dependency {service} SharedDataService {@link app_services.SharedDataService|link}
     */
    constructor(
        NormalizeDataService,
        CommonMethodsService,
        SharedDataService,
        programPreferences
    ) {

        // Extra dependencies
        this.NormalizeDataService = NormalizeDataService;
        this.CommonMethodsService = CommonMethodsService;
        this.SharedDataService = SharedDataService;
        this.programPreferences = programPreferences;

        // Properties
        this.payment = null;
        this.passengers = null;
        this.passengersData = null;
    }

    /**
     * @name fetchData
     * @function fetchData
     * @memberOf app_services.PassengersService
     * @description
     *  **Description**<br>
     *    The __fetchData__ method returns prepared protection data.
     *    If data already were created method returns data otherwise create data, assigns it to
     *    'passengersData' variable and returns created data.
     * @returns {Object} Prepared protection data
     */
    fetchData(serverData) {
        let data;

        if (this.passengersData) {
            data = this.passengersData;
        }
        else if(serverData) {
            data = this.passengersData = this.createData(serverData);
        }
        else {
            data = null;
        }

        return data;
    }

    /**
     * @name createData
     * @function createData
     * @memberOf app_services.PassengersService
     * @description
     *  **Description**<br>
     *    The __createData__ method execute all necessary actions to prepare passengers data.
     * @param {Object} serverData - source data
     * @returns {Object} prepared data for passengers block
     */
    createData(serverData) {
        let data;

        this.hotel = serverData.hotel;
        this.payment = serverData.payment;
        this.passengers = serverData.passengers;

        if (this.passengers && this.payment) {
            if (this.SharedDataService.isHardcopy) {
                data = this.createPassengerDataForHardcopy(serverData);
            }
            else {
                data = this.createPassengerDataForNotSubmittedSale();
            }
        }
        else {
            data = null;
        }

        return data;
    }

    /**
     * @name updateData
     * @function updateData
     * @memberOf app_services.PassengersService
     * @description
     *  **Description**<br>
     *    The __updateData__ method updates passengers data.
     * @param {Object} passengers - previous passenger data
     * @param {Object} serverData - data from server for update
     * @returns {Object} updated data for passengers block
     */
    updateData(passengers, serverData) {
        let tempPassengers = angular.copy(passengers);

        if (serverData.passengers) {
            angular.forEach(serverData.passengers, (passenger, key) => {
                tempPassengers[key].ticket_price = passenger.ticket_price;
            });
        }

        return tempPassengers;
    }

    /**
     * @name createPassengerDataForNotSubmittedSale
     * @function createPassengerDataForNotSubmittedSale
     * @memberOf app_services.PassengersService
     * @description
     *  **Description**<br>
     *    The __createPassengerDataForNotSubmittedSale__ method returns prepared passengers data.
     *    This method used for sale who else was not be submitted.
     * @returns {Object} Prepared passengers data
     */
    createPassengerDataForNotSubmittedSale() {
        let passengers = [];

        angular.forEach(this.passengers, (passenger, key) => {
            let tempPassenger = angular.copy(passenger);
            angular.merge(tempPassenger, this.createCommonPassengerData(tempPassenger));

            passengers.push(tempPassenger);
        });

        return passengers;
    }

    /**
     * @name createPassengerDataForHardcopy
     * @function createPassengerDataForHardcopy
     * @memberOf app_services.PassengersService
     * @description
     *  **Description**<br>
     *    The __createPassengerDataForHardcopy__ method returns prepared passengers data.
     *    This method used for sale who was be submitted.
     * @returns {Object} Prepared passengers data
     */
    createPassengerDataForHardcopy(serverData) {
        let passengers = [];
        let submittedPassengers = this.CommonMethodsService.getData('submittedData.passengers', serverData);

        if (this.NormalizeDataService.typeOf(submittedPassengers) === 'array') {
            angular.forEach(submittedPassengers, (passenger, key) => {
                let tempPassenger = angular.copy(passenger);

                angular.merge(tempPassenger, this.createCommonPassengerData(tempPassenger));

                // Assigns the original data fo passenger ( data that were filled by agent ).
                // This property 'original' used to compare 'submitted data' with 'original data'
                // for highlight fields in form with different data.
                tempPassenger.original = angular.copy(this.passengers[key]);

                // // In this place we define if some fields for passenger must be highlighted
                // // ( see more details in getHighlightedFields() method ).
                tempPassenger.highlightedFields = this.getHighlightedFields(tempPassenger);

                passengers.push(tempPassenger);
            });
        }

        return passengers;
    }

    /**
     * @name createCommonPassengerData
     * @function createCommonPassengerData
     * @memberOf app_services.PassengersService
     * @description
     *  **Description**<br>
     *    The __createCommonPassengerData__ method returns prepared passengers data.
     *    This method used for sale who was be submitted.
     * @returns {Object} Prepared passengers data
     */
    createCommonPassengerData(data) {
        let passenger = {};

        // Prepares the data of program for passenger
        passenger.program = this.prepareProgram(data.program);

        // Defines the need to display the price of the ticket for passenger
        passenger.isTicketPriceVisible = this.isTicketPriceVisible();

        // Defines the need to display 'redress block'. It dependence from sale type.
        // The block is displayed if the sale type is both 'SALE' or 'EXCHANGE'.
        passenger.isRedressBlockVisible = this.CommonMethodsService.isCorrespondsToTypeOfSale(
            this.SharedDataService.saleType,
            [
                'SALE',
                'EXCHANGE'
            ]
        );

        // We must make sure as the email not contains 'break spaces'. It is important for form validation.
        if (this.NormalizeDataService.typeOf(data.email) === 'string') {
            passenger.email = data.email.replace(' ', '');
        }

        // If passenger has the redress number, we assign the flag 'redress' to 'true'.
        // It applies for 'redress' checkbox for each passenger.
        passenger.redress = !!data.redress_number;

        return passenger;
    }

    /**
     * @name prepareProgram
     * @function prepareProgram
     * @memberOf app_services.PassengersService
     * @description
     *  **Description**<br>
     *    The __prepareProgram__ method prepares passenger program.
     * @param {Array} program - passenger program
     * @returns {Array} Prepared program
     */
    prepareProgram(program) {
        let tempProgram = null;

        // Way to prepare the data of program for passenger if data about program is in array format.
        if (this.NormalizeDataService.typeOf(program) === 'array') {

            // If passed program is an array, we simply assign it to 'tempProgram' variable.
            tempProgram = program;
        }

        // If passed program not an array, we check the state of application on 'hardcopy state'.
        // That needed for not display empty progarm in 'hardcopy' state.
        else {

            // If application state not 'hardcopy', we assign an array to variable 'tempProgram'
            // with an object that is an empty program.
            if (!this.SharedDataService.isHardcopy) {
                tempProgram = [{
                    name: null,
                    number: null
                }];
            }
        }

        return tempProgram;
    }

    /**
     * @name getHighlightedFields
     * @function getHighlightedFields
     * @memberOf app_services.PassengersService
     * @description
     *  **Description**<br>
     *    The __getHighlightedFields__ method defines, need or not, highlight some field.
     * @param {Object} passenger - passenger data
     * @returns {Object} Contains all keys of passenger with ( true|false ) values,
     *  what must be highlighted and what not.
     */
    getHighlightedFields(passenger) {
        let highlightedFields = {};

        angular.forEach(passenger.original, function(value, key) {
            if (passenger[key]) {
                if (angular.isString(passenger[key]) && angular.isString(value)) {
                    highlightedFields[key] = passenger[key].toLowerCase() !== value.toLowerCase();
                } else {
                    highlightedFields[key] = passenger[key] !== value;
                }
            }
        });

        if (passenger.preferences) {
            angular.forEach(passenger.preferences, function(value, key) {
                if (angular.isString(passenger[key]) && angular.isString(value)) {
                    highlightedFields[key] = passenger[key].toLowerCase() !== value.toLowerCase();
                } else {
                    highlightedFields[key] = passenger[key] !== value;
                }
            });
        }

        if (passenger.redress_number) {
            highlightedFields.redress_number = passenger.redress_number !== passenger.original.redress_number;
        }

        return highlightedFields;
    }

    /**
     * @name isTicketPriceVisible
     * @function isTicketPriceVisible
     * @memberOf app_controllers.PassengersController
     * @description
     *  **Description**<br>
     *    The __isTicketPriceVisible__ defines the need to display the price of the ticket for passenger
     * @returns {Boolean} return true if ticket price will be visible
     */
    isTicketPriceVisible() {
        let isVisible = true;

        if (this.NormalizeDataService.typeOf(this.hotel) !== 'array') {

            let hotelPrice = this.payment.ticket_total.hotel_amount;
            let isCorrespondsToTypeOfSale = this.CommonMethodsService.isCorrespondsToTypeOfSale(
                this.SharedDataService.saleType,
                [
                    'SALE',
                    'EARLY_VOID',
                    'LATE_VOID'
                ]
            );

            isVisible = isCorrespondsToTypeOfSale && !!+hotelPrice;
        }

        return isVisible;
    }

    /**
     * @name preparePassengersDataToSend
     * @function preparePassengersDataToSend
     * @memberOf app_services.PassengersService
     * @description
     *  **Description**<br>
     *    The __preparePassengersDataToSend__ method prepares passengers for send to server
     * @returns {Array} Prepared passengers
     */
    preparePassengersDataToSend(passengers) {
        let tempPassengers = [];

        angular.forEach(passengers, (passenger) => {
            let tempPassenger = {
                id: passenger.id,
                firstname: passenger.firstname,
                middlename: passenger.middlename,
                lastname: passenger.lastname,
                dob: passenger.dob,
                type: passenger.type,
                gender: passenger.gender,
                ticket_price: passenger.ticket_price
            };

            if (passenger.email) {
                tempPassenger.email = passenger.email;
            }

            if (passenger.phone) {
                tempPassenger.phone = passenger.phone;
            }

            if (passenger.redress && passenger.redress_number) {
                tempPassenger.redress = passenger.redress;
                tempPassenger.redress_number = passenger.redress_number;
            }

            if (passenger.preferences) {
                tempPassenger.preferences = passenger.preferences;

                if (tempPassenger.preferences.meal && this.programPreferences.meals.indexOf(tempPassenger.preferences.meal) === -1) {
                    delete tempPassenger.preferences.meal;
                }
            }

            if (passenger.program) {

                let tempProgram = [];

                // Iterates passenger program and collect only filled objects.
                angular.forEach(passenger.program, function (program) {
                    if (program.name && program.number) {
                        tempProgram.push({
                            name: program.name,
                            number: program.number
                        });
                    }
                });

                // If program is an empty array, it should be deleted
                if (tempProgram.length > 0) {
                    tempPassenger.program = tempProgram;
                }
            }

            tempPassengers.push(tempPassenger);
        });

        return tempPassengers;
    }

}

// Injections of necessary dependencies
PassengersService.$inject = [
    'NormalizeDataService',
    'CommonMethodsService',
    'SharedDataService',
    'app.programPreferences'
];

// Default export
export default PassengersService;