(function() {
    "use strict";

    angular.module('app.directives').directive('replace', function() {
        return {
            require: 'ngModel',
            scope: true,
            link: function(scope, element, attrs, model) {
                var validateKeypress = 'validateKeypress' in attrs;
                model.$parsers.push(function(val) {
                    if (validateKeypress && !val) {
                        return;
                    }
                    var regex = new RegExp(attrs.replace);
                    var replaced = val.replace(regex, '');

                    if (replaced !== val) {
                        model.$setViewValue(replaced);
                        model.$render();
                    }

                    return replaced;
                });
            }
        };
    });
})();

