// Import dependencies
import angular from "angular";

/**
 * @name PaymentController
 * @class PaymentController
 * @memberOf app_controllers
 * @description
 *  **Description**<br>
 *    The __PaymentController__ is controller which control the payment block state.<br><br>
 *  **List of methods**
 *  * [$onInit()]{@link app_controllers.PaymentController.$onInit}
 *  * [$onChanges()]{@link app_controllers.PaymentController.$onChanges}
 *  * [isAffirmAvailable()]{@link app_controllers.PaymentController.isAffirmAvailable}
 *  * [getCountries()]{@link app_controllers.PaymentController.getCountries}
 *  * [removeZipCodeError()]{@link app_controllers.PaymentController.removeZipCodeError}
 *  * [comparePhone()]{@link app_controllers.PaymentController.comparePhone}
 *  * [isSpecialConsolidator()]{@link app_controllers.PaymentController.isSpecialConsolidator}
 *  * [isStateSelectDisabled()]{@link app_controllers.PaymentController.isStateSelectDisabled}
 *  * [checkCardType()]{@link app_controllers.PaymentController.checkCardType}
 *  * [getCardExpDataForChoice()]{@link app_controllers.PaymentController.getCardExpDataForChoice}
 *  * [setAffirm()]{@link app_controllers.PaymentController.setAffirm}
 *  * [checkoutAffirm()]{@link app_controllers.PaymentController.checkoutAffirm}
 *  * [postAffirm()]{@link app_controllers.PaymentController.postAffirm}
 *  * [checkAffirmCountryError()]{@link app_controllers.PaymentController.checkAffirmCountryError}
 *  * [affirmSuccess()]{@link app_controllers.PaymentController.affirmSuccess}
 *  * [affirmRejection()]{@link app_controllers.PaymentController.affirmRejection}
 *  * [updateValidMonths()]{@link app_controllers.PaymentController.updateValidMonths}
 *  * [isMonthDisabled()]{@link app_controllers.PaymentController.isMonthDisabled}
 */
class PaymentController {

    /**
     * @constructs
     * @memberOf app_controllers.PaymentController
     * @description
     *  **Description**<br>
     *    This method contains all initializations for that class
     * @property {Object} payment - Contains the data about payment
     * @dependency {service} $rootScope {@link https://docs.angularjs.org/api/ng/service/$rootScope|link}
     * @dependency {service} $timeout {@link https://docs.angularjs.org/api/ng/service/$timeout|link}
     * @dependency {service} SaleApi {@link app_services.SaleApi|link}
     * @dependency {service} NormalizeDataService {@link app_services.NormalizeDataService|link}
     * @dependency {service} SharedDataService {@link app_services.SharedDataService|link}
     * @dependency {service} CommonMethodsService {@link app_services.CommonMethodsService|link}
     * @dependency {service} FlightService {@link app_services.FlightService|link}
     * @dependency {service} PaymentService {@link app_services.PaymentService|link}
     * @dependency {service} LogService {@link app_services.LogService|link}
     */
    constructor(
        $rootScope,
        $timeout,
        SaleApi,
        itnApi,
        NormalizeDataService,
        SharedDataService,
        CommonMethodsService,
        FlightService,
        PaymentService,
        LogService,
        dateSettings,
        specialConsolidators
    ) {

        // Extra dependencies
        this.$rootScope = $rootScope;
        this.$timeout = $timeout;
        this.SaleApi = SaleApi;
        this.itnApi = itnApi;
        this.NormalizeDataService = NormalizeDataService;
        this.SharedDataService = SharedDataService;
        this.CommonMethodsService = CommonMethodsService;
        this.FlightService = FlightService;
        this.PaymentService = PaymentService;
        this.LogService = LogService;
        this.dateSettings = dateSettings;
        this.specialConsolidators = specialConsolidators;

        // Properties
        this.payment = {};
        this.countries = {};
        this.states = {};
        this.objectToSend = {};
        this.isServicePhoneMatch = false;
        this.isAffirmNotify = false;
        this.isAffirmErrorSent = false;
        this.isLastMinute = false;
        this.ccvLength = 3;

        this.getCardExpDataForChoice();
    }

    /**
     * @name $onInit
     * @function $onInit
     * @memberOf app_controllers.PaymentController
     * @description
     *  **Description**<br>
     *    The __$onInit__ lifecycle hook is for all initialisation of code for a Controller.
     */
    $onInit() {

        this.payment = this.appData.payment;

        if (this.payment) {

            if (!this.payment.creditcard) {
                this.payment.creditcard = {
                    expiration_date: {}
                };
            }

            this.appData.isAffirmAvailable = this.isAffirmAvailable();

            this.isHardcopy = this.appData.isHardcopy;
            this.isPaymentTypeWt = this.appData.paymentType === 'wt';
            this.isPaymentTypeCard = this.appData.paymentType !== 'wt';
            this.isCmsCompanyUk = this.appData.cmsCompany === 'UK';
            this.isSaleUkSpecific = (this.isCmsCompanyUk && this.appData.currency.title === 'GBP');

            if (!this.isHardcopy) {
                this.comparePhone();
                this.getCountries();
            }
        }
    }


    /**
     * @name $onChanges
     * @function $onChanges
     * @memberOf app_controllers.PaymentController
     * @description
     *  **Description**<br>
     *    The __$onChanges__ lifecycle hook is for update payment data.
     */
    $onChanges(changes) {
        if (!angular.equals({}, changes.appData.previousValue)) {
            this.payment = this.appData.payment;

            if (!this.payment.creditcard) {
                this.payment.creditcard = {
                    expiration_date: {}
                };
            }
        }
    }

    /**
     * @name isAffirmAvailable
     * @function isAffirmAvailable
     * @memberOf app_controllers.PaymentController
     * @description
     *  **Description**<br>
     *    The __isAffirmAvailable__ checks conditions for showing affirm payment option
     * @returns {Boolean} return true if affirm is available for this sale
     */
    isAffirmAvailable() {
        return false;
        // let isHardcopyAffirm = !this.appData.isHardcopy || this.appData.isHardcopyAffirm;
        // let isEmployeeTester = Boolean(this.appData.employee && this.appData.employee.oaf_tester);
        //
        // return isEmployeeTester &&
        //     isHardcopyAffirm &&
        //     this.appData.saleType === 'SALE' &&
        //     this.appData.paymentType !== 'wt';
    }

    /**
     * @name getCountries
     * @function getCountries
     * @memberOf app_controllers.PaymentController
     * @description
     *  **Description**<br>
     *    The __getCountries__ method responsible to get data about countries from server. It makes request to server
     *    and after success response prepares ( countries & states ) data. Otherwise repead the request.
     */
    getCountries() {
        this.itnApi.getCountries({},
            response => {
                this.countries = response.result;
            },
            rejection => {
                this.$timeout(() => {
                    this.getCountries();
                }, 1000);
            }
        );
    }

    /**
     * @name removeZipCodeError
     * @function removeZipCodeError
     * @memberOf app_controllers.PaymentController
     * @description
     *  **Description**<br>
     *    The __removeZipCodeError__ method remove error on "zip_code" field in the moment when the country was changed.
     */
    removeZipCodeError() {
        this.itnForm.payment.zip_code.$setValidity('not_valid', true);
    }

    /**
     * @name comparePhone
     * @function comparePhone
     * @memberOf app_controllers.PaymentController
     * @description
     *  **Description**<br>
     *    The __comparePhone__ method compares service phone with billing phone.
     *    If phone numbers are equals sets the isServicePhoneMatch flag to true.
     */
    comparePhone() {

        let billingPhoneNumber = this.payment.billing_phone_number;
        let servicePhoneNumber = this.payment.creditcard ? this.payment.creditcard.service_phone_number : '';

        if (billingPhoneNumber && servicePhoneNumber) {
            this.isServicePhoneMatch = servicePhoneNumber === billingPhoneNumber;
        }
        else {
            this.isServicePhoneMatch = false;
        }
    }

    /**
     * @name isStateSelectDisabled
     * @function isStateSelectDisabled
     * @memberOf app_controllers.PaymentController
     * @description
     *  **Description**<br>
     *    The __isStateSelectDisabled__ method checks if the select box of states is disabled.
     * @returns {Boolean} return true if select box is disabled
     */
    isStateSelectDisabled() {
        let isCountryEmpty = Boolean(this.payment && !this.payment.country);
        let states = {};

        if (this.countries && this.payment && this.payment.country && this.payment.country.code) {
            let chosenCountry = null;

            this.countries.map((country) => {
                if (this.payment.country.code === country.code) {
                    chosenCountry = country;
                }
            });

            if (chosenCountry) {
                states = chosenCountry.states
            }
        }

        let isStatesEmpty = Boolean(!states || states && angular.equals(states, {}));

        return isCountryEmpty || !isCountryEmpty && isStatesEmpty;
    }

    /**
     * @name checkCardType
     * @function checkCardType
     * @memberOf app_controllers.PaymentController
     * @description
     *  **Description**<br>
     *    The __checkCardType__ method checks the type of card and depending on the result
     *    assigns ccv length for validation.
     */
    checkCardType() {
        if (this.payment.creditcard && this.payment.creditcard.card_number) {
            let amexCardPattern = new RegExp(/^3[47][0-9]{13}$/);
            this.ccvLength = amexCardPattern.test(this.payment.creditcard.card_number) ? 4 : 3;
        }
    }

    /**
     * @name getCardExpDataForChoice
     * @function getCardExpDataForChoice
     * @memberOf app_controllers.PaymentController
     * @description
     *  **Description**<br>
     *    The __getCardExpDataForChoice__ method prepares data for choice for card expiration date.
     */
    getCardExpDataForChoice() {
        let today = new Date();
        let cardExpireYears = this.dateSettings.cardExpireYears;
        let currentYear = today.getFullYear();

        this.cardExpDataForChoice = {
            years: this.CommonMethodsService.createNumberList(
                currentYear,
                currentYear + cardExpireYears
            ),
            months: this.CommonMethodsService.createNumberList(1, 12)
        };
    }

    /**
     * @name setAffirm
     * @function setAffirm
     * @memberOf app_controllers.PaymentController
     * @description
     *  **Description**<br>
     *    The __setAffirm__ sets appData.isAffirm flag to value in parameter.
     * @param {Boolean} status - value to set
     * @param {Object} form - angular form
     */
    setAffirm(status, form){
        this.appData.isAffirm = status;

        if(!status && form) {

            form.ctr.$setValidity('affirmCountry', true);
            form.st.$setValidity('affirmState', true);

            this.appData.payment.isCreditSelected = '0';
        }

        this.$timeout(()=>{
            if (window.affirm && window.affirm.ui && typeof window.affirm.ui.refresh === 'function') {
                window.affirm.ui.refresh();
            }
        });

    }

    /**
     * @name checkoutAffirm
     * @function checkoutAffirm
     * @memberOf app_controllers.PaymentController
     * @description
     *  **Description**<br>
     *    The __checkoutAffirm__ validates data required for affirm and initiates checkout
     * @param {Object} paymentForm - angular form
     */
    checkoutAffirm() {

        if (this.SharedDataService.isAffirmSuccess) {

            this.onSubmit();

        } else {

            this.checkAffirmCountryError(this.itnForm.payment);

            if (this.checkForm()) {

                this.appData.payment.isCreditSelected = '1';

                this.validateForm().then(success => {

                    this.isAffirmErrorSent = false;
                    this.objectToSend = {};

                    let dataObject = {

                        items: [],
                        shipping: {

                            name: {
                                first: this.appData.payment.affirmFirstName,
                                last: this.appData.payment.affirmLastName
                            },
                            address: {
                                line1: this.appData.payment.street_address,
                                city: this.appData.payment.city,
                                state: this.appData.payment.state ? this.appData.payment.state.code : null,
                                zipcode: this.appData.payment.zip_code,
                                country: this.appData.payment.country.code
                            }
                        },

                        metadata: {
                            record_locator: this.appData.confirmations.itn,
                            itinerary: this.FlightService.prepareAffirmFlight(this.appData)
                        },

                        order_id: String(this.appData.saleId),
                        shipping_amount: 0,
                        tax_amount: null,
                        total: 0
                    };

                    if (this.appData.payment.affirmMiddleName) {
                        dataObject.shipping.name.first = `${this.appData.payment.affirmFirstName} ${this.appData.payment.affirmMiddleName} `;
                    }

                    this.objectToSend = {
                        success: this.affirmSuccess.bind(this),
                        error: this.affirmRejection.bind(this),
                        checkout_data: dataObject
                    };

                    if (this.appData.protection.isSelected !== '1' || !this.appData.isTips || this.appData.total.confirmedTips === 0) {

                        this.appData.isSubmitBtnDisabled = false;
                        this.isLastMinute = true;

                    } else {
                        this.postAffirm();
                    }

                }, rejection => {
                    this.appData.payment.isCreditSelected = '0';
                    this.CommonMethodsService.handleValidationRejection(this.appData, rejection, this.itnForm);
                });
            }

        }
    }

    /**
     * @name postAffirm
     * @function postAffirm
     * @memberOf app_controllers.PaymentController
     * @description
     *  **Description**<br>
     *    The __postAffirm__ logs event of sending affirm and executes submission
     */
    postAffirm(){

        let totalAmount = Math.round(this.PaymentService.prepareAffirmPayment(this.appData));

        this.objectToSend.checkout_data.items = this.PaymentService.prepareAffirmBreakdown(this.appData);
        this.objectToSend.checkout_data.shipping_amount = totalAmount;
        this.objectToSend.checkout_data.total = totalAmount;

        this.LogService
            .assign({
                _target: 'affirm-submit',
                data: this.objectToSend.checkout_data
            })
            .sendInfo();

        affirm.checkout.open_vcn(this.objectToSend);
    }


    /**
     * @name checkAffirmCountryError
     * @function checkAffirmCountryError
     * @memberOf app_controllers.PaymentController
     * @description
     *  **Description**<br>
     *    The __checkAffirmCountryError__ checks form for custom affirm errors
     * @param {Object} paymentForm - angular form
     */
    checkAffirmCountryError(paymentForm){

        if (this.payment.country && paymentForm.ctr && this.payment.country.code !== 'us') {
            paymentForm.ctr.$setValidity('affirmCountry', false);
            paymentForm.ctr.$setDirty();
        }

        if (this.payment.state && paymentForm.st) {

            if (this.payment.state.code === 'ia' || this.payment.state.code === 'wv') {
                paymentForm.st.$setValidity('affirmState', false);
                paymentForm.st.$setDirty();
            }
        }
    }

    /**
     * @name affirmSuccess
     * @function affirmSuccess
     * @memberOf app_controllers.PaymentController
     * @description
     *  **Description**<br>
     *    The __affirmSuccess__ passed as success callback function to affirm post request
     * @param {Object} cardDetails - object with required data
     */
    affirmSuccess(cardDetails) {

        this.LogService
            .assign({
                _target: 'affirm-success',
                appData: this.appData
            })
            .sendInfo();

        this.SaleApi.sale.postAffirmState({},
            {
                state: 'success'
            },
            response => {},
            rejection => {});

        this.SharedDataService.isAffirmSuccess = true;

        this.payment.creditcard.cardholder = cardDetails.cardholder_name;
        this.payment.creditcard.card_number = cardDetails.number;
        this.payment.creditcard.expiration_date.m = Number(cardDetails.expiration.slice(0,2));
        this.payment.creditcard.expiration_date.y = 2000 + Number(cardDetails.expiration.slice(2,4));
        this.payment.creditcard.card_cvv = cardDetails.cvv;

        //Affirm CC service number
        this.payment.creditcard.service_phone_number = '+14194263641';

        //Execute method passed to payment.component from sale-form.component
        this.onSubmit();
    }

    /**
     * @name affirmRejection
     * @function affirmRejection
     * @memberOf app_controllers.PaymentController
     * @description
     *  **Description**<br>
     *    The __affirmRejection__ passed as error callback function to affirm post request
     * @param {Object} errorData - object with error response
     */
    affirmRejection(errorData) {

        if (!this.isAffirmErrorSent && errorData && errorData.reason) {

            this.isAffirmErrorSent = true;
            this.appData.payment.isCreditSelected = '0';

            this.SaleApi.sale.postAffirmState({},
                {
                    state: errorData.reason
                },
                response => {},
                rejection => {});

            this.LogService
                .assign({
                    _target: 'affirm-error',
                    affirmErrorReason: errorData,
                    appData: this.appData
                })
                .sendInfo();

            if (errorData.reason === 'canceled') {
                this.setAffirm(false);
                this.appData.isAffirmAvailable = false;
                this.isAffirmNotify = true;
                this.appData.isSubmitBtnDisabled = false;
                this.appData.paymentType = 'card';
            }
        }
    }

    /**
     * @name updateValidMonths
     * @function updateValidMonths
     * @memberOf app_controllers.PaymentController
     * @description
     *  **Description**<br>
     *    The __updateValidMonths__ method updates the month and year selected by user depending on
     *    are it correct or not.
     */
    updateValidMonths() {
        let today = new Date();
        let currentMonth = today.getMonth();
        let currentYear = today.getFullYear();
        let dateSelected = this.payment.creditcard.expiration_date;
        let cardExpireYears = this.dateSettings.cardExpireYears;

        if (+dateSelected.y === currentYear) {

            // We enter this if currentYear is equal to one selected in credit card expiration date year select box
            if (+dateSelected.m <= currentMonth) {

                // If currentMonth has higher or equal value with selected month then we assign null that models value
                this.payment.creditcard.expiration_date.m = null;
            }
        }
        else if (+dateSelected.y < currentYear || +dateSelected.y > currentYear + cardExpireYears) {

            // We enter this if currentYear is higher than received year value or received year is 10 years higher than currentYear
            // We clear out select box values if we enter this if
            this.payment.creditcard.expiration_date = {
                m: null,
                y: null
            };
        }
    }

    /**
     * @name isMonthDisabled
     * @function isMonthDisabled
     * @memberOf app_controllers.PaymentController
     * @description
     *  **Description**<br>
     *    The __isMonthDisabled__ method defines if month need set as disabled.
     * @param {Number} month - month number
     * @returns {Boolean} return true if selected current year and selected month less of current month
     */
    isMonthDisabled(month) {

        let isMonthDisabled = false;
        let today = new Date();
        let currentMonth = today.getMonth() + 1;
        let currentYear = today.getFullYear();
        let selectedYear = this.payment.creditcard.expiration_date.y;

        if (currentYear === selectedYear && month < currentMonth) {
            isMonthDisabled = true;
        }

        return isMonthDisabled;
    }

}

// Injections of necessary dependencies
PaymentController.$inject = [
    '$rootScope',
    '$timeout',
    'SaleApi',
    'itnApi',
    'NormalizeDataService',
    'SharedDataService',
    'CommonMethodsService',
    'FlightService',
    'PaymentService',
    'LogService',
    'app.dateSettings',
    'app.specialConsolidators'
];

// Default export
export default PaymentController;