// Import dependencies
import angular from 'angular';
import moment from 'moment';
import jstz from 'jstz';

/**
 * @name AppService
 * @class AppService
 * @memberOf app_services
 * @description
 *  **Description**<br>
 *    The __AppService__ class contains methods to prepare data for application.
 *    Each method prepares data for certain state of application ( default_sale, hardcopy etc. ).
 *    Also it contain methods to make updates for data of application.<br><br>
 *  **List of methods**
 *  * [updateSaleData()]{@link app_services.AppService.updateSaleData}
 *  * [updateTranslations()]{@link app_services.AppService.updateTranslations}
 *  * [createDefaultSaleData()]{@link app_services.AppService.createDefaultSaleData}
 *  * [createHardcopyData()]{@link app_services.AppService.createHardcopyData}
 *  * [createVerificationTestChargeData()]{@link app_services.AppService.createVerificationTestChargeData}
 *  * [createVerificationConferenceCallData()]{@link app_services.AppService.createVerificationConferenceCallData}
 *  * [createVerificationHardcopyData()]{@link app_services.AppService.createVerificationHardcopyData}
 *  * [createTestimonialsData()]{@link app_services.AppService.createTestimonialsData}
 *  * [createSuccessData()]{@link app_services.AppService.createSuccessData}
 *  * [createErrorData()]{@link app_services.AppService.createErrorData}
 *  * [defineLanguage()]{@link app_services.AppService.defineLanguage}
 */
class AppService {

    /**
     * @constructs
     * @memberOf app_services.AppService
     * @description
     *  **Description**<br>
     *    This method contains all initializations for that class
     * @dependency {service} $window {@link https://docs.angularjs.org/api/ng/service/$window|link}
     * @dependency {library} $translate {@link https://github.com/angular-translate/angular-translate|link}
     * @dependency {service} CommonMethodsService {@link app_services.CommonMethodsService|link}
     * @dependency {service} SharedDataService {@link app_services.SharedDataService|link}
     * @dependency {service} PackageSummaryService {@link app_services.PackageSummaryService|link}
     * @dependency {service} HotelService {@link app_services.HotelService|link}
     * @dependency {service} FlightService {@link app_services.FlightService|link}
     * @dependency {service} PassengersService {@link app_services.PassengersService|link}
     * @dependency {service} RulesService {@link app_services.RulesService|link}
     * @dependency {service} BaggageService {@link app_services.BaggageService|link}
     * @dependency {service} ProtectionService {@link app_services.ProtectionService|link}
     * @dependency {service} PaymentService {@link app_services.PaymentService|link}
     * @dependency {service} VerificationService {@link app_services.VerificationService|link}
     */
    constructor(
        $window,
        $translate,
        CommonMethodsService,
        SharedDataService,
        PackageSummaryService,
        HotelService,
        FlightService,
        PassengersService,
        RulesService,
        BaggageService,
        ProtectionService,
        PaymentService,
        VerificationService
    ) {

        // Extra dependencies
        this.$window = $window;
        this.$translate = $translate;
        this.CommonMethodsService = CommonMethodsService;
        this.SharedDataService = SharedDataService;
        this.PackageSummaryService = PackageSummaryService;
        this.HotelService = HotelService;
        this.FlightService = FlightService;
        this.PassengersService = PassengersService;
        this.RulesService = RulesService;
        this.BaggageService = BaggageService;
        this.ProtectionService = ProtectionService;
        this.PaymentService = PaymentService;
        this.VerificationService = VerificationService;

        this.serverData = null;
    }

    /**
     * @name updateSaleData
     * @function updateSaleData
     * @memberOf app_services.AppService
     * @description
     *  **Description**<br>
     *    The __updateSaleData__ method updates sale data for application.
     *    It needed if sale was updated on BO ( Back Office ) side.
     * @param {Object} currentData - current data of application.
     * @param {Object} serverData - response form server.
     */
    updateSaleData(currentData, serverData) {
        currentData.state = 'form';
        currentData.isSaleUpdated = true;
        currentData.passengers = this.PassengersService.updateData(currentData.passengers, serverData);
        currentData.payment = angular.merge(currentData.payment, serverData.payment);
        currentData.protection = this.ProtectionService.createData(serverData);

        return currentData;
    }

    /**
     * @name updateTranslations
     * @function updateTranslations
     * @memberOf app_services.AppService
     * @description
     *  **Description**<br>
     *    The __updateTranslations__ method updates sale data for application based on user selected language.
     * @param {Object} currentData - current data of application.
     */
    updateTranslations(currentData) {
        let data = angular.copy(currentData);

        if (this.serverData) {
            data.hotel = this.HotelService.updateData(this.serverData);
            data.flight = this.FlightService.updateData(this.serverData);
            data.rules = this.RulesService.updateData(this.serverData);
            data.baggage = this.BaggageService.updateData(this.serverData);
        }

        return data;
    }

    /**
     * @name createDefaultSaleData
     * @function createDefaultSaleData
     * @memberOf app_services.AppService
     * @description
     *  **Description**<br>
     *    The __createDefaultSaleData__ method creates data for display 'default sale' ( not filled sale ).
     */
    createDefaultSaleData(serverData, params) {
        this.serverData = angular.copy(serverData);

        let sharedData = {
            isHardcopy: params.isHardcopy,
            saleType: serverData.sale_type,
            language: this.$translate.use(),
            saleId: serverData.sale_id,
            currency: this.CommonMethodsService.prepareCurrency(serverData.currency),
            cmsCompany: serverData.cms_company,
            confirmations: this.CommonMethodsService.prepareConfirmations(serverData.confirmations)
        };

        angular.merge(this.SharedDataService, sharedData);

        return {
            state: 'form',
            isHardcopy: sharedData.isHardcopy,
            language: sharedData.language,
            currency: sharedData.currency,
            oafDatetime: serverData.oaf_datetime,
            appState: serverData.appState,
            saleType: sharedData.saleType,
            saleId: sharedData.saleId,
            headerTitle: serverData.sale_type,
            itineraryCancellation: false, // TODO find where this property is born
            cmsCompany: sharedData.cmsCompany,
            suggestedTip: serverData.suggested_tip,
            isItineraryCancellationVisible: this.CommonMethodsService.isCorrespondsToTypeOfSale(
                serverData.sale_type,
                [
                    'REFUND',
                    'EARLY_VOID',
                    'LATE_VOID'
                ]
            ),
            isRulesVisible: this.CommonMethodsService.isCorrespondsToTypeOfSale(
                serverData.sale_type,
                [
                    'SALE',
                    'EXCHANGE'
                ]
            ),
            isReviewFormVisible: this.CommonMethodsService.isCorrespondsToTypeOfSale(
                serverData.sale_type,
                [
                    'SALE',
                    'EARLY_VOID',
                    'LATE_VOID'
                ]
            ),
            isExchangeReviewFormVisible: this.CommonMethodsService.isCorrespondsToTypeOfSale(
                serverData.sale_type,
                [
                    'EXCHANGE',
                    'REFUND'
                ]
            ),
            consolidator: serverData.consolidator,
            employee: serverData.employee,
            payment: params.isHardcopy ? this.PaymentService.createDataForHardcopy(serverData) : serverData.payment,
            paymentType: serverData.payment_type,
            confirmations: sharedData.confirmations,
            submitButtonTextKey: this.CommonMethodsService.getSubmitButtonTextKey(serverData.sale_type),
            packageSummary: this.PackageSummaryService.fetchData(serverData),
            hotel: this.HotelService.fetchData(serverData),
            flight: this.FlightService.fetchData(serverData),
            passengers: this.PassengersService.fetchData(serverData),
            rules: this.RulesService.fetchData(serverData),
            baggage: this.BaggageService.fetchData(serverData),
            protection: this.ProtectionService.createData(serverData),
            isTips: params.isHardcopy ? Boolean(serverData.submittedData.payment.tip_amount) : false,
            total: {
                confirmedTips: params.isHardcopy ? serverData.submittedData.payment.tip_amount : 0
            }
        };
    }

    /**
     * @name createHardcopyData
     * @function createHardcopyData
     * @memberOf app_services.AppService
     * @description
     *  **Description**<br>
     *    The __createHardcopyData__ method creates data for display 'hardcopy' ( sale was submitted ).
     */
    createHardcopyData(serverData) {
        let data = this.createDefaultSaleData(serverData, {
            isHardcopy: true
        });

        if (serverData.submittedData.creditState === 'success') {
            data.paymentType = 'affirm';
            data.isHardcopyAffirm = data.isAffirm = true;
        }
        data.currentProductionTag = serverData.current_production_tag;
        data.clientIp = serverData.client_ip;

        return data;
    }

    /**
     * @name createVerificationTestChargeData
     * @function createVerificationTestChargeData
     * @memberOf app_services.AppService
     * @description
     *  **Description**<br>
     *    The __createVerificationTestChargeData__ method provides data for Verification state with 'Test Charge' verification type.
     */
    createVerificationTestChargeData(serverData) {
        this.serverData = angular.copy(serverData);

        let sharedData = {
            isHardcopy: false,
            saleType: serverData.sale_type,
            language: this.$translate.use(),
            currency: this.CommonMethodsService.prepareCurrency(serverData.currency),
            cmsCompany: serverData.cms_company,
            confirmations: this.CommonMethodsService.prepareConfirmations(serverData.confirmations)
        };

        angular.merge(this.SharedDataService, sharedData);

        return {
            state: 'verification',
            verificationType : 'test-charge',
            isHardcopy: sharedData.isHardcopy,
            language: sharedData.language,
            currency: sharedData.currency,
            appState: serverData.appState,
            saleType: sharedData.saleType,
            headerTitle: 'verification',
            cmsCompany: sharedData.cmsCompany,
            confirmations: sharedData.confirmations,
            employee: serverData.employee,
            confirmation_email: serverData.confirmation_email,
            isTestimonialsSubmitted: serverData.testimonials_submitted,
            isTypeTestCharge: true,
            isTypeConferenceCall: false,

            // This needed for createServerDataForConferenceCall() function
            verification_data: serverData.verification_data,
            billing_phone_number: serverData.billing_phone_number
        };
    }

    /**
     * @name createVerificationConferenceCallData
     * @function createVerificationConferenceCallData
     * @memberOf app_services.AppService
     * @description
     *  **Description**<br>
     *    The __createVerificationConferenceCallData__ method provides data for Verification state with
     *    'Conference Call' verification type.
     */
    createVerificationConferenceCallData(serverData) {
        this.$window.intlTelInputGlobals.loadUtils(require('intl-tel-input/build/js/utils.js'));
        this.serverData = angular.copy(serverData);

        let timeZoneOffset;
        let timeZone = jstz.determine().name();

        if (timeZone) {
            // finds timezone utc offset and converts it to hours;
            let offset = Math.round(moment().tz(timeZone)._offset/60);
            timeZoneOffset = offset.toString();
        }

        let sharedData = {
            isHardcopy: false,
            saleType: serverData.sale_type,
            language: this.$translate.use(),
            currency: this.CommonMethodsService.prepareCurrency(serverData.currency),
            cmsCompany: serverData.cms_company,
            confirmations: this.CommonMethodsService.prepareConfirmations(serverData.confirmations)
        };

        angular.merge(this.SharedDataService, sharedData);

        return {
            state: 'verification',
            verificationType : 'conference-call',
            isHardcopy: sharedData.isHardcopy,
            language: sharedData.language,
            currency: sharedData.currency,
            appState: serverData.appState,
            saleType: sharedData.saleType,
            headerTitle: 'verification',
            cmsCompany: sharedData.cmsCompany,
            confirmations: sharedData.confirmations,
            employee: serverData.employee,
            billingPhoneNumber: this.$window.intlTelInputUtils.formatNumber(
                serverData.billing_phone_number,
                null,
                1
            ),
            confirmation_email: serverData.confirmation_email,
            isTestimonialsSubmitted: serverData.testimonials_submitted,
            isTypeTestCharge: false,
            isTypeConferenceCall: true,
            dst: moment().isDST() ? 'dst' : 'noDst',
            time: this.VerificationService.getBankWorkingTime(serverData.verification_data),
            timeZoneOffset: timeZoneOffset,
        };
    }

    /**
     * @name createVerificationHardcopyData
     * @function createVerificationHardcopyData
     * @memberOf app_services.AppService
     * @description
     *  **Description**<br>
     *    The __createVerificationHardcopyData__ method provides data for Verification Hardcopy state.
     */
    createVerificationHardcopyData(serverData) {
        let resolvedType = this.VerificationService.resolveVerificationType(serverData.verification_type);

        let sharedData = {
            isHardcopy: true,
            language: this.$translate.use(),
            currency: this.CommonMethodsService.prepareCurrency(serverData.currency),
            cmsCompany: serverData.cms_company,
            confirmations: this.CommonMethodsService.prepareConfirmations(serverData.confirmations)
        };

        angular.merge(this.SharedDataService, sharedData);

        return {
            state: 'verification',
            verificationType: resolvedType,
            isHardcopy: sharedData.isHardcopy,
            language: sharedData.language,
            currency: sharedData.currency,
            headerTitle: 'verification',
            cmsCompany: sharedData.cmsCompany,
            confirmations: sharedData.confirmations,
            employee: serverData.employee,
            billingPhoneNumber: serverData.billing_phone_number,
            confirmation_email: serverData.confirmation_email,
            isTypeTestCharge: resolvedType === 'test-charge',
            isTypeConferenceCall: resolvedType === 'conference-call',
            dst: serverData.submittedData.dst || null,
            phone: serverData.submittedData.phone,
            comment: serverData.submittedData.comment,
            endTimeStamp: serverData.submittedData.end_time_stamp,
            startTimeStamp: serverData.submittedData.start_time_stamp,
            timeZone: serverData.submittedData.time_zone,
            amount: serverData.submittedData.amount, // For 'Test charge' type of verification
            currentProductionTag: serverData.current_production_tag,
            clientIp: serverData.client_ip
        };
    }

    /**
     * @name createTestimonialsData
     * @function createTestimonialsData
     * @memberOf app_services.AppService
     * @description
     *  **Description**<br>
     *    The __createTestimonialsData__ method creates data for display 'testimonials' page.
     */
    createTestimonialsData(serverData) {
        return {
            state: 'testimonials',
            isHardcopy: false,
            saleType: serverData.sale_type,
            language: serverData.language,
            currency: this.CommonMethodsService.prepareCurrency(serverData.currency),
            cmsCompany: serverData.cms_company,
            headerTitle: 'testimonials',
            confirmationEmail: serverData.confirmation_email,
            confirmations: this.CommonMethodsService.prepareConfirmations(serverData.confirmations),
            employee: serverData.employee,
            isReviewForm: this.CommonMethodsService.isCorrespondsToTypeOfSale(
                serverData.sale_type,
                [
                    'SALE',
                    'EARLY_VOID',
                    'LATE_VOID'
                ]
            ),
        };
    }

    /**
     * @name createSuccessData
     * @function createSuccessData
     * @memberOf app_services.AppService
     * @description
     *  **Description**<br>
     *    The __createSuccessData__ method creates data for display 'success' page.
     */
    createSuccessData(serverData) {
        return {
            state: 'success',
            clientEmail: serverData.client_email,
            agentPhone: serverData.agent_phone,
            cmsCompany: serverData.cms_company
        };
    }

    /**
     * @name createErrorData
     * @function createErrorData
     * @memberOf app_services.AppService
     * @description
     *  **Description**<br>
     *    The __createErrorData__ method creates data for display 'error' page.
     */
    createErrorData(serverData) {
        let errorMsg;

        switch (serverData.appState) {

            case 'sale_not_valid_status':
                errorMsg = this.$translate.instant('error.oafNotValid');
                break;

            case 'link_with_problem':
                errorMsg = this.$translate.instant('error.problemWithLink');
                break;

            case 'maintenance':
                errorMsg = this.$translate.instant('error.maintenance');
                break;

            default:
                errorMsg = this.$translate.instant('error.unexpectedError');
                break;
        }

        return {
            state: 'error',
            clientEmail: serverData.client_email || '',
            agentPhone: serverData.agent_phone || '',
            errorMsg: errorMsg
        };
    }

    /**
     * @name defineLanguage
     * @function defineLanguage
     * @memberOf app_services.AppService
     * @description
     *  **Description**<br>
     *    The __defineLanguage__ method defines the language on that will be displayed the application.
     * @returns {String} Language of application
     */
    defineLanguage(serverData) {
        let language = 'en';

        if (serverData) {
            if (serverData.submittedData && serverData.submittedData.language) {
                language = serverData.submittedData.language;
            }
            else if (serverData.language) {
                language = serverData.language;
            }
        }

        return language;
    }

}

// Injections of necessary dependencies
AppService.$inject = [
    '$window',
    '$translate',
    'CommonMethodsService',
    'SharedDataService',
    'PackageSummaryService',
    'HotelService',
    'FlightService',
    'PassengersService',
    'RulesService',
    'BaggageService',
    'ProtectionService',
    'PaymentService',
    'VerificationService'
];

// Default export
export default AppService;
