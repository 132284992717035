/**
 * @name ReviewController
 * @class ReviewController
 * @memberOf app_controllers
 * @description
 *  **Description**<br>
 *    The __ReviewController__ is controller for the review block.<br><br>
 *  **List of methods**
 *  * [setPurpose()]{@link app_controllers.ReviewController.setPurpose}
 *  * [setRecommendUsRatingScore()]{@link app_controllers.ReviewController.setRecommendUsRatingScore}
 *  * [setAgentRatingScore()]{@link app_controllers.ReviewController.setAgentRatingScore}
 *  * [clickAgree()]{@link app_controllers.ReviewController.clickAgree}
 *  * [prepareReviewDataToSend()]{@link app_controllers.ReviewController.prepareReviewDataToSend}
 *  * [sendReview()]{@link app_controllers.ReviewController.sendReview}
 */
class ReviewController {

    /**
     * @constructs
     * @memberOf app_controllers.ReviewController
     * @description
     *  **Description**<br>
     *    This method contains all initializations for that class
     * @dependency {library} $translate {@link https://github.com/angular-translate/angular-translate|link}
     * @dependency {service} SaleApi {@link app_services.SaleApi|link}
     * @dependency {service} CommonMethodsService {@link app_services.CommonMethodsService|link}
     */
    constructor(
        $translate,
        SaleApi,
        CommonMethodsService
    ) {
        // Extra dependencies
        this.$translate = $translate;
        this.SaleApi = SaleApi;
        this.CommonMethodsService = CommonMethodsService;

        this.formData = {};

        this.rating = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'];
        this.isSpecifyPurposeRequired = false;

    }

    /**
     * @name setPurpose
     * @function setPurpose
     * @memberOf app_controllers.ReviewController
     * @description
     *  **Description**<br>
     *    The __setPurpose__ method sets 'isSpecifyPurposeRequired' boolean if 'Other' is selected in travel purpose field.
     */
    setPurpose() {
        this.isSpecifyPurposeRequired = (this.formData.travelPurpose === 'Other');
    }

    /**
     * @name setRecommendUsRatingScore
     * @function setRecommendUsRatingScore
     * @memberOf app_controllers.ReviewController
     * @description
     *  **Description**<br>
     *    The __setRecommendUsRatingScore__ method is responsible for assigning recommendUsRatingScore button value to hidden input.
     * @param {String} value - rating score ( 1 - 10 )
     */
    setRecommendUsRatingScore(value) {
        this.formData.recommendUsRatingScore = value;
    }

    /**
     * @name setAgentRatingScore
     * @function setAgentRatingScore
     * @memberOf app_controllers.ReviewController
     * @description
     *  **Description**<br>
     *    The __setAgentRatingScore__ method is responsible for assigning agentRatingScore button value to hidden input.
     * @param {String} value - rating score ( 1 - 10 )
     */
    setAgentRatingScore(value) {
        this.formData.agentRatingScore = value;
    }

    /**
     * @name clickAgree
     * @function clickAgree
     * @memberOf app_controllers.ReviewController
     * @description
     *  **Description**<br>
     *    The __clickAgree__ method is responsible for changing checkbox value on label click.
     */
    clickAgree() {
        this.formData.isAllowedReprint = !this.formData.isAllowedReprint;
    }

    /**
     * @name prepareReviewDataToSend
     * @function prepareReviewDataToSend
     * @memberOf app_controllers.ReviewController
     * @description
     *  **Description**<br>
     *    The __prepareReviewDataToSend__ method prepares review data to send on server.
     * @returns {Object} Prepared review data
     */
    prepareReviewDataToSend() {

        // If no errors are present send reviewForm form data to BE
        // Create object variable to send to BE, so we don't change anything for form modules and can freely interpret sent data and cloneDeep testimonialData
        let dataToSend = angular.copy(this.formData);

        dataToSend.language = this.appData.language;

        if (this.isSpecifyPurposeRequired) {
            // If we have specifyPurpose module value, add it at the end of dataToSend.travelPurpose
            dataToSend.travelPurpose = `${dataToSend.travelPurpose}, ${this.formData.specifyPurpose}`;
            delete dataToSend.specifyPurpose;
        }

        dataToSend.hotelAccommodation = (dataToSend.hotelAccommodation === 'yes') ? '1' : '0';
        dataToSend.isAllowedReprint = dataToSend.isAllowedReprint ? '1' : '0';
        delete dataToSend.isReprint;

        return dataToSend;
    }

    /**
     * @name sendReview
     * @function sendReview
     * @memberOf app_controllers.ReviewController
     * @description
     *  **Description**<br>
     *    The __sendReview__ method handles testimonials form form submission
     */
    sendReview() {
        this.isLoading = true;

        if (this.reviewForm.$invalid || this.reviewForm.$invalidExplicit) {
            this.CommonMethodsService.setErrors(this.reviewForm);
            this.isLoading = false;
            this.CommonMethodsService.scrollToError();
        }
        else {

            // We are passing id and token for API, then we are passing dataToSend object
            this.SaleApi.sale.postTestimonialsData(
                this.prepareReviewDataToSend(),
                response => {
                    this.isLoading = false;

                    this.app.prepareData('sale_is_filled_and_submitted', {
                        state: this.appData.appState,
                        result: {
                            language: this.$translate.use(),
                            client_email: this.appData.confirmationEmail,
                            agent_phone: this.appData.employee.phone || '',
                            cms_company: this.appData.cmsCompany
                        }
                    });
                },
                rejection => {
                    if (rejection.data && rejection.data.state === 'validation') {
                        this.reviewForm.agentRatingScore.$dirty = true;
                        this.reviewForm.agentRatingScore.$setValidity('required', false);
                        this.CommonMethodsService.scrollToError();
                    } else {
                        this.app.prepareData('error', rejection.data);
                    }

                    this.isLoading = false;
                }
            );
        }
    }

}

// Injections of necessary dependencies
ReviewController.$inject = [
    '$translate',
    'SaleApi',
    'CommonMethodsService'
];

// Default export
export default ReviewController;