(function() {
    "use strict";

    angular.module('app.directives').directive('visainfo', [
        'itnApi',
        'SaleApi',
        '$translate',

        function(
            itnApi,
            SaleApi,
            $translate
        ) {

        return {
            restrict: 'E',
            scope: {
                isPopup: '=',
                popupCloseLogic: '='
            },
            template: require('./../templates/partials/visainfo.html'),

            link : function(scope, element, attrs) {

                scope.visa = {
                    data: {},
                    isOpen: false,
                    isCountriesReady: false,
                    isRequest: false,
                    countries: [],

                    closePopup: function(){
                        scope.isPopup = false;
                        this.isOpen = false;
                        scope.popupCloseLogic = null;
                    },

                    openPopup: function(){
                        this.isOpen = true;

                        scope.isPopup = true;
                        scope.popupCloseLogic = this.closePopup.bind(this);

                        if (!this.isCountriesReady) {
                            itnApi.getCountries(
                                {},
                                response => {
                                    this.countries = response.result;
                                    this.isCountriesReady = true;
                                },
                                rejection => {
                                    console.log('Visa countries error, data:',rejection);
                                    this.closePopup();
                                }
                            );
                        }
                    },

                    isButtonDisabled: function(){
                        return this.isRequest ||  !this.data.nationality || !this.data.residence;
                    },

                    submit: function(){
                        this.isRequest = true;
                        scope.visa.data.text = '';
                        let dataToSend = {
                            nationality: this.data.nationality.code,
                            residence: this.data.residence.code
                        };

                        SaleApi.sale.getVisaData(
                            dataToSend,
                            {},
                            response => {
                                this.isRequest = false;
                                if (response.result && response.result.visaInfo) {
                                    this.data.text = response.result.visaInfo.replace(/\n/g,'<br/>');

                                } else {
                                    this.data.text = $translate.instant('visaInformation.popup.noInfo');
                                }
                            },
                            rejection => {
                                console.log('Visa info error, :',rejection);
                                this.data.text = $translate.instant('visaInformation.popup.noInfo');
                                this.isRequest = false;
                            }
                        )
                    }
                };
            }
        };
    }]);
})();

