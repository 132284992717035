/**
 * @name ProtectionController
 * @class ProtectionController
 * @memberOf app_controllers
 * @description
 *  **Description**<br>
 *    The __ProtectionController__ is controller which control the protection block state.<br><br>
 *  **List of methods**
 *  * [$onInit()]{@link app_controllers.ProtectionController.$onInit}
 *  * [updateTotalAmount()]{@link app_controllers.ProtectionController.updateTotalAmount}
 */
class ProtectionController {

    /**
     * @constructs
     * @memberOf app_controllers.ProtectionController
     * @description
     *  **Description**<br>
     *    This method contains all initializations for that class
     * @property {Object} protection - Contains the data about protection
     * @dependency {service} $rootScope {@link https://docs.angularjs.org/api/ng/service/$rootScope|link}
     * @dependency {service} CommonMethodsService {@link app_services.CommonMethodsService|link}
     * @dependency {service} SharedDataService {@link app_services.SharedDataService|link}
     */
    constructor(
        $rootScope,
        CommonMethodsService,
        SharedDataService
    ) {

        // Extra dependencies
        this.$rootScope = $rootScope;
        this.CommonMethodsService = CommonMethodsService;
        this.SharedDataService = SharedDataService;

        // Properties
        this.protection = {};
        this.popup = {
            terms: {},
            tp: {}
        };
    }

    /**
     * @name $onInit
     * @function $onInit
     * @memberOf app_controllers.ProtectionController
     * @description
     *  **Description**<br>
     *    The __$onInit__ lifecycle hook is for all initialisation of code for a Controller.
     */
    $onInit() {
        this.isHardcopy = this.appData.isHardcopy;
        this.protection = this.appData.protection;
    }

    /**
     * @name $onChanges
     * @function $onChanges
     * @memberOf app_controllers.ProtectionController
     * @description
     *  **Description**<br>
     *    The __$onChanges__ lifecycle hook is for update protection data.
     */
    $onChanges(changes) {
        if (!angular.equals({}, changes.appData.previousValue)) {
            this.protection = this.appData.protection;
        }
    }

    /**
     * @name updateTotalAmount
     * @function updateTotalAmount
     * @memberOf app_services.ProtectionController
     * @description
     *  **Description**<br>
     *    The __updateTotalAmount__ method fire the event to update total amount for sale.
     *    Catcher located inside {@link app_controllers.PaymentController|PaymentController}
     * @todo remove to SharedService || use other way how remove $broadcast
     */
    updateTotalAmount() {
        this.$rootScope.$broadcast('update-total-amount');
    }
}

// Injections of necessary dependencies
ProtectionController.$inject = [
    '$rootScope',
    'CommonMethodsService',
    'SharedDataService'
];

// Default export
export default ProtectionController;