/**
 * @name SaleFormController
 * @class SaleFormController
 * @memberOf app_controllers
 * @description
 *  **Description**<br>
 *    The __SaleFormController__ is controller which control the sale form block state.<br><br>
 *  **List of methods**
 *  * [$onInit()]{@link app_controllers.SaleFormController.$onInit}
 *  * [checkForm()]{@link app_controllers.SaleFormController.checkForm}
 *  * [serverValidateForm()]{@link app_controllers.SaleFormController.serverValidateForm}
 *  * [submitForm()]{@link app_controllers.SaleFormController.submitForm}
 *  * [sendForm()]{@link app_controllers.SaleFormController.sendForm}
 */
class SaleFormController {

    /**
     * @constructs
     * @memberOf app_controllers.SaleFormController
     * @description
     *  **Description**<br>
     *    This method contains all initializations for that class
     * @dependency {service} $window {@link https://docs.angularjs.org/api/ng/service/$window|link}
     * @dependency {service} $timeout {@link https://docs.angularjs.org/api/ng/service/$timeout|link}
     * @dependency {service} $anchorScroll {@link https://docs.angularjs.org/api/ng/service/$anchorScroll|link}
     * @dependency {library} $translate {@link https://github.com/angular-translate/angular-translate|link}
     * @dependency {service} SaleApi {@link app_services.SaleApi|link}
     * @dependency {service} NormalizeDataService {@link app_services.NormalizeDataService|link}
     * @dependency {service} CommonMethodsService {@link app_services.CommonMethodsService|link}
     * @dependency {service} SharedDataService {@link app_services.SharedDataService|link}
     * @dependency {service} LogService {@link app_services.LogService|link}
     * @dependency {service} PassengersService {@link app_services.PassengersService|link}
     * @dependency {service} PaymentService {@link app_services.PaymentService|link}
     */
    constructor(
        $window,
        $timeout,
        $anchorScroll,
        $translate,
        $q,
        SaleApi,
        NormalizeDataService,
        CommonMethodsService,
        SharedDataService,
        LogService,
        PassengersService,
        PaymentService,
        saleStatus
    ) {

        // Extra dependencies
        this.$window = $window;
        this.$timeout = $timeout;
        this.$anchorScroll = $anchorScroll;
        this.$translate = $translate;
        this.$q = $q;
        this.SaleApi = SaleApi;
        this.NormalizeDataService = NormalizeDataService;
        this.CommonMethodsService = CommonMethodsService;
        this.SharedDataService = SharedDataService;
        this.LogService = LogService;
        this.PassengersService = PassengersService;
        this.PaymentService = PaymentService;
        this.saleStatus = saleStatus;

        this.saleFormModel = {};
    }

    /**
     * @name checkForm
     * @function checkForm
     * @memberOf app_services.AppController
     * @description
     *  **Description**<br>
     *    The __checkForm__ checks form for errors and raises FE error messages.
     *    Also returns validity of form
     *
     * @returns {Boolean} Return form validity
     */
    checkForm(){

        let isValid = true;

        this.appData.isFormSubmitted = true;


        if (this.itnForm.$invalid || this.itnForm.$invalidExplicit) {

            this.CommonMethodsService.setErrors(this.itnForm);
            this.CommonMethodsService.scrollToError();

            isValid = false;
        }

        return isValid;
    }

    /**
     * @name serverValidateForm
     * @function serverValidateForm
     * @memberOf app_services.AppController
     * @description
     *  **Description**<br>
     *    The __serverValidateForm__ used to preform BE validation without further submission of form
     * @returns {Promise} deferred.promise - to catch success/failure of validation
     */
    serverValidateForm(){

        let deferred = this.$q.defer();

        this.appData.isSubmitBtnDisabled = true;
        this.appData.loadingTextKey = 'processing';

            let dataToSend = {
                language: this.$translate.use(),
                isCarRequested: this.appData.packageSummary.isCarRequested ? '1' : '0',
                passengers: this.PassengersService.preparePassengersDataToSend(this.saleFormModel.passengers)
            };

        dataToSend.payment = this.PaymentService.preparePaymentDataToSend(this.appData);

        if (this.appData.paymentType !== "wt") {
            dataToSend.creditcard = this.appData.payment.creditcard;

            if (this.appData.cmsCompany !== 'UK') {
                dataToSend.creditcard.service_phone_number = this.$window.intlTelInputUtils.formatNumber(
                    dataToSend.creditcard.service_phone_number,
                    null,
                    0
                );
            }
        }

        if (this.SharedDataService.isAffirmSuccess) {
            dataToSend.creditState = 'success';
        }

        this.appData.dataToSend = dataToSend;

        this.SaleApi.sale.validateSaleData({},
            dataToSend,
            response => {
                deferred.resolve(response);
            },

            rejection => {
                this.appData.isSubmitBtnDisabled = false;
                deferred.reject(rejection);
            }
        );

        return deferred.promise;
    }

    /**
     * @name submitForm
     * @function submitForm
     * @memberOf app_services.AppController
     * @description
     *  **Description**<br>
     *    The __submitForm__ method is responsible to verifying the data of sale form before it is sent to the server.
     *    Note: verification is performed on the server
     */
    submitForm() {
        if (this.checkForm()) {
            this.appData.isSubmitBtnDisabled = true;

            this.serverValidateForm().then(
                success => {
                    new Fingerprint2().get(result => {
                        this.appData.dataToSend.fingerprint = result;
                        this.sendForm(this.appData.dataToSend);
                    });
                },
                rejection => {
                    this.CommonMethodsService.handleValidationRejection(this.appData, rejection, this.itnForm);
                }
            );
        }
    }

    /**
     * @name sendForm
     * @function sendForm
     * @memberOf app_services.AppController
     * @description
     *  **Description**<br>
     *    The __sendForm__ method is responsible for the send the data of sale form to server.
     * @param {Object} data - data to send on server
     */
    sendForm(data) {
        this.appData.state = 'loading';

        this.LogService
            .assign({
                _target: 'submit-sale',
                appData: this.appData
            })
            .sendInfo();

        this.SaleApi.sale.postSaleData({},
            data,
            response => {
                if (this.NormalizeDataService.typeOf(response.result) === 'object') {
                    this.appData.isSaleUpdated = false;

                    if (!response.result.verification_enabled) {
                        this.app.prepareData('sale_submitted_without_testimonials', response);
                    }
                    else {
                        this.app.prepareData('sale_submitted_without_verification', response);
                    }

                }
                else {
                    this.appData.isSaleSubmitError = true;
                }

                this.appData.loadingTextKey = 'loading';
            },
            rejection => {

                if (
                    this.NormalizeDataService.typeOf(rejection.data) === 'object' &&
                    rejection.data.state
                ) {

                    switch (rejection.data.state) {

                        case this.saleStatus.SALE_NOT_VALID:
                            this.app.prepareData('error', rejection.data);
                            break;

                        case this.saleStatus.SALE_UPDATED:
                            this.appData.isInit = false;
                            this.app.prepareData('sale_updated', rejection.data);
                            this.$window.scrollTo(0, 0);

                            this.$timeout(()=>{
                                if (window.affirm && window.affirm.ui && typeof window.affirm.ui.refresh === 'function') {
                                    window.affirm.ui.refresh();
                                }
                            });

                            break;

                        case this.saleStatus.SALE_FILLED_AND_SUBMITTED:
                            this.app.prepareData(this.saleStatus.SALE_FILLED_AND_SUBMITTED, rejection.data);
                            break;

                        case this.saleStatus.APP_UNEXPECTED_EXCEPTION:
                            this.appData.isSaleSubmitError = true;
                            this.app.prepareData('error', rejection.data);
                            break;

                        default:
                            this.app.prepareData('error', rejection.data);
                            break;
                    }
                }
                else {
                    this.app.prepareData('error', rejection.data);
                }

                this.appData.isSubmitBtnDisabled = false;
                this.appData.loadingTextKey = 'loading';
            }
        );
    }

}


// Injections of necessary dependencies
SaleFormController.$inject = [
    '$window',
    '$timeout',
    '$anchorScroll',
    '$translate',
    '$q',
    'SaleApi',
    'NormalizeDataService',
    'CommonMethodsService',
    'SharedDataService',
    'LogService',
    'PassengersService',
    'PaymentService',
    'app.saleStatus'
];

// Default export
export default SaleFormController;