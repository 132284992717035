/**
 * @name ProgramController
 * @class ProgramController
 * @memberOf app_controllers
 * @description
 *  **Description**<br>
 *    The __ProgramController__ is controller which control the passenger program block state.<br><br>
 *  **List of methods**
 *  * [$onInit()]{@link app_controllers.ProgramController.$onInit}
 *  * [isFieldHighlighted()]{@link app_controllers.ProgramController.isFieldHighlighted}
 *  * [isProgramRequired()]{@link app_controllers.ProgramController.isProgramRequired}
 *  * [isButtonVisible()]{@link app_controllers.ProgramController.isButtonVisible}
 *  * [addProgram()]{@link app_controllers.ProgramController.addProgram}
 *  * [removeProgram()]{@link app_controllers.ProgramController.removeProgram}
 */
class ProgramController {

    /**
     * @constructs
     * @memberOf app_controllers.ProgramController
     * @description
     *  **Description**<br>
     *    This method contains all initializations for that class
     * @dependency {service} SharedDataService {@link app_services.SharedDataService|link}
     */
    constructor(
        SharedDataService
    ) {

        // Extra dependencies
        this.SharedDataService = SharedDataService;

        // Properties
        this.passenger = null;
        this.confirmations = null;
        this.maxProgramCount = 5;
    }

    /**
     * @name $onInit
     * @function $onInit
     * @memberOf app_controllers.ProgramController
     * @description
     *  **Description**<br>
     *    The __$onInit__ lifecycle hook is for all initialisation of code for a Controller.
     */
    $onInit() {
        this.confirmations = this.SharedDataService.confirmations;
    }

    /**
     * @name isFieldHighlighted
     * @function isFieldHighlighted
     * @memberOf app_controllers.ProgramController
     * @description
     *  **Description**<br>
     *    The __isFieldHighlighted__ method defines the need to highlight some field in block of passenger program.
     * @param {String} value - one from properties ( name , number ) from passenger program.
     * @returns {Boolean} Returns 'true' if the field need highlight
     */
    isFieldHighlighted(value) {
        return this.SharedDataService.isHardcopy && !!value;
    }

    /**
     * @name isProgramRequired
     * @function isProgramRequired
     * @memberOf app_controllers.ProgramController
     * @description
     *  **Description**<br>
     *    The __isFieldHighlighted__ method defines if some field  is required in passenger program.
     *    Basically it's means if one of field group ( name, number ) aren't empty then second field becomes the required.
     * @param {Object} program - passenger program.
     * @returns {Boolean} Returns 'true' if the field need highlight
     */
    isProgramRequired(program) {
        return !!program && (!!program.name || !!program.number);
    }

    /**
     * @name isButtonVisible
     * @function isButtonVisible
     * @memberOf app_controllers.ProgramController
     * @description
     *  **Description**<br>
     *    The __isButtonVisible__ method defines the visibility of two buttons ( Add program, and Remove program ).
     * @param {Number} index - passenger program index.
     * @returns {Boolean} Returns 'true' if the index not zero and selected programs amount are less of max available programs.
     */
    isButtonVisible(index) {
        return index === 0 && this.passenger.program.length < this.maxProgramCount;
    }

    /**
     * @name addProgram
     * @function addProgram
     * @memberOf app_controllers.ProgramController
     * @description
     *  **Description**<br>
     *    The __addProgram__ method add empty program to passenger programs.
     */
    addProgram() {
        this.passenger.program.push({
            name: null,
            number: null
        });
    }

    /**
     * @name removeProgram
     * @function removeProgram
     * @memberOf app_controllers.ProgramController
     * @description
     *  **Description**<br>
     *    The __removeProgram__ method remove program from passenger programs by passed index.
     */
    removeProgram(index){
        this.passenger.program.splice(index, 1);
    }
}

// Injections of necessary dependencies
ProgramController.$inject = [
    'SharedDataService'
];

// Default export
export default ProgramController;