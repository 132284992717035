(function() {
    "use strict";

    angular.module('app.directives').directive('replaceToNumber', function() {
        return {
            require: 'ngModel',
            scope: true,
            link: function(scope, element, attrs, model) {

                model.$parsers.push(function(val) {

                    let replaced = null;
                    if (val) {
                        let allowedCharacters = new RegExp("[^0-9.,]");
                        let allowedSyntax = new RegExp(/^((\d+(\.\d{0,2})?)|(\.\d{0,2}))$/);
                        replaced = val.replace(',','.');
                        replaced = replaced.replace(allowedCharacters, '');

                        while (replaced && !allowedSyntax.test(replaced)) {
                            replaced = replaced.slice(0, -1);
                        }

                        if (replaced !== val) {
                            model.$setViewValue(replaced);
                            model.$render();
                        }
                    }

                    return replaced;
                });
            }
        };
    });
})();

