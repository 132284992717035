// Import dependencies
import angular from 'angular';
import PreferencesComponent from './preferences.component';

/**
 * @name app.preferences
 * @class PreferencesModule
 * @memberOf app_modules
 * @description
 *  **Description**<br>
 *    The __app.preferences__ module is module that is responsible to make all preparation of data for preferences block
 *    and display him in application. This module consist from 'PreferencesComponent'.
 *    What it makes, see in relevant section.
 *    * {@link app_components.PreferencesComponent|PreferencesComponent}
 */
const PreferencesModule = angular.module('app.preferences', [])
    .component('preferences', PreferencesComponent)
    .name;

// Default export
export default PreferencesModule;