// Import dependencies
import angular from 'angular';

/**
 * @name PassengersController
 * @class PassengersController
 * @memberOf app_controllers
 * @description
 *  **Description**<br>
 *    The __PassengersController__ is controller which control the passengers block state.<br><br>
 *  **List of methods**
 *  * [$onInit()]{@link app_controllers.PassengersController.$onInit}
 *  * [$onChanges()]{@link app_controllers.PassengersController.$onChanges}
 *  * [isPreferencesVisible()]{@link app_controllers.PassengersController.isPreferencesVisible}
 *  * [isFormSubmitted()]{@link app_controllers.PassengersController.isFormSubmitted}
 */
class PassengersController {

    /**
     * @constructs
     * @memberOf app_controllers.PassengersController
     * @description
     *  **Description**<br>
     *    This method contains all initializations for that class
     * @dependency {service} CommonMethodsService {@link app_services.CommonMethodsService|link}
     * @dependency {service} SharedDataService {@link app_services.SharedDataService|link}
     * @dependency {service} PassengersService {@link app_services.PassengersService|link}
     */
    constructor(
        CommonMethodsService,
        SharedDataService,
        PassengersService
    ) {

        // Extra dependencies
        this.CommonMethodsService = CommonMethodsService;
        this.SharedDataService = SharedDataService;
        this.PassengersService = PassengersService;

        // Properties
        this.passengers = null;
    }

    /**
     * @name $onInit
     * @function $onInit
     * @memberOf app_controllers.PassengersController
     * @description
     *  **Description**<br>
     *    The __$onInit__ lifecycle hook is for all initialisation of code for a Controller.
     */
    $onInit() {
        this.saleFormModel.passengers = angular.copy(this.appData.passengers);
    }

    /**
     * @name $onChanges
     * @function $onChanges
     * @memberOf app_controllers.PassengersController
     * @description
     *  **Description**<br>
     *    The __$onChanges__ lifecycle hook is for update passenger data.
     */
    $onChanges(changes) {
        if (!angular.equals({}, changes.appData.previousValue)) {
            this.passengers = this.appData.passengers;
        }
    }

    /**
     * @name isItineraryCancellationError
     * @function isItineraryCancellationError
     * @memberOf app_controllers.PassengersController
     * @description
     *  **Description**<br>
     *    The __isItineraryCancellationError__ method defines is error visible or not.
     * @returns {Boolean} Return 'true' if 'itineraryCancellation' error is visible
     */
    isItineraryCancellationError() {
        let isError = false;

        if (this.itnForm.itineraryCancellation) {
            let isDirty = this.itnForm.itineraryCancellation.$dirty;
            let isRequired = this.itnForm.itineraryCancellation.$error.required;

            isError = isDirty && isRequired;
        }

        return isError;
    }

    /**
     * @name isPreferencesVisible
     * @function isPreferencesVisible
     * @memberOf app_controllers.PassengersController
     * @description
     *  **Description**<br>
     *    The __isPreferencesVisible__ method defines is preferences block visible or not. It's dependent from
     *    application state 'hardcopy' and from filled or not ( preferences or program ) in data of passenger.
     * @param {Object} passenger - passenger data
     * @returns {Boolean} Return 'true' if 'preferences' is visible
     */
    isPreferencesVisible(passenger) {
        let isVisible = false;

        let isCorresponds = this.CommonMethodsService.isCorrespondsToTypeOfSale(
            this.SharedDataService.saleType,
            [
                'SALE',
                'EXCHANGE'
            ]
        );

        let isFilled = !!passenger.preferences || !!passenger.program;

        if(isCorresponds) {
            isVisible = this.SharedDataService.isHardcopy ? isFilled : true;
        }

        return isVisible;
    }

}

// Injections of necessary dependencies
PassengersController.$inject = [
    'CommonMethodsService',
    'SharedDataService',
    'PassengersService'
];

// Default export
export default PassengersController;