// Import dependencies
import controller from './preferences.controller';
import template from './preferences.template.html';

/**
 * @name PreferencesComponent
 * @class PreferencesComponent
 * @memberOf app_components
 * @description
 *  **Description**<br>
 *    The __preferences__ component contains all needed to display 'preferences' block. That include itself ( controller and template ).
 *    For more details see:
 *    * {@link app_controllers.PreferencesController|PreferencesController}
 *    * {@link https://gitlab.dyninno.net/oaf/standalone/blob/master/web/public/src/components/passengers/preferences/preferences.template.html|'preferences' template}
 */
const PreferencesComponent = {
    controller,
    controllerAs: 'PreferencesCtrl',
    template,
    bindings: {
        passenger: '='
    }
};

// Default export
export default PreferencesComponent;